import React, { Component } from 'react';

import HeaderGoat from '../components/ui/HeaderGoat.js';
import { getChainParameters } from '../components/wallet_generic/helpers/getChainParameters.js';
import { isDev } from '../components/wallet_generic/helpers/isDev.js';
import { switchChain } from '../components/wallet_generic/helpers/switchChain.js';
import { Reti } from '../components/wallet_generic/Wallets.js';

export class SwitchNetwork extends Component {
    
    constructor(props) {
        super(props);

        let redirect_url = window.location.search.substring(1);
        redirect_url = redirect_url.toLowerCase();
        redirect_url = redirect_url.replace("http", "");
        redirect_url = redirect_url.replace("/", "");
        redirect_url = redirect_url.replace("redirect=", "");
        redirect_url = decodeURIComponent(redirect_url);

        this.state = {
            'rete': props.goto,
            'title': '',
            'description': '',
            'redirect': redirect_url
        };
        this.setInfoPage();

        this.doSwitch = this.doSwitch.bind(this);
    }
    
    async doSwitch() {
        const params = getChainParameters(this.state.rete);
        const response = await switchChain(null, params.chainId, params);
        if (response) {
            window.location.href = window.location.protocol + '//' + window.location.host + '/' + this.state.redirect;
        }
    }

    setInfoPage() {
        let title;
        let description;

        switch (this.state.rete) {
            case Reti.ETHEREUM:
                if (isDev()) {
                    title = 'Ethereum Rinkeby';
                    description = 'In modalità sviluppo utilizzare la rete di test Rinkeby';
                } else {
                    title = 'Ethereum Main net';
                    description = 'Goat Nation works only on the ETH main chain.';
                }
                break;
            case Reti.BINANCE:
                if (isDev()) {
                    title = 'Binance Smart Chain Testnet';
                    description = 'In modalità sviluppo utilizzare la rete di test Smart Chain Testnet';
                } else {
                    title = 'Binance Smart Chain';
                    description = 'The Goatech area is only available on the Binance network.';
                }
                break;
        }
        this.state.title = title;
        this.state.description = description;
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" />

                <div className='container-top'>
                    <div className='container-account container-switch-network'>
                        <div className='text-switch-network'>
                            <h3>Switch network to {this.state.title}</h3>

                            <p className='switch-network-description'>{this.state.description}</p>
                            <p className='switch-network-btn'><a onClick={() => this.doSwitch()} className='btn-block-orange'>Switch Chain</a></p>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SwitchNetwork;