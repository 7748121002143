import React, {Component} from 'react';

import DiscordBtn from '../ui/DiscordBtn.js';
import GoatMintBtn from '../ui/GoatMintBtn.js';

import {testi} from "../../../language/lang.js";
import {MINT_OPENED} from "../../../config/ConfigMint";

export class HomeSection extends Component {
    render() {
        return (
            <div style={{paddingTop: 100}} className={!MINT_OPENED ? 'section-mint-disabled' : ''} id="utilities">
                <div className='container-fluid first-sectionhome'>
                    <div style={{
                        background: `url(${process.env.PUBLIC_URL + '/assets/img/section1/earth-animation.png'})
                    `
                    }} className='section1-background goat-fromday0'>
                        <div style={{
                            background: `url(${process.env.PUBLIC_URL + '/assets/img/section1/ELEMENTI.png'})
                            `
                        }} className='goat-fromday0-decoration'>
                            <div className="container">
                                <div className="section1-right">
                                    <h3 dangerouslySetInnerHTML={{__html: testi('fromday0_h3')}}></h3>
                                    <p dangerouslySetInnerHTML={{__html: testi('fromday0_parag')}}
                                       className="parag-section1"></p>
                                    {MINT_OPENED ? <GoatMintBtn/> : null}
                                    <DiscordBtn />
                                </div>
                                <div className="section1-left">
                                    <img src="assets/img/section1/di1.png?v=3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;