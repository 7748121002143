import React, { Component } from 'react';
import { ethers } from "ethers";

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import GoatechBtnMobile from '../../../components/area_riservata/goatech/ui/BtnMobile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { showError } from '../../../components/ui/ToastErrore';
import { Reti } from '../../../components/wallet_generic/Wallets.js';
import ContractData from '../../../components/binance/getContractData.js';

import { GoatechHayMint } from '../../../components/binance/GoatechHayMint';
import { getMerkleMint } from '../../../components/binance/getMerkleMint.js';
import { EndPointMethod, RequestBackend } from '../../../components/area_riservata/backend/RequestBackend.js';

export class GoatechMintFieno extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            'menu_address': '',
            'infoLoaded': false,
            'mintQnt': 1,
            'mintPrice': 0,
            'mintEnabled': true,
            'mintGlobalEnabled': false,
            'mintProofs': {},
            'transaction_waiting': false,
            'transaction_waiting_text': '',
            'transaction_complete': false,
            'transaction_error': false,
            'transaction_error_msg': '',
        };

        this.setAddress = this.setAddress.bind(this);

        this.setWaitingTransaction = this.setWaitingTransaction.bind(this);
        this.stopWaitingTransaction = this.stopWaitingTransaction.bind(this);
        this.setErrorTransaction = this.setErrorTransaction.bind(this);
        this.setCompletedTransation = this.setCompletedTransation.bind(this);
    }

    async setAddress(address) {
        this.setState({ 'menu_address': address });
        
        new RequestBackend(address, EndPointMethod.GOATECH_MINTPAGE).run();
        
        const merlke = await getMerkleMint(address);
        if (merlke == null) {
            this.setState({ 'infoLoaded': true, 'mintEnabled': false });
            return;
        }
        this.setState({ 'mintProofs': merlke });

        // Carico tutte le info dal contratto
        const result = await ContractData.loadInfo();
        this.setState({ 'infoLoaded': true, ...result });
    }

    formatPrice(price, qnt) {
        let format = ethers.BigNumber.from(price).mul(qnt);
        format = ethers.utils.formatEther(format);
        return (+format).toLocaleString();
    }

    qntChange(tipo) {

        var mintQnt = this.state.mintQnt;
        if (tipo === "add") {
            mintQnt++;
        } else if (tipo === "rem") {
            mintQnt--;
            if (mintQnt < 0) {
                mintQnt = 0;
            }
        }

        this.setState({
            ...this.state,
            'mintQnt': mintQnt,
        });
    }

    mint() {
        if (this.state.mintQnt == 0) {
            showError({ 'titolo': 'Incorrect value', 'testo': 'Select at least 1 bale of hay' });
            return;
        }
        if (this.state.mintProofs.length == 0) {
            showError({ 'titolo': 'Mint not ready', 'testo': 'Please wait...' });
            return;
        }
        const data_mint = {
            'address': this.state.menu_address,
            'mintQnt': this.state.mintQnt,
            'proofs': this.state.mintProofs
        };
        
        this.setWaitingTransaction();
        GoatechHayMint.runMint(data_mint, this).then(result => {
            this.stopWaitingTransaction();
            if (result === true) {
                this.setCompletedTransation();
            } else {
                this.setErrorTransaction(result);
            }
        });
    }

    setWaitingTransaction(text = '') {
        this.setState({
            'transaction_waiting': true,
            'transaction_waiting_text': text
        });
    }

    stopWaitingTransaction() {
        this.setState({
            'transaction_waiting': false
        });
    }

    setErrorTransaction(error_msg) {
        this.setState({
            'transaction_error': true,
            'transaction_error_msg': error_msg
        });
    }

    setCompletedTransation() {
        this.setState({
            'transaction_complete': true
        });
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech' subMenu='mint-fieno' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding scroller scroller-stylized flex-mint-fieno'>
                                    <div className='container-mint-fieno'>
                                        <GoatechBtnMobile />
                                        <br />
                                        
                                        <div className={'mint-fieno-spinner ' + (this.state.infoLoaded && !this.state.transaction_waiting ? 'box-mint-hide' : '')} >
                                            {this.state.transaction_waiting && this.state.transaction_waiting_text == "" ? <p>Please check your wallet to confirm the transaction...</p> : null}
                                            {this.state.transaction_waiting && this.state.transaction_waiting_text != "" ? <p>{this.state.transaction_waiting_text}</p> : null}
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        </div>

                                        <div style={!this.state.mintGlobalEnabled || this.state.mintEnabled ? { display: 'none' } : { textAlign: 'center' }} >
                                            <h3>Goatech not enabled on this wallet</h3>
                                            <p>Your wallet is not yet ready to access Goatech since you do not have a Goat. If you have recently mint a Goat you should wait a few minutes...</p>
                                        </div>

                                        <div style={this.state.mintGlobalEnabled ? { display: 'none' } : { textAlign: 'center' }} >
                                            <h3>Mint is closed</h3>
                                            <p></p>
                                        </div>
                                        
                                        <div style={!this.state.mintEnabled || !this.state.mintGlobalEnabled || this.state.transaction_waiting || this.state.transaction_error || this.state.transaction_complete || !this.state.infoLoaded ? { display: 'none' } : {}} >
                                            <h3>Buy your Fieno</h3>
                                            <div className='box-mint-fieno box-mint-now'>
                                                <p className='box-mint-title'>Select the amount you want to mint</p>
                                                <div className='box-mint-fieno-cols'>
                                                    <div className='box-mint-fieno-col1'>
                                                        <img src="assets/img/account/goatech/28_FLOWER.png" />
                                                    </div>
                                                    <div className='box-mint-fieno-col2'>
                                                        <div className='boxinput-mint-qnt'>
                                                            <div className='mint-input-clmn1'>
                                                                <span onClick={() => this.qntChange('rem')} className='selector-mint-qnt'>
                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                </span>
                                                                <span id="total-qnt" className='total-qnt'>{this.state.mintQnt}</span>
                                                                <span onClick={() => this.qntChange('add')} className='selector-mint-qnt'>
                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='boxinput-mint-qnt boxtotal-mint'>
                                                            <div className='mint-input-clmn1 only-desktop'>
                                                                Total
                                                            </div>
                                                            <div className='mint-input-clmn2'>
                                                                {this.formatPrice(this.state.mintPrice, this.state.mintQnt)} USDC
                                                            </div>
                                                        </div>
                                                        <button onClick={() => this.mint()} className='mint-btn'>Mint and join GOATECH</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className={this.state.transaction_complete ? 'box-mint-show mint-completed' : 'box-mint-hide'}>
                                            <p className='titleTxComplete'>Success!</p>
                                            See your new Bale of Hay on:<br />
                                            <a href="/goatech-stats" >My Goatech Stats</a>
                                            <p style={{fontSize: 15}}>It may take a few minutes before you can see it in your account.</p>
                                        </div>

                                        <div className={this.state.transaction_error ? 'box-mint-show mint-failed-tx' : 'box-mint-hide'}>
                                            {this.state.transaction_error_msg}<br />
                                            <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => window.location.reload()}>try again</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goatech/gt212.png'})` }} className='account-goatech-g212'></div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatechMintFieno;