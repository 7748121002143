import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export class BtnCreateElement extends Component {
    constructor(props) {
        super(props);

        this.state = props;
    }

    render() {
        return (<button onClick={this.state.onClick} className={'btn-create-course'}><FontAwesomeIcon icon={faPlus}/>{this.state.testo}</button>);
    }
}

export default BtnCreateElement;