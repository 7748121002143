import React, { Component } from 'react';

export const AffiliateProfitBadgeEnum = {
    PENDING: 'PENDING',
    PAYED: 'PAYED'
};

export const AffiliateProfitBadgeText = {
    PENDING: 'Pending',
    PAYED: 'Paid'
};

export const AffiliateProfitBadgeClass = {
    PENDING: 'badge-pending',
    PAYED: 'badge-active'
};

export class AffiliateProfitBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'badge': props.badge
        }
    }
    render() {
        return (<span id={this.props.id} className={'stats-badge ' + AffiliateProfitBadgeClass[this.state.badge]} >{AffiliateProfitBadgeText[this.state.badge]}</span>);
    }
}

export default AffiliateProfitBadge;