
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";
import GoatMintBtn from "../ui/GoatMintBtn";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div className='section2-background section-esim-background' style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/HomeGoateSim/background.png'})` }}>
                    <div className="container" style={{ position: 'relative' }}>
                        <div style={{ paddingTop: 50 }} className="section2-left">
                            <BadgeHome testo={testi("goat_esim_label")}  style={{ color: '#20FFAF',textTransform: 'uppercase' }} />
                            <h3 className="h3-section2">E-SIM GOAT</h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goat_esim_parag') }} style={{fontSize: 19, lineHeight: '28.8px'}} className="parag-section2"></p>
                            <GoatMintBtn/>
                        </div>
                        <div className="section2-right">
                            <img src="assets/img/HomeGoateSim/hand.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;