
export const lingua_sito = () => {
    var selected_lang = "en";
    if (localStorage.getItem("language")) {
        if (localStorage.getItem("language") == "it") {
            selected_lang = "it";
        }
    }
    return selected_lang;
}

export const testi = (key) => {
    const selected_lang = lingua_sito();
    if (lang[selected_lang][key]) {
        return lang[selected_lang][key];
    }
    return lang["en"][key];
}

const lang = {
    en: {

        "header_whitepaper_link": "https://goat-nation.gitbook.io/goat-nation-whitepaper/",

        "welcome_h1": "WELCOME TO<br />THE GOAT NATION",
        "welcome_pricemint": "PRICE: $1.000 (ETH actual price)",
        "welcome_mint_closed_parag": "Over <u>$2,000,000</u> generated at the Mint",
        "welcome_buyon_opensea": "Buy on Opensea",

        "fromday0_h3": "The best utilities,<br />from day 0",
        "fromday0_parag": "Imagine having access to the best utilities on the market since the first day you minted your NFTs... well, thanks to GOAT NATION this is now a reality.<br /><br />" +
            "Our goal is to personally and financially grow our holders, taking them from 0 to GOAT (Greatest Of All Time) in all areas of life. We will create a true Nation, the Goat Nation... The first decentralized nation in the real world.<br /><br />\n" +
            "What is the most impactful thing for a man's success?<br />\n" +
            "THE ENVIRONMENT. Anything you may have or not have at this moment depends on the environment that surrounds you. GOAT NATION is creating the best environment for people who want to be more, achieve more and have more.",

        "goatnation_h3": "WE ARE ALL GOAT NATION",
        "goatnation_parag": "What is the thing that impact the most on the success of a human?<br />THE ENVIRONMENT. Whatever you might have or not have right now depends on the environment that surrounds You. GOAT NATION is creating the best environment for people who wants to BE MORE, ACHIEVE MORE and HAVE MORE",
        "goatmap_h3": "THE GOATMAP",
        "goatmap_parag": "From 0 to GOAT",

        "goat_nft_level_title": 'NFT Levels',
        "goat_nft_level_subtitle": 'Based on how many Goat Nation NFTs you own, you will have access to exclusive services and treatments<br/> within the community. Specifically:',
        "goat_nft_level_1": 'Initial access to the Nation',
        "goat_nft_level_2": 'Access to the <b>Holders Discord</b>',
        "goat_nft_level_3": 'Access to the <b>complete academy</b> on Crypto and Blockchain (Mint)',
        "goat_nft_level_4": 'Access to <b>Goat-Chart</b>',
        "goat_nft_level_5": 'Access to <b>Goatech (Bronze License)</b>',
        "goat_nft_level_6": 'Access to <b>Weekly training live</b>',
        "goat_nft_level_7": 'Produce <b>$LATTE</b> tokens',
        "goat_nft_level_8": 'Possibility to generate Baby Goat for free',
        "goat_nft_level_9": 'Alpha Goat',
        "goat_nft_level_10": 'Exclusive training live',
        "goat_nft_level_11": 'Access to <b>Goatech (Silver License)</b>',
        "goat_nft_level_12": 'Exclusive treatments for Goat events',
        "goat_nft_level_13": 'Access to <b>Goatech (Gold License)</b>',
        "goat_nft_level_14": 'VIP treatments and Goat events',
        "goat_nft_level_15": 'Future access to Corporate shares',

        /*
        "goatroadmap_gennaio2023": "January 2023",
        "goatroadmap_febbraio2023": "February 2023",
        "goatroadmap_marzo2023": "March 2023",
        "goatroadmap_aprile2023": "April 2023",
        "goatroadmap_maggio2023": "May 2023",
        "goatroadmap_giugno2023": "June 2023",
        "goatroadmap_fine2023": "By the end of 2023",
        */

        "goatroadmap_gennaio2023": "Phase 1",
        "goatroadmap_febbraio2023": "Phase 2",
        "goatroadmap_marzo2023": "Phase 3",
        "goatroadmap_aprile2023": "Phase 4",
        "goatroadmap_maggio2023": "Phase 5",
        "goatroadmap_giugno2023": "Phase 6",
        "goatroadmap_fine2023": "Phase 7",

        "goat_to_mars_h3": "GOAT TO MARS",
        "goat_to_mars_parag": "True GOATs know their way around, understand the value of projects instantly, and know that minting is where they take home the biggest advantage.<br />Purchasing a GOAT in minting will entitle you to lifetime access to the MARS Academy.<br /><br />You can look at it as a €1,500 pass but it's much more than that, it's your ticket to the most comprehensive knowledge base in the crypto, NFT and exchange markets.<br /><br />How fast are you?",

        "goatech_h3": "GOATECH",
        "goatech_parag": "To be a GOAT in the Crypto market, knowledge alone is not enough. You have to combine it with technology and experience.<br/><br/>\n" +
            "We have assembled the best knowledge and designed the best technology to go\n" +
            "beyond expectations.\n" +
            "Goatech is the first exclusive service for Goat Nation holders that allows you to have\n monthly rewards simply by buying hay bales.",
        "goatech_column_1": "We use artificial intelligence to operate on BTC-USDT",
        "goatech_column_2": "We perform mining of cryptocurrencies specifically BTC",
        "goatech_column_3": "Manual Trading strategy on Crypto, Forex and Indices.",
        "goatech_platform_title": "How the platform works",
        "goatech_platform_subtitle": "You will just have to buy the hay bale to become a part of Goatech.<br/>\n" +
            "The rewards generated will be sent automatically every month to your wallet. You don't have to\n" +
            "worry about doing any operations or setting reminders.<br/>\n" +
            "For greater transparency, we have also included the statistics section where you will see all the\n" +
            "detailed information and the history of rewards.",

        "goatech_title_comeopera": "How the company operates",

        "goatech_platform_buget_title": "How the Goatech Budget is allocated",
        "goatech_platform_buget_parag": "The entire Goatech service budget is allocated precisely in different compartments to diversify and make Goatech and its results more secure and long-lasting.<br />\n" +
            "It is divided into percentages as can be observed on the chart next to it.",
        "goatech_levels_title": 'How the 3 levels of Goatech work',
        "goatech_levels_parag": "Goatech has 3 levels within its program that allow you to receive different rewards based on\n" +
            "how many Goat Nation NFTs you own.<br/>\n" +
            "<br/>\n" +
            "<b>Specifically</b>:<br/>\n" +
            "From 1 to 4 NFTs, you have up to 4% of monthly rewards*<br/>\n" +
            "From 5 to 9 NFTs, you have up to 5% of monthly rewards*<br/>\n" +
            "From 10 NFTs and above, you have up to 6% of monthly rewards.*<br/>",
        "goatech_moreinfo": "More info",

        "goatrex_h3": "GOATREX",
        "goatrex_parag": "Forex: another world full of sheep filling their mouths and GOATs making a difference... and real money.<br />Have a BABY GOAT will give you access to managed GOAT Fund investment strategies; What does that mean?<br /><br />Stake your BABY GOAT and enjoy monthly percentage returns on capital.",

        "goatestate_h3": "GOAT ESTATE",
        "goatestate_parag": "Imagine to feel like home in every part of the world. Imagine being resident of the best places you’ve ever dreamed of. We are taking REAL ESTATE to a complete next level.<br /><br />Be sure to have both GENESIS and BABY, and you will have access to the third collection.<br /><br />The ALIEN GOAT. Your Goats will be abducted and strange experiments will make remarkables alterations to it. Be ready for the Revolution of this industry, in the real world and in the Metaverse",

        "goatalphagoat_h3": "Alphagoat Club",
        "goatalphagoat_parag": "That’s La crème de la crème. To earn the status of AlphaGOAT you will have to have a minimun of 5 GOATs in your wallet.<br />It won't be a matter of collecting, mind you, but of gaining access to the Discord of the Élite and then being able to preview theservices offered in Roadmap and participate in AlphaTalks, moments of meeting and exchange of ideas for a few, very few.<br /><br />Only the members of the AlphaGoat Club will have access to the GOAT-MIND: an exclusive Mastermind with 4 Real Goats in the entrepreneurial world that will attend 1 video-call per week for ONE YEAR. That’s what we call, a shortcut for success.<br /><br />The more information you have, the more opportunities you have to break through. Even under the AlphaGoat Club, access to groups and information will be regulated by the number of GOATs owned.<br />",

        "goatacademy_h3": "GOAT ACADEMY",
        "goatacademy_parag": "The GOAT NATION Academy is an entire journey where we will talk about NFTs, Mindset, Leadership, Subconscious Mind Reprogramming, and much more. <br /><br />The contents of the academy will be dynamic will evolve during time and will be updated in order to provide the best tools and informations for you to become a real GOAT in every aspect of your life.",

        "goatteam_h3": "THE GOATeam",
        "goatteam_parag": "When the going gets tough it's the team that makes the difference.",

        "goatart_h3": "GOAT ART",
        "goatart_parag": "The Goat Nation Collection was developed in collaboration with <b>Filippo Ghisleri.</b><br />\n" +
            "Filippo is a freelance Digital Artist, after graduating in Art Direction and Graphic Design at the Fine Arts University of Brescia, he works with some of the biggest artists in the Italian music scene such as: Fedez, Vegas Jones, Dark Polo Gang, Giaime etc." +
            "Within 1 year he enters the digital fashion market, creating advertising campaigns and 3D animations for international brands such as: Armani, GCDS, Lipault, Pyrex, Disclaimer, Adidas x Y-3.",

        "faq_h3": "FAQ",
        "faq_parag": "If something is still not clear, we have gathered the most frequent questions and answers for you below.",
        "faq_quest1": "What’s GOAT Nation?",
        "faq_resp1": "GOAT Nation is a collection of 4,444 unique 3D GOATs randomly generated, hunting on the ETH Blockchain as ERC-721 NFTs and hosted on IPFS.",
        "faq_quest2": "What is the $LATTE token?",
        "faq_resp2": "The $LATTE token is the exclusive token of Goat Nation that will allow you to access exclusive products and services.",
        "faq_quest3": "How do I mint?",
        "faq_resp3": "You can mint by <a href='https://goat-nation.com/mint'>clicking here</a>. If you need a tutorial, you can watch <a href='https://www.youtube.com/watch?v=XfrqF_YP-fU'>this video.</a>",
        "faq_quest4": "I bought my NFT, now what?",
        "faq_resp4": "You can access the services and the private area directly on our official <a href='https://discord.com/goatnation'>discord here.</a>",
        "faq_quest5": "What changes if I have one or more Goats?",
        "faq_resp5": "We have simplified the levels within Goat Nation and you can view them <a href='#levels-table'>here</a>.",
        "faq_quest6": "What is Goatech?",
        "faq_resp6": "Goatech is an exclusive service of Goat Nation. You will simply have to purchase the bale of hay to become a part of Goatech. More information on <a href='#goatech'>Rewards and bonuses here.</a>",

        // Goat mind
        "goatmind_1_img": "lesbrown.png",
        "goatmind_1_titolo": "Les Brown",
        "goatmind_1_descr": "<b>Les Brown</b> is a former American politician, TV host and radio DJ and recognized as one of the greatest motivational speakers in the world. Over the past four decades, he has transformed numerous lives with his speeches relating to almost all aspects of life. He attracted attention with his show entitled \"The Les Brown Show\", where he taught people to follow their dreams and reiterate the phrase \"it is possible\", emphasizing the need to seek solutions rather than meditate on problems.",

        "goatmind_2_img": "sibaldi.png",
        "goatmind_2_titolo": "Igor Sibaldi",
        "goatmind_2_descr": "Philologist, writer, narrator and creator of the Technique of 101 Wishes. The path with him will start right from here, from desires. The essential first step to success. We are ready to be enchanted by his deep voice and his unique teachings.",

        "goatmind_3_img": "gasparotto.png",
        "goatmind_3_titolo": "Mirco Gasparotto",
        "goatmind_3_descr": "<b>Mirco Gasparotto</b> is the embodiment of the Italian self-made man. He started in 1983 as a simple delivery man: for years he transported heavy welding gas cylinders all day, every day around Veneto. Today, after almost 40 years of career, he is the President of a company named Arroweld Italia Group, leader in Italy in the welding sector that in 2020 hit the company turnover record: 102 million euros, with 1000 active collaborators and then improved it in 2021 closing at 110 million euros. Today Mirco Gasparotto provides the wealth of experience, mistakes and knowledge built over 38 years of career, to help entrepreneurs, professionals and aspirants to improve their professional and personal lives.",

        "goatmind_4_img": "borra.png",
        "goatmind_4_titolo": "Sergio Borra",
        "goatmind_4_descr": "Founder of <b>Dale Carnegie Italia</b> with over 30 years of experience in the field of managerial and business training. During his professional activity he met and contributed to the success of <b>175,000 people</b>, providing them with the tools useful for achieving personal and professional goals, also promoting the development and acquisition of winning best practices.",

        "servizi_title": "Other upcoming services",
        "servizi_academy": "Want to improve your skills and expand your knowledge?\n" +
            "Goat Academy is the solution!\n" +
            "A set of training courses that offer you a 360-degree understanding of the world of online business and more!\n" +
            "In addition, within Goat Academy, you will also have access to recordings of past events and a wide range of resources to continue your professional growth",
        "servizi_goatrex": "Are you ready to become a true Forex expert? Goatrex, the second exclusive service for Goat Nation members, offers you the opportunity to do so... automatically!\n" +
            "You will have access to unique tools and resources to help you achieve consistent rewards every month.\n" +
            "Its detailed operation will be introduced at the time of launch, which will be linked to the collection of Baby Goats.<br /><br />",

        "eventi_community_title": "EVENTS AND COMMUNITY",
        "eventi_community_parag": "The Goat Nation Community is strong and solid, we put every single holders ahead of all our priorities.<br/>\n" +
            "We have already participated and organized several live events.",

        "eventi_community_1_title": "Dubai",
        "eventi_community_1": "The first official event by Goat Nation took place in Dubai, where three days were devoted to personal training, extraordinary experiences, and new knowledge, introducing the 2023 program of Goat Nation to those present.",

        "eventi_community_2_title": "Rome",
        "eventi_community_2": "Blockchain Week Rome is the largest Italian event dedicated to the world of cryptocurrencies. The event took place from May 24th to 28th, 2022 in Piazza di Spagna in Rome with speakers and influential figures in the sector.",

        "eventi_community_3_title": "Lugano",
        "eventi_community_3": "MetaForum Lugano is the first edition of an event that focuses on today's main themes: Blockhain, Crypto, NFT, Gamification & Play-to-earn. Goat Nation participated in the event and was an official partner of MetaForum.",

        "eventi_community_4_title": "Naples",
        "eventi_community_4": "Event organized by one of the largest blockchain communities in Europe to which we were invited as an official partner",

        "partner_title": "PARTNERS WHO CHOSE GOAT NATION",

        "disclaimer": "***DISCLAIMER***\n" +
            "We do not believe in get-rich-quick schemes. We believe in hard work, adding value and serving others. And that is what our programs are designed to help you do.\n" +
            "In compliance with the law, we cannot and do not make any guarantees about your ability to get results or earn money with our ideas, information, programs or strategies.\n" +
            "We do not know you and, furthermore, your results in life are up to you. Everything should be taken into consideration in light of your financial situation and attitude towards risk.\n" +
            "Agreed? We are here to help by providing our best strategies to help you move forward, faster.\n" +
            "However, nothing on this page or any of our websites, emails, social posts or one-to-one communications is a promise or guarantee of future earnings.\n" +
            "The price and/or value, particularly of crypto, as you well know, can fluctuate rapidly at any time. As such, the risk of holding crypto can be substantial.\n" +
            "We are not financial advisers and the data, opinions, and contents of our ideas, information, programs or strategies (i) have an exclusively informative character and do not constitute a “solicitation to invest” and/or an offer,\n" +
            "nor a recommendation to buy and/or sell and/or exchange crypto and/or NFT and/or financial instruments and/or any type of investment services; (ii) are simply estimates, projections and/or past results and should not be considered accurate,\n" +
            "actual or as a promise of potential earnings, all numbers are for illustration purpose only and, anyhow, you undertake not to disclose to third parties.",

        "goatchart_label": "Trading services",
        "goatchart_parag": "Goat Chart is the autonomous trading service designed for Goat Nation holders, who can follow the operations of our expert and profitable trader! <br /><br />" +
            "You will have access to daily market analysis, a private chat with all active members using the Goat Chart service, to exchange opinions, ideas and increase your know-how on the world of cryptocurrency trading.",

        "goat_esim_label": "International connectivity",
        "goat_esim_parag": "Goat Nation's E-SIMs are virtual, exclusive, and international SIM cards reserved for project holders. <br /><br />" +
            "This service will allow you to make unlimited and encrypted calls and browse the internet from anywhere in the world, thanks to your personal VPN. You can use your personal room for video conferencing, making your work meetings or chats with friends and family even more secure and protected. <br />" +
            "Furthermore, when you use your Goat E-SIM, your phone will display the operator \"GOAT\", thus showing your support for Goat Nation and your membership in the community. <br />" +
            "The service is available in over 100 countries worldwide and is the ideal choice for those who want a secure, private and unlimited connection wherever they are."
    },
    it: {

        "header_whitepaper_link": "https://goat-nation.gitbook.io/goat-nation-whitepaper/",

        "welcome_h1": "BENVENUTO<br />SU GOAT NATION",
        "welcome_pricemint": "PREZZO: $1.000 (ETH Prezzo attuale)",
        "welcome_mint_closed_parag": "Oltre <u>$ 2.000.000</u> generati al Mint",
        "welcome_buyon_opensea": "Acquista su Opensea",

        "fromday0_h3": "Le migliori utilities,<br />dal giorno 0",
        "fromday0_parag": "Immagina di avere accesso alle migliori utility sul mercato dal primo giorno in cui hai mintato i tuoi NFT... beh, grazie a GOAT NATION questa è ora realtà.<br ><br />\n" +
            "Il nostro obiettivo è quello di far crescere a livello personale e finanziario i nostri holders, portandoli da 0 a GOAT (Greatest Of All Time) in tutte le aree della vita. Creeremo così una vera Nazione, la Goat Nation... La prima nazione decentralizzata nel mondo reale.<br /><br />\n" +
            "Qual è la cosa che incide di più sul successo di un uomo?<br />\n" +
            "L'AMBIENTE. Qualsiasi cosa tu possa avere o non avere in questo momento dipende dall'ambiente che ti circonda. GOAT NATION sta creando l'ambiente migliore per le persone che vogliono essere di più, ottenere di più e avere di più.",

        "goatnation_h3": "SIAMO TUTTI PARTE DI GOAT NATION",
        "goatnation_parag": "Qual è la cosa che incide di più sul successo di un uomo?<br />L'AMBIENTE. Qualsiasi cosa tu possa avere o non avere in questo momento dipende dall'ambiente che ti circonda. GOAT NATION sta creando l'ambiente migliore per le persone che vogliono essere di più, ottenere di più e avere di più.",
        "goatmap_h3": "THE GOATMAP",
        "goatmap_parag": "Dal giorno 0 a GOAT",

        "goat_nft_level_title": 'NFT Levels',
        "goat_nft_level_subtitle": 'In base a quanti NFT di Goat Nation possiedi potrai accedere a servizi e trattamenti<br/>esclusivi all’interno della community. Nello specifico:',
        "goat_nft_level_1": 'Accesso iniziale alla Nazione',
        "goat_nft_level_2": 'Accesso al <b>Discord Holders</b>',
        "goat_nft_level_3": 'Accedi <b>all\'accademia completa</b> su Crypto e Blockchain (Mint)',
        "goat_nft_level_4": 'Accedi a <b>Goat-Chart</b>',
        "goat_nft_level_5": 'Accedi a <b>Goatech (Licenza Bronzo)</b>',
        "goat_nft_level_6": 'Accedi alle <b>Live di formazione Settimanali</b>',
        "goat_nft_level_7": 'Produci token <b>$LATTE</b>',
        "goat_nft_level_8": 'Possibilità di generare Baby Goat in modo gratuito',
        "goat_nft_level_9": 'Alpha Goat',
        "goat_nft_level_10": 'Live di Formazioni Esclusive',
        "goat_nft_level_11": 'Accedi a <b>Goatech (Licenza Argento)</b>',
        "goat_nft_level_12": 'Trattamenti riservati ad eventi Goat',
        "goat_nft_level_13": 'Accedi a <b>Goatech (Licenza Oro)</b>',
        "goat_nft_level_14": 'Trattamenti VIP ed eventi Goat',
        "goat_nft_level_15": 'Accesso futuro alle Shares Aziendali',

        /*
        "goatroadmap_gennaio2023": "Gennaio 2023",
        "goatroadmap_febbraio2023": "Febbraio 2023",
        "goatroadmap_marzo2023": "Marzo 2023",
        "goatroadmap_aprile2023": "Aprile 2023",
        "goatroadmap_maggio2023": "Maggio 2023",
        "goatroadmap_giugno2023": "Giugno 2023",
        "goatroadmap_fine2023": "Entro fine 2023",
        */

        "goatroadmap_gennaio2023": "Fase 1",
        "goatroadmap_febbraio2023": "Fase 2",
        "goatroadmap_marzo2023": "Fase 3",
        "goatroadmap_aprile2023": "Fase 4",
        "goatroadmap_maggio2023": "Fase 5",
        "goatroadmap_giugno2023": "Fase 6",
        "goatroadmap_fine2023": "Fase 7",

        "goat_to_mars_h3": "GOAT SU MARTE",
        "goat_to_mars_parag": "I veri GOAT sanno come muoversi, capiscono il valore dei progetti all'istante e sanno che il minting è dove loro portano a casa il maggior vantaggio.<br />L'acquisto di un GOAT nel minting ti darà diritto all'accesso a vita alla MARS Academy.<br /><br />Puoi considerarlo come un pass da 1.500 euro, ma è molto di più, è il tuo biglietto per la più completa base di conoscenze nel mercato delle criptovalute, NFT e degli scambi.<br /><br />Quanto sei veloce?",

        "goatech_h3": "GOATECH",
        "goatech_parag": "Per essere un GOAT nel mercato Crypto, la conoscenza non è sufficiente. Devi combinarla con la tecnologia e l'esperienza.<br/><br/>\n" +
            "Abbiamo assemblato le migliori conoscenze e progettato la migliore tecnologia per andare\n" +
            "oltre ogni aspettativa.\n" +
            "Goatech è il primo servizio esclusivo per gli Holders di Goat Nation che permette di avere\n Rewards ogni mese semplicemente acquistando balle di fieno.",
        "goatech_column_1": "Utilizziamo l’intelligenza artificiale per operare su BTC-USDT",
        "goatech_column_2": "Effettuiamo Mining di cryptovalute nello specifico di BTC",
        "goatech_column_3": "Strategia di Trading manuale su Crypto, Forex ed Indici.",
        "goatech_platform_title": "Come funziona la piattaforma",
        "goatech_platform_subtitle": "Ti basterà acquistare la balla di fieno per entrare a far parte di Goatech.<br/>\n" +
            "I rewards generati verranno inviati ogni mese in modo automatico nel tuo wallet. Non devi\n" +
            "preoccuparti di fare nessuna operazione o di mettere reminder.<br/>\n" +
            "Per una maggiore trasparenza abbiamo inserito anche la sezione statistiche dove vedrete tutte le\n" +
            "informazioni al dettaglio e lo storico delle rewards.",

        "goatech_title_comeopera": "Come opera l'azienda",
        "goatech_platform_buget_title": "Come viene allocato il Budget Goatech",
        "goatech_platform_buget_parag": "Tutto il budget del servizio Goatech viene allocato in maniera precisa in differenti comparti per diversificare e rendere più sicuro e longevo Goatech e i suoi risultati.<br /><br />\n" +
            "Viene suddiviso in percentuali come si può osservare sul grafico qui vicino.",
        "goatech_levels_title": 'Come funzionano i 3 livelli di Goatech',
        "goatech_levels_parag": "Goatech ha 3 livelli all’interno del suo programma che permettono di ricevere reward differenti in\n" +
            "base a quanti NFT di Goat Nation si possiedono.<br/>\n" +
            "<br/>\n" +
            "<b>Rispettivamente</b>:<br/>\n" +
            "Da 1 a 4 NFT si ha fino al 4% di Rewards mensili*<br/>\n" +
            "Da 5 a 9 NFT si ha fino al 5% di Rewards mensili*<br/>\n" +
            "Da 10 NFT in poi si ha fino al 6% di Rewards mensili.*<br/>",
        "goatech_moreinfo": "Scopri maggiori informazioni",

        "goatrex_h3": "GOATREX",
        "goatrex_parag": "Forex: un altro mondo pieno di pecore che si riempiono la bocca e di GOAT che fanno la differenza... e soldi veri.<br />Avere un BABY GOAT ti darà accesso alle strategie di investimento del Fondo GOAT; cosa significa?<br /><br />Metti in stacking il tuo BABY GOAT e goditi i ritorni percentuali mensili sul capitale.",

        "goatestate_h3": "GOAT ESTATE",
        "goatestate_parag": "Immaginate di sentirvi a casa in ogni parte del mondo. Immagina di essere residente nei posti migliori che hai sempre sognato. Stiamo portando REAL ESTATE ad un livello completamente superiore.<br /><br />Assicurati di avere sia GENESIS che BABY, e avrai accesso alla terza collezione.<br /><br />La capra aliena. Le vostre capre saranno rapite e strani esperimenti ne faranno delle alterazioni notevoli. Siate pronti per la rivoluzione di questa industria, nel mondo reale e nel Metaverso",

        "goatalphagoat_h3": "Alphagoat Club",
        "goatalphagoat_parag": "La crème de la crème. Per guadagnare lo status di AlphaGOAT dovrai avere un minimo di 5 GOAT nel tuo portafoglio.<br />Non si tratterà di collezionare, attenzione, ma di ottenere l'accesso al Discord dell'Élite e quindi poter provare in anteprima i servizi offerti in Roadmap e partecipare agli AlphaTalks, momenti di incontro e scambio di idee per pochi, pochissimi.<br /><br />Solo i membri dell'AlphaGoat Club avranno accesso al GOAT-MIND: un esclusivo Mastermind con 4 Veri GOAT del mondo imprenditoriale che parteciperanno a 1 video-call a settimana per UN ANNO. Questo è quello che noi chiamiamo, una scorciatoia per il successo.<br /><br />Più informazioni hai, più opportunità hai di sfondare. Anche sotto il Club AlphaGoat, l'accesso ai gruppi e alle informazioni sarà regolato dal numero di GOAT posseduti.",

        "goatacademy_h3": "ACADEMY GOAT",
        "goatacademy_parag": "La GOAT NATION Academy è un intero percorso dove parleremo di NFT, Mindset, Leadership, Riprogrammazione della Mente Subconscia e molto altro ancora.<br /><br />I contenuti dell'accademia saranno dinamici, si evolveranno nel tempo e saranno aggiornati per fornirti i migliori strumenti e informazioni per diventare un vero GOAT in ogni aspetto della tua vita.",

        "goatteam_h3": "Il GOATeam",
        "goatteam_parag": "Quando il gioco si fa duro è la squadra che fa la differenza.",

        "goatart_h3": "GOAT ART",
        "goatart_parag": "La Collezione Goat Nation è stata sviluppata in collaborazione con <b>Filippo Ghisleri.</b><br />\n" +
            "Filippo è un Digital Artist freelance, dopo la laurea in Art Direction e Graphic Design all’università di belle Arti di Brescia lavora con alcuni dei più grandi artisti nella scena musicale Italiana come: Fedez, Vegas Jones, Dark polo Gang, Giaime ecc." +
            "Dopo neanche 1 anno entra nel mercato del digital fashion, realizzando campagne pubblicitarie e animazioni 3D per brand internazionali come: Armani, GCDS, Lipault, Pyrex, Disclaimer, Adidas x Y-3.",

        "faq_h3": "FAQ",
        "faq_parag": "Se qualcosa ancora non ti è chiaro, abbiamo racchiuso le domande e le<br />risposte più frequenti qui sotto per te.",
        "faq_quest1": "Cos'è GOAT Nation?",
        "faq_resp1": "GOAT Nation è una raccolta di 4.444 GOAT 3D univoci generati casualmente nella Blockchain ETH come NFT ERC-721 e ospitati in IPFS.",
        "faq_quest2": "Cos’è il token $LATTE?",
        "faq_resp2": "Il token $LATTE è il token esclusivo di Goat Nation che ti permetterà di accedere a prodotti e servizi esclusivi.",
        "faq_quest3": "Come mintare?",
        "faq_resp3": "Per mintare puoi farlo <a href='https://goat-nation.com/mint'>da qui</a>. Se hai bisogno di un tutorial puoi guardare <a href='https://www.youtube.com/watch?v=tJi_WMqwjsI'>questo video.</a>",
        "faq_quest4": "Ho comprato il mio NFT e ora?",
        "faq_resp4": "Puoi accedere ai servizi e all’area riservata direttamente sul nostro discord ufficiale <a href='https://discord.com/goatnation'>da qui.</a>",
        "faq_quest5": "Cosa cambia se ho una o più Goat?",
        "faq_resp5": "Abbiamo semplificato i livelli all’interno di Goat Nation e puoi visionarli da <a href='#levels-table'>qui.</a>",
        "faq_quest6": "Cosa è Goatech?",
        "faq_resp6": "Goatech è un servizio esclusivo di Goat Nation. Ti basterà acquistare la balla di fieno per entrare a far parte di Goatech. Maggiori informazioni su <a href='#goatech'>Rewards e bonus qui.</a>",

        // Goat mind
        "goatmind_1_img": "lesbrown.png",
        "goatmind_1_titolo": "Les Brown",
        "goatmind_1_descr": "<b>Les Brown</b> è un ex politico americano, conduttore televisivo e DJ radiofonico e riconosciuto come uno dei più grandi oratori motivazionali del mondo.<br />Negli ultimi quattro decenni ha trasformato numerose vite con i suoi discorsi relativi a quasi tutti gli aspetti della vita.<br />Ha attirato l'attenzione cob il suo spettacolo intitolato <i>\"The Les Brown Show\"</i>, dove ha insegnato alle persone a seguire i loro sogni e ribadire la frase \"è possibile\", sottolineando il bisogno di cercare soluzioni piuttosto che meditare sui problemi.",

        "goatmind_2_img": "sibaldi.png",
        "goatmind_2_titolo": "Igor Sibaldi",
        "goatmind_2_descr": "Filologo, scrittore, narratore e ideatore della Tecnica dei 101 Desideri. Il percorso con lui inizierà proprio da qui, dai desideri.<br />Il primo passo essenziale per avere successo. Siamo pronti a farci incantare dalla sua voce profonda e dai suoi insegnamenti unici.",

        "goatmind_3_img": "gasparotto.png",
        "goatmind_3_titolo": "Mirco Gasparotto",
        "goatmind_3_descr": "<b>Mirco Gasparotto</b> è l'incarnazione del self-made man italiano. Ha iniziato nel 1983 come semplice fattorino: per anni ha trasportato pesanti bombole di gas per saldatura tutto il giorno, tutti i giorni in giro per il Veneto. Oggi, dopo quasi 40 anni di carriera, è Presidente di una società denominata Gruppo Arroweld Italia, leader in Italia nel settore della saldatura che nel 2020 ha raggiunto il record di fatturato aziendale: 102 milioni di euro, con 1000 collaboratori attivi per poi migliorarlo in Chiusura 2021 a 110 milioni di euro.<br />Oggi Mirco Gasparotto mette a disposizione il patrimonio di esperienze, errori e conoscenze costruito in 38 anni di carriera, per aiutare imprenditori, professionisti e aspiranti a migliorare la propria vita professionale e personale.",

        "goatmind_4_img": "borra.png",
        "goatmind_4_titolo": "Sergio Borra",
        "goatmind_4_descr": "Fondatore di Dale Carnegie Italia con oltre 30 anni di esperienza nel campo della formazione manageriale e aziendale. Durante la sua attività professionale ha incontrato e contribuito al successo di 175.000 persone, fornendo loro gli strumenti utili al raggiungimento degli obiettivi personali e professionali, favorendo anche lo sviluppo e l'acquisizione di best practices vincenti.",

        "servizi_title": "Altri servizi in arrivo",
        "servizi_academy": "Volete migliorare le vostre abilità e ampliare le vostre conoscenze?\n" +
            "Goat Academy è la soluzione!\n" +
            "Un’insieme di corsi di formazione che vi offrono una conoscenza a 360 gradi sul mondo del business online e non solo!\n" +
            "Inoltre all'interno della Goat Academy, avrete anche accesso alle registrazioni degli eventi passati e ad una vasta gamma di risorse per continuare la vostra crescita professionale",
        "servizi_goatrex": "Siete pronti per diventare dei veri esperti del Forex? Goatrex, il secondo servizio esclusivo per i membri di Goat Nation, vi offre l'opportunità di farlo… in modo automatico!\n" +
            "Avrete accesso a strumenti e risorse uniche per aiutarvi ad ottenere rewards costanti ogni mese.\n" +
            "Il suo funzionamento al dettaglio verrà introdotto al momento del lancio, che sarà collegato alla collezione dei Baby Goat.<br /><br />",

        "eventi_community_title": "EVENTI E COMMUNITY",
        "eventi_community_parag": "La Community di Goat Nation è forte e solida, mettiamo ogni singolo holders davanti a tutte le\n" +
            "nostre priorità.<br/>\n" +
            "Abbiamo già partecipato e organizzato diversi eventi dal vivo.",

        "eventi_community_1_title": "Dubai",
        "eventi_community_1": "A dubai si è svolto il primo evento ufficiale targato Goat Nation dove si sono sviluppate 3 giornate all'insegna della formazione personale, di esperienze straordinare e nuove conoscenze, introducendo in anteprima ai presenti tutto il programma 2023 di Goat Nation.",

        "eventi_community_2_title": "Roma",
        "eventi_community_2": "Blockchain Week Rome è il più grande evento italiano dedicato al mondo delle criptovalute. L’ evento si è tenuto dal 24 al 28 Maggio 2022 in piazza di Spagna a Roma con speaker e personalità influenti del settore.",

        "eventi_community_3_title": "Lugano",
        "eventi_community_3": "MetaForum Lugano è la prima edizione di un evento che focalizza la sua attenzione sui temi principali di oggi: Blockhain, Crypto, NFT, Gamification & Play-to-earn. Goat Nation ha partecipato all'evento ed è stato partner del MetaForum.",

        "eventi_community_4_title": "Napoli",
        "eventi_community_4": "Evento organizzato da una delle community blockchain più grande d’europa al quale siamo stati invitati come parter ufficiali",

        "partner_title": "PARTNER CHE HANNO SCELTO GOAT NATION",

        "disclaimer": "***DISCLAIMER***\n" +
            "Non crediamo nei programmi per arricchirsi velocemente. Crediamo nel duro lavoro, nell'aggiungere valore e nel servire gli altri. Ed è ciò che i nostri programmi sono progettati per aiutarti a fare.\n" +
            "In conformità alla legge, non possiamo e non forniamo alcuna garanzia sulla tua capacità di ottenere risultati e/o guadagnare denaro con le nostre idee, informazioni, programmi o strategie.\n" +
            "Non ti conosciamo e, inoltre, i tuoi risultati nella vita dipendono da te. Tutto deve essere preso in considerazione alla luce della tua situazione finanziaria e dell’attitudine al tuo rischio.\n" +
            "Concordato? Siamo qui per aiutarti fornendoti le nostre migliori strategie per farti andare avanti, più velocemente.\n" +
            "Tuttavia, nulla in questa pagina e/o in nessuno dei nostri siti Web e/o e-mail e/o post sui social e/o comunicazioni one-to-one è una promessa e/o garanzia di guadagni futuri.\n" +
            "Il prezzo e/o il valore, in particolare delle crypto, come ben sai, può aumentare e/o diminuire rapidamente in qualsiasi momento. Di conseguenza il rischio di perdita detenendo crypto può essere sostanziale.\n" +
            "Non siamo dei consulenti finanziari ed i dati, le opinioni ed i contenuti delle nostre idee, informazioni, programmi o strategie (i) hanno carattere esclusivamente informativo e non rappresentano una “sollecitazione all’investimento” e/o una offerta,\n" +
            "né una raccomandazione all’acquisto e/o alla vendita e/o scambio di crypto e/o di NFT e/o strumenti finanziari e/o di qualsiasi tipologia di servizi di investimento; (ii) sono semplicemente stime, proiezioni e/o risultati passati e non devono essere considerati esatti,\n" +
            "effettivi e/o come una promessa di potenziali guadagni, tutti i numeri sono solo illustrativi che, ad ogni modo, ti impegni a non divulgare a terzi.",

        "goatchart_label": "Servizi di trading",
        "goatchart_parag": "Goat Chart è il servizio di trading autonomo destinato agli Holders di Goat Nation, che possono seguire le operazioni del nostro trader esperto e profittevole!<br /><br />" +
            "Avrai accesso ad analisi di mercato quotidiane, ad una chat riservata con tutti i membri attivi che utilizzano il servizio di Goat Chart, " +
            "per scambiare pareri, opinioni e accrescere il tuo \"know-how\" sul mondo del trading di cryptovalute.",

        "goat_esim_label": "Connettività internazionale",
        "goat_esim_parag": "Le E-SIM di Goat Nation sono SIM virtuali, esclusive ed internazionali riservate agli holders del progetto.<br /><br />" +
            "Questo servizio ti permetterà di chiamare e navigare in maniera illimitata e criptata, ovunque tu sia nel mondo, grazie alla tua VPN personale. Potrai utilizzare la tua room personale per videoconferenze, rendendo le tue riunioni di lavoro o le chiacchierate con amici e parenti ancora più sicure e protette.<br /><br />\n" +
            "Inoltre, quando utilizzerai la tua E-SIM Goat, sul tuo telefono verrà segnato l'operatore \"GOAT\", mostrando così il tuo supporto per Goat Nation e la tua appartenenza alla community.<br />\n" +
            "Il servizio è disponibile in oltre 100 paesi nel mondo e rappresenta la scelta ideale per chi desidera una connessione sicura, privata e illimitata, ovunque si trovi."
    }
};

export default testi;
