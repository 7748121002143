import forceLogout from "../../../helpers/forceLogout";
import {getBackendURI} from "./getBackendURI";

import Cookies from 'js-cookie';
import {setSessionCookies} from "../../wallet_generic/setSessionCookies";

import {setGoatechLevelCookie} from "../../../components/area_riservata/goatech/helpers/setGoatechLevelCookie";

export const EndPointMethod = {
    REQUEST_NONCE_AUTH: '/user/generate-nonce-token',
    LOGIN: '/user/login',
    REFRESH_TOKEN: '/user/refresh-token',
    GOATECH_MINTPAGE: '/goatech/mint-page',
    GOATECH_STATS: '/goatech/stats',
    GOATECH_PAMM_HISTORY: '/goatech/pamm-history',
    GOATECH_ADMIN_LOAD_EXTERNAL_TXDATA: '/goatech-admin/getdata-tx-from-link',
    GOATECH_ADMIN_PREPARE_DISTRIB: '/goatech-admin/profit-prepare-distrib',
    GOATECH_ADMIN_APPROVE_DISTRIB: '/goatech-admin/approve-distrib',
    GOATECH_ADMIN_DELETE_DISTRIB: '/goatech-admin/delete-distrib',
    GOATECH_ADMIN_GETLIST_PROFITS: '/goatech-admin/getlist-distrib',
    GOATECH_ADMIN_GET_BALLA: '/goatech-admin/get-balla',
    GOATECH_ADMIN_GETLIST_BALLE: '/goatech-admin/getlist-balle',
    GOATECH_ADMIN_GETLIST_WALLET: '/goatech-admin/getlist-wallet',
    GOATECH_ADMIN_ADDRESS_AS_PROFIT_REACHED: '/goatech-admin/address-profit-reached',
    AFFILIATE_SET_ADDRESS: '/user/set-affiliate-ref',
    AFFILIATE_REFERRALS_PAGE: '/affiliate/affiliate-referrals-page',
    AFFILIATE_COMMISSIONS_PAGE: '/affiliate/affiliate-referrals-commissions',
    AFFILIATE_BONUS_PAGE: '/affiliate/affiliate-referrals-bonus',
    AFFILIATE_ADMIN_COMMISSIONS: '/affiliate-admin/commissions',
    AFFILIATE_ADMIN_PREPARE_PAY: '/affiliate-admin/prepare-pay',
    AFFILIATE_ADMIN_PAY: '/affiliate-admin/pay',
    AFFILIATE_ADMIN_BONUS: '/affiliate-admin/bonus',
    AFFILIATE_ADMIN_BONUS_PAY: '/affiliate-admin/pay-bonus',
    ACADEMY_ADMIN_MANAGE_CONTENTS: '/academy/admin-manage-contents',
    ACADEMY_ADMIN_LIST_CONTENTS: '/academy/admin-list-contents',
    ACADEMY_ADMIN_EDIT_CONTENT: '/academy/admin-edit-content',
    ACADEMY_ADMIN_DELETE_CONTENT: '/academy/admin-delete-content',
    ACADEMY_ADMIN_MANAGE_LESSONS: '/academy/admin-manage-lessons',
    ACADEMY_ADMIN_LIST_LESSONS: '/academy/admin-list-lessons',
    ACADEMY_ADMIN_DELETE_LESSON: '/academy/admin-delete-lesson',
    ACADEMY_LIST_CONTENTS: '/academy/list-contents',
    ACADEMY_VIEW_CONTENT: '/academy/view-content',
}

export class RequestBackend {

    constructor(address, method, body = {}) {
        this.state = {
            'address': address,
            'method': method,
            'body': body,
            'isResponseError': false,
            'ResponseCode': 0,
        }
        if (Cookies.get('RefreshToken')) {
            this.state.body.AuthorizationToken = Cookies.get('AuthorizationToken');
            this.state.body.RefreshToken = Cookies.get('RefreshToken');
        }
    }

    getParameters(bodyWithoutState = null) {

        // Se viene passato il body della richiesta senza utilizzare gli state (Utilizzato quando bisogna inviare un file)
        let body_request;
        if (bodyWithoutState != null && Cookies.get('RefreshToken')) {
            bodyWithoutState.AuthorizationToken = Cookies.get('AuthorizationToken');
            bodyWithoutState.RefreshToken = Cookies.get('RefreshToken');
            body_request = bodyWithoutState;
        } else {
            body_request = this.state.body;
        }

        const param = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            credentials: 'include',
            body: new URLSearchParams({
                AuthorizationWallet: this.state.address,
                ...body_request
            })
        };
        return param;
    }

    async run(bodyWithoutState = null) {
        const uri = getBackendURI() + '' + this.state.method;
        const param = this.getParameters(bodyWithoutState != null ? bodyWithoutState : null);

        // Verifico l'esito della risposta
        const response = await fetch(uri, param).catch((error) => {
            this.state.isResponseError = true;
            return error;
        });
        if (this.state.isResponseError) {
            return response;
        }
        this.state.ResponseCode = response.status;

        // 401 = Utente non loggato correttamente
        // 440 = Token dell'utente da aggiornare
        if (response.status == 401) { // Login non valido
            forceLogout();
            return;
        }
        if (response.status == 440) { // Token scaduto
            return await this.refreshTokenAndResend();
        }

        // Parso il json
        const data = await response.json().catch((error) => {
            this.state.isResponseError = true;
            return error;
        });
        if (data["error"]) {
            this.state.isResponseError = true;
        }
        return data;
    }

    getStatusCode() {
        return this.state.ResponseCode;
    }

    isResponseError() {
        return this.state.isResponseError;
    }

    // Il token temporaneo scade. Devo rinnovarlo usando il RefreshToken che dura 3 giorni
    async refreshTokenAndResend() {
        // Chiedo un nuovo token
        const refresh = await this.refreshToken();
        if (!refresh) {
            forceLogout();
            return;
        } else {
            // Aggiorno la sessione
            if (Cookies.get('RefreshToken')) {
                this.state.body.AuthorizationToken = Cookies.get('AuthorizationToken');
                this.state.body.RefreshToken = Cookies.get('RefreshToken');
            }
        }
        // Rifaccio la richiesta di API
        return await this.run();
    }

    async refreshToken() {
        const request = new RequestBackend(this.state.address, EndPointMethod.REFRESH_TOKEN);
        const response = await request.run();
        if (request.isResponseError() || request.getStatusCode() != 200) {
            return false;
        }
        if (response["status"] && response["status"] == "success") {
            setSessionCookies(response["data"]);
            setGoatechLevelCookie(response["level_goatech"]);
            return true;
        }
        return false;
    }
}