import Cookies from 'js-cookie';
import {isDev} from '../../../wallet_generic/helpers/isDev';

export function setGoatechLevelCookie(data) {

    const current_type = data["current_type"] ? data["current_type"] : "";
    const current_format = data["current_format"] ? data["current_format"] : "";
    const next_format = data["next_format"] ? data["next_format"] : "";

    const isSecureToken = isDev() == false ? true : false;

    // Login riuscito con successo, setto i cookie
    Cookies.set('GoatechLevelCurrent', current_type, {expires: 365, secure: isSecureToken});
    Cookies.set('GoatechLevelCurrentFormat', current_format, {expires: 365, secure: isSecureToken});
    Cookies.set('GoatechLevelNextFormat', next_format, {expires: 365, secure: isSecureToken});
}