
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faLinkedin, faYoutube, faFacebook } from '@fortawesome/free-brands-svg-icons'

import { testi } from "../../../language/lang.js";

import HomeGoatFaq from './GoatFaq.js';

function ThumnailTeam(param) {
    return (
        <div className='team-grid-element'>
            <p className='team-grid-avatar'><img src={`assets/img/HomeGoatTeam/New/${param.avatar}`} /></p>
            <p className='team-grid-nome'>{param.nome}</p>
            <p className='team-grid-ruolo'>{param.ruolo}</p>
            <p className='team-grid-social'>
                {param.twitter ? (<a className='team-grid-social-icon' target='_blank' href={`https://twitter.com/${param.twitter}`} ><FontAwesomeIcon icon={faTwitter} /></a>) : ""}
                {param.instagram ? (<a className='team-grid-social-icon' target='_blank' href={`https://www.instagram.com/${param.instagram}`} ><FontAwesomeIcon icon={faInstagram} /></a>) : ""}
                {param.linkedin  ? (<a className='team-grid-social-icon' target='_blank' href={`https://www.linkedin.com/in/${param.linkedin}`} ><FontAwesomeIcon icon={faLinkedin} /></a>) : ""}
                {param.youtube  ? (<a className='team-grid-social-icon' target='_blank' href={`https://www.youtube.com/${param.youtube}`} ><FontAwesomeIcon icon={faYoutube} /></a>) : ""}
                {param.facebook  ? (<a className='team-grid-social-icon' target='_blank' href={`https://www.facebook.com/${param.facebook}`} ><FontAwesomeIcon icon={faFacebook} /></a>) : ""}
            </p>
        </div>
    );
}

export class HomeSection extends Component {
    render() {
        return (

            <div style={{
                background: `url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatFaq/PANDA.png'})`,
            }} className='faq-background'>
                {/*
                <div id="team" className="container container-padding">
                    <h3 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: testi('goatteam_h3') }} className="h3-section2"></h3>
                    <p style={{ fontSize: 20, lineHeight: '28px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: testi('goatteam_parag') }} className="parag-section2"></p>

                    <div className='team-grid'>
                        <ThumnailTeam nome="ODT" avatar="team_odt2.png" ruolo="Founder" facebook="omardetommasopage" youtube="channel/UCXF-XhUuKwURo4aaXz8tBdQ" instagram="omardetommaso" linkedin="omardetommaso" />
                        <ThumnailTeam nome="Lorenzo22" avatar="team_lorenzo2.png" ruolo="Co-Founder & Project Manager NFT" instagram="lorenzocampo22" twitter="lorenzocampo22" linkedin="lorenzocampo" youtube="c/LorenzoCampo" />
                        <ThumnailTeam nome="Mister" avatar="team_mister2.png" ruolo="Co-Founder & COO Chief Operating Officer" instagram="misternetwork" twitter="riccardi_marco" linkedin="marco-riccardi-7416915a" />
                    </div>
                    <div className='team-grid team-grid2'>
                        <ThumnailTeam nome="Filippo" avatar="team_filippo.png" ruolo="Artist" twitter="GhisleriFilippo" instagram="filippo.ghisleri" />
                        <ThumnailTeam nome="Rachele" avatar="team_rachele.png?v=2" ruolo="COO Chief Operating Officer" youtube="channel/UCMNgPSn024cuIp0qA3uv6UQ" instagram="rachele_grimoldi" />
                        <ThumnailTeam nome="Benito" avatar="team_benito.png" ruolo="Utily Expert (CTO)" instagram="benito_pellegrino" />

                        <ThumnailTeam nome="Stefano" avatar="2023/stefano.png" ruolo="Sales Manager" twitter="" instagram="ste.matto" />
                        <ThumnailTeam nome="Emanuele" avatar="2023/emanuele.png" ruolo="Sales" twitter="" instagram="emanuele_robertazzi" />


                        <ThumnailTeam nome="Pex" avatar="team_pex.png" ruolo="Blockchain expert" instagram="valerio.pex" />
                        <ThumnailTeam nome="Musti" avatar="team_musti.png" ruolo="Marketing" instagram="musta.azza" />
                        <ThumnailTeam nome="Paul" avatar="team_paul.png" ruolo="UI Designer" twitter="paolocorazzini" instagram="paolocorazzini" />

                        <ThumnailTeam nome="Alex" avatar="2023/alex.png" ruolo="Real Estate Broker" twitter="" instagram="alex_somma" />
                        <ThumnailTeam nome="Raul" avatar="2023/raul.png" ruolo="Real Estate Expert" twitter="" instagram="raulalbertioficial" />
                        <ThumnailTeam nome="Paolo" avatar="2023/paolo.png" ruolo="Collab Manager" twitter="" instagram="montemurro.paolo" />
                        <ThumnailTeam nome="Sebastian" avatar="2023/sebastian.png" ruolo="Community Developer" twitter="sebagazzarrini" instagram="sebastiangazzarrini/" />
                        <ThumnailTeam nome="Andrea" avatar="2023/andrea.png" ruolo="Token Expert" twitter="" instagram="andrealabate.xyz" />
                        <ThumnailTeam nome="Sonia" avatar="2023/sonia.png" ruolo="Discord Mod" twitter="" instagram="" />
                        <ThumnailTeam nome="Antonio" avatar="2023/antonio.png" ruolo="Developer" twitter="" instagram="" />
                    </div>
                </div>
                */}
                <HomeGoatFaq />
            </div>
        );
    }
}

export default HomeSection;