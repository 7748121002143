import React, { Component } from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            ticks: {
                color: '#FFF',
                callback: function (value, index, ticks) {
                    return value + ' %';
                }
            }
        },
        x: {
            ticks: {
                color: '#FFF'
            }
        }
    }
};

let data = {
    labels: [],
    datasets: [
        {
            fill: true,
            label: 'Profitti',
            data: [],
            borderColor: 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            pointRadius: 0
        },
    ],
};

export class PAMMGrafico extends Component {
    chartReference = {};

    constructor(props) {
        super(props);
        this.state = {
            labels: [],
            datasets: []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.datagraph != this.props.datagraph) {
            this.carica();
        }
    }

    carica() {
        const dataGraph = this.props.datagraph;

        let arrayLabels = [];
        let arrayData = [];
        for (let label in dataGraph) {
            arrayLabels.push(dataGraph[label].label);
            arrayData.push(dataGraph[label].value);
        }
        this.setState({
            labels: arrayLabels,
            datasets: arrayData
        });
    }

    render() {
        if (this.state.datasets.length > 0) {
            data.labels = this.state.labels;
            data.datasets[0].data = this.state.datasets;
            return <Line options={options} data={data} />;
        }
        return null;
    }
}