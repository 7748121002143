import React, {Component} from 'react';

import {testi} from "../../../language/lang.js";

class GoatNFTLevelsTable extends Component {
    constructor(props) {
        super(props);

        this.DrawLineChecks = this.DrawLineChecks.bind(this);
    }

    getLevels() {
        return [
            {
                text: testi("goat_nft_level_1"),
                levels: {
                    1: true,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_2"),
                levels: {
                    1: true,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_3"),
                levels: {
                    1: true,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_4"),
                levels: {
                    1: true,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_5"),
                levels: {
                    1: true,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_6"),
                levels: {
                    1: true,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_7"),
                levels: {
                    1: true,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_8"),
                levels: {
                    1: false,
                    2: true,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_9"),
                levels: {
                    1: false,
                    2: false,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_10"),
                levels: {
                    1: false,
                    2: false,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_11"),
                levels: {
                    1: false,
                    2: false,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_12"),
                levels: {
                    1: false,
                    2: false,
                    5: true,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_13"),
                levels: {
                    1: false,
                    2: false,
                    5: false,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_14"),
                levels: {
                    1: false,
                    2: false,
                    5: false,
                    10: true,
                    20: true
                }
            },
            {
                text: testi("goat_nft_level_15"),
                levels: {
                    1: false,
                    2: false,
                    5: false,
                    10: false,
                    20: true
                }
            }
        ];
    }

    DrawChecked(props) {
        if (props.checked) {
            return (<p><img alt="checked" src={'assets/img/HomeNFTLevels/Vector.png'} /></p>);
        }
        return (<p>&nbsp;</p>);
    }

    DrawLineChecks(props) {
        return (
            <div className={'level-table-row'}>
                <div className={'level-column-checks'}>
                    <this.DrawChecked checked={props.level.levels[1]}/>
                </div>
                <div className={'level-column-checks'}>
                    <this.DrawChecked checked={props.level.levels[2]}/>
                </div>
                <div className={'level-column-checks'}>
                    <this.DrawChecked checked={props.level.levels[5]}/>
                </div>
                <div className={'level-column-checks'}>
                    <this.DrawChecked checked={props.level.levels[10]}/>
                </div>
                <div className={'level-column-checks'}>
                    <this.DrawChecked checked={props.level.levels[20]}/>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={'container'} id={'levels-table'} style={{paddingLeft: 0, paddingRight: 0}}>
                <div className={'levels-table'}>
                    <div className={'levels-column-labels'}>
                        <div className={'level-table-row'}>&nbsp;</div>
                        {this.getLevels().map((level, i) => {
                            return (
                                <div className={'level-table-row'} key={i}><p dangerouslySetInnerHTML={{__html: level.text}}></p></div>
                            );
                        })}
                    </div>
                    <div className={'levels-column-checks'}>
                        <div className={'level-table-row'}>
                            <div className={'level-column-checks'}>1&nbsp;<span>NFT</span></div>
                            <div className={'level-column-checks'}>2&nbsp;<span>NFT</span></div>
                            <div className={'level-column-checks'}>5&nbsp;<span>NFT</span></div>
                            <div className={'level-column-checks'}>10&nbsp;<span>NFT</span></div>
                            <div className={'level-column-checks'}>20&nbsp;<span>NFT</span></div>
                        </div>
                        {this.getLevels().map((level, i) => {
                            return (
                                <this.DrawLineChecks level={level} key={i}/>
                            );
                        })}
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>
            </div>
        );
    }
}

export default GoatNFTLevelsTable;