import React, { Component } from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import StatsTab from '../../../components/area_riservata/goatech/ui/StatsTab.js';
import { StatsBadge, StatsBadgeEnum } from '../../../components/area_riservata/goatech/ui/StatsBadge';
import StatsValue from '../../../components/area_riservata/goatech/ui/StatsValue.js';

import GoatechRequestBurn from '../../../components/area_riservata/goatech/ui/GoatechRequestBurn';
import GoatechBtnMobile from '../../../components/area_riservata/goatech/ui/BtnMobile';
import { Reti } from '../../../components/wallet_generic/Wallets.js';
import { EndPointMethod, RequestBackend } from '../../../components/area_riservata/backend/RequestBackend.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

export class GoatechStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'myPAMMBalance': '',
            'profitti_history': {},
            'fieno_history': {},
            'myWithdrawProfit': '',
            'myWithdrawPercentage': '',
            'lastMonthProfit': '',
            'lastMonthPercentage': '',

            'valueMissing': '',
            'recoveryTotalValue': '',
            'recoveryPercentProfit': ''
        };
        this.setAddress = this.setAddress.bind(this);
    }
    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.GOATECH_STATS);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const response_data = response.data;
            this.setState({
                'myPAMMBalance': response_data.myPAMMBalance,
                'balleAttive': response_data.balleAttive,
                'profitti_history': response_data.profitti,
                'fieno_history': response_data.balle,
                'myWithdrawProfit': response_data.myWithdrawProfit,
                'myWithdrawPercentage': response_data.myWithdrawPercentage,
                'lastMonthProfit': response_data.lastMonthProfit,
                'lastMonthPercentage': response_data.lastMonthPercentage,

                'recoveryTotalValue': response_data.recoveryTotalValue,
                'valueMissing': response_data.valueMissing,
                'recoveryPercentProfit': response_data.recoveryPercentProfit,
            });
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech' subMenu='stats' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>
                                    <GoatechBtnMobile />

                                    <h3>My GOATECH Stats</h3>

                                    <div className='goatech-stats-cols'>
                                        <div className='goatech-stats-col1'>
                                            <div className='goatech-stats-tabs'>
                                                {/* <StatsTab titolo="My Balance" valore={this.state.myPAMMBalance !== '' ? '$ ' + this.state.myPAMMBalance : ''} /> */ }
                                                {/* <StatsTab titolo="My Last Month Rewards" valore={this.state.lastMonthProfit !== '' ? '$ ' + this.state.lastMonthProfit : ''} percent={this.state.lastMonthPercentage} /> */}
                                                <StatsTab titolo="My Fieno quantity" valore={this.state.balleAttive !== '' ? this.state.balleAttive : ''} />
                                                <StatsTab titolo="My Withdraw Rewards" valore={this.state.myWithdrawProfit !== '' ? '$ ' + this.state.myWithdrawProfit : ''} percent={this.state.myWithdrawPercentage} />

                                                <StatsTab titolo="Total Balance Recovery" valore={this.state.recoveryTotalValue !== '' ? '$ ' + this.state.recoveryTotalValue : ''} />
                                                <StatsTab titolo="Percent Recovered" valore={this.state.valueMissing !== '' ? (this.state.recoveryPercentProfit + "%") + " (miss. $ "+ this.state.valueMissing +")" : ''} />
                                            </div>
                                        </div>
                                        <div className='goatech-stats-col1' style={{ position: 'relative' }}>
                                            <div
                                                style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goatech/FarmHouseBarn1.png'})` }}
                                                className='goatech-stats-farmhousebarn'>
                                            </div>
                                            <div
                                                style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goatech/gt212.png'})` }}
                                                className='goatech-stats-gt212'>
                                            </div>
                                        </div>
                                    </div>

                                    <h3>My Withdrawal History</h3>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Date</th>
                                                    <th>Rewards %</th>
                                                    <th>License</th>
                                                    <th>Month number</th>
                                                    <th>Quantity</th>
                                                    <th className='cell-left'>Transaction link</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.profitti_history ? Array.from(this.state.profitti_history).map((profitto, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="Date" className='cell-left'>{profitto.profitDate}</td>
                                                            <td data-label="Rewards %" className='cell-left'><StatsValue valore={profitto.percentage} /></td>
                                                            <td data-label="License" className='cell-left'>{profitto.level ? profitto.level.toLowerCase() : null}</td>
                                                            <td data-label="Month number" className='cell-left'>{profitto.monthNumber}</td>
                                                            <td data-label="Quantity" className='cell-left'>{profitto.profit} USDC</td>
                                                            <td data-label="Transaction link">{profitto.txHash != "" ? <a target={'_blank'} className='link_bsc' href={`https://bscscan.com/tx/${profitto.txHash}`}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a> : null}</td>
                                                        </tr>
                                                    )
                                                }, this) : (
                                                    <tr>
                                                        <td colSpan="5" className='cell-left-important'>Nothing yet to show</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>My Goatech Wallet</h3>
                                    <p style={{marginTop: 0}}><span style={{color: '#E2FC43', fontWeight: 600, borderBottom: '1px solid'}}>ATTENTION</span>: If you request a burn within the first 90 days of purchase it will cost you 10%. After 90 days it will be possible to burn for free.</p>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Fieno #</th>
                                                    <th className='cell-left'>Fieno Total Rewards</th>
                                                    <th className='cell-left'>Recovery Missing</th>
                                                    <th className='cell-left'>Buy date</th>
                                                    <th className='cell-left'>Status</th>
                                                    <th className='cell-left'>Request burn date</th>
                                                    <th className='cell-left'>Burn date</th>
                                                    <th className='cell-left'>Burn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.fieno_history ? Array.from(this.state.fieno_history).map((balla, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="Fieno #" className='cell-left'>{balla.id}</td>
                                                            <td data-label="Fieno Total Rewards" className='cell-left'><StatsValue valore={balla.percentage} />{balla.totalValue ? " ($ "+ balla.totalValue +")" : ""}</td>
                                                            <td data-label="Recovery Missing" className='cell-left'>$ {balla.valueMissing}</td>
                                                            <td data-label="Buy date" className='cell-left'>{balla.buyDate}</td>
                                                            <td data-label="Status" className='cell-left'><StatsBadge id={'statsbadge' + i} badge={StatsBadgeEnum[balla.state]} /></td>
                                                            <td data-label="Request burn date" className='cell-left'>{balla.reqBurnDate ? balla.reqBurnDate : ''}</td>
                                                            <td data-label="Burn date" className='cell-left'>{balla.dataBurned ? balla.dataBurned : ''}</td>
                                                            <td data-label="Burn" className='cell-left'>
                                                                <GoatechRequestBurn tokenId={balla.id} percentagePenalBurn={balla.percentagePenalBurn} keyId={i} disabled={this.state.blockRequestBurn || balla.state == StatsBadgeEnum.BURNED || balla.state == StatsBadgeEnum.REQ_BURN ? true : false} />
                                                            </td>
                                                        </tr>
                                                    )
                                                }, this) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatechStats;