import Cookies from "js-cookie";

export default function forceLogout() {
    if (localStorage.getItem("wallet_connected")) {
        localStorage.removeItem("wallet_connected");
    }
    document.cookie = 'AuthorizationGoatech=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'AuthorizationToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'RefreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    
    if (Cookies.get("AuthorizationGoatechIsAdmin")) {
        document.cookie = 'AuthorizationGoatechIsAdmin=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    if(Cookies.get("GoatechLevelCurrent")) {
        document.cookie = 'GoatechLevelCurrent=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'GoatechLevelCurrentFormat=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'GoatechLevelNextFormat=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    
    window.location.href = "/";
}