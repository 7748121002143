import React, { Component } from 'react';
import { getWeb3Provider } from '../../../binance/helpers/getWeb3Provider';
import { getAddressContract } from '../../../binance/getContractInfo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { showError } from '../../../ui/ToastErrore';
import { getUSDContract } from '../../../binance/usdc/getUSDContract';
import { ethers } from 'ethers';


export class GoatechProcessBurn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'tokenId': this.props.tokenId ? this.props.tokenId : null,
            'burnValue': this.props.burnValue ? this.props.burnValue : null,
            'percentagePenalBurn': this.props.percentagePenalBurn ? this.props.percentagePenalBurn : 0,
            'disabled': this.props.disabled ? true : false,
            'loadingBurn': false
        };
        this.processBurnBtn = this.processBurnBtn.bind(this);
    }

    processBurnBtn() {
        if (!this.state.disabled && this.state.tokenId && this.state.burnValue) {
            this.processBurn();
        }
    }

    async processBurn() {
        const provider = await getWeb3Provider(true);
        const contract = provider.contract;

        const burnValue = this.state.burnValue;
        const actualHexAmountInWei = ethers.utils.parseUnits(String(burnValue))._hex;

        // Richiesta di burn al contratto
        this.setState({ loadingBurn: true });

        try {
            
            // Approve da USDC
            const USDC = await getUSDContract(provider.provider);
            const requestUSD = await USDC.approve(getAddressContract(), actualHexAmountInWei);
            showError({ 'titolo': 'Process burn in progress', 'testo': 'Waiting for the approval of tokens...' });
            await requestUSD.wait();
            
            const request = await contract.processBurn(this.state.tokenId, actualHexAmountInWei);

            showError({ 'titolo': 'Process burn in progress', 'testo': 'Awaiting confirmation of the transaction...' });

            await request.wait().then((res) => {
                showError({ 'titolo': 'Success!', 'testo': 'The burn was burned! The bale will be updated within a few minutes' });
                this.setState({ disabled: true });

                return true;
            }, error => {
                showError({ 'titolo': 'Error :(', 'testo': 'An error occurred in the request ' + (error.message ? ": " + error.message : '') });
                return false;
            }).catch((error) => {
                showError({ 'titolo': 'Error :(', 'testo': 'An error occurred in the request ' + (error.message ? ": " + error.message : '') });
                return false;
            });
        } catch (error) {
            showError({ 'titolo': 'Error :(', 'testo': 'An error occurred in the request ' + (error.message ? ": " + error.message : '') });
        }

        this.setState({ loadingBurn: false });
    }

    render() {
        const percentPenalBurn = this.state.percentagePenalBurn;
        return (<span onClick={() => this.processBurnBtn()} className={'burn-btn ' + (this.state.disabled || this.state.loadingBurn ? 'disabled' : '')}>
            {percentPenalBurn === 0 ? "Free" : percentPenalBurn + "%"} Burn {this.state.loadingBurn ? <>&nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} spin /></> : ''}
        </span>);
    }
}

export default GoatechProcessBurn;