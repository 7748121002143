import React, { Component } from 'react';

export const StatsBadgeEnum = {
    ACTIVE: 'ACTIVE',
    PENDING_DEPOSIT: 'PENDING_DEPOSIT',
    REQ_BURN: 'REQ_BURN',
    DEACTIVATED: 'DEACTIVATED',
    BURNED: 'BURNED',
    PROFIT_REACHED: 'PROFIT_REACHED'
};

export const StatsBadgeText = {
    ACTIVE: 'Active',
    PENDING_DEPOSIT: 'Pending dep',
    REQ_BURN: 'Req. Burn',
    DEACTIVATED: 'Deactivated',
    BURNED: 'Burned',
    PROFIT_REACHED: 'Profit Reached'
};

export const StatsBadgeClass = {
    ACTIVE: 'badge-active',
    PENDING_DEPOSIT: 'badge-pending',
    REQ_BURN: 'badge-request',
    DEACTIVATED: 'badge-deactivated',
    BURNED: 'badge-burned',
    PROFIT_REACHED: 'badge-profit-reached'
};

export class StatsBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'badge': props.badge
        }
    }
    render() {
        return (<span id={this.props.id} className={'stats-badge ' + StatsBadgeClass[this.state.badge]} >{StatsBadgeText[this.state.badge]}</span>);
    }
}

export default StatsBadge;