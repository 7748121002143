import React, {Component} from 'react';
import {Stage, Layer, Line, Image, Circle, Text, Rect} from 'react-konva';
import useImage from 'use-image';
import testi from "../../../language/lang";
import {Waypoint} from 'react-waypoint';
import Konva from "konva";

const HEIGHT_CANVAS = 610;
const MAX_HEIGHT_CANVAS = 900;
const WIDTH_CANVAS = 1400;
const COLOR_POINT_ACTIVE = "#E2FC43";
const COLOR_POINT_INACTIVE = "white";

class GoatRoadMapInteractive extends Component {
    constructor(props) {
        super(props);

        const newHeight = (window.innerWidth) * HEIGHT_CANVAS / WIDTH_CANVAS;
        this.state = {
            scale: Math.min(
                (window.innerWidth) / WIDTH_CANVAS,
                window.innerHeight / HEIGHT_CANVAS
            ),
            height: newHeight,
            enabledScrollEffect: window.innerWidth < 1080 ? false : true
        }

        this.circles = [];
        this.decorations = [];

        this.handleResize = this.handleResize.bind(this);
        this.LoadPointCircle = this.LoadPointCircle.bind(this);
        this.LoadIconDecoration = this.LoadIconDecoration.bind(this);
        this.Background = this.Background.bind(this);
        this.removeDecorations = this.removeDecorations.bind(this);
        this.setPoints();
    }

    getPoints() {
        const points = [
            {
                coord: [-220, 300],
                active: true
            },
            {
                coord: [200, -100],
                active: true,
                title: {
                    text: 'Goatech 3.0',
                    x: 130,
                    y: 285
                },
                icon: {
                    url: 'assets/img/GoatRoadMap/2023/goatech3.svg',
                    x: 190,
                    y: 321.5
                },
                circle: {
                    x: 200,
                    y: 330
                }
            },
            {
                coord: [320, 30],
                active: true,
                title: {
                    text: 'E-Sim',
                    x: 230,
                    y: 446
                },
                icon: {
                    url: 'assets/img/GoatRoadMap/2023/esim.svg',
                    x: 314.5,
                    y: 447.5
                },
                circle: {
                    x: 320,
                    y: 455
                }
            },
            {
                coord: [400, -50],
                active: true,
                title: {
                    text: 'Goat Chart',
                    x: 340,
                    y: 335
                },
                icon: {
                    url: 'assets/img/GoatRoadMap/2023/goat-chart.png',
                    x: 391.5,
                    y: 371.5
                },
                circle: {
                    x: 400,
                    y: 380
                }
            },
            {
                coord: [450, -13],
                active: true
            },
            {
                coord: [520, -80],
                active: true,
                title: {
                    text: '    Goat\nAcademy',
                    x: 470,
                    y: 285
                },
                icon: {
                    url: 'assets/img/GoatRoadMap/2023/icon_academy.png',
                    x: 511,
                    y: 342
                },
                circle: {
                    x: 520,
                    y: 350
                }
            },
            {
                coord: [610, -20],
                active: false
            },
            {
                coord: [697, -230],
                active: false,
                title: {
                    text: 'Token',
                    x: 665,
                    y: 158
                },
                circle: {
                    x: 698,
                    y: 200
                }
            },
            {
                coord: [820, -180],
                active: false
            },
            {
                coord: [980, 0],
                active: false,
                title: {
                    text: 'Baby Goat',
                    x: 930,
                    y: 340
                },
                icon: {
                    url: 'assets/img/GoatRoadMap/2023/babygoat.svg',
                    x: 972,
                    y: 419
                },
                circle: {
                    x: 980,
                    y: 425
                }
            },
            {
                coord: [1100, -120],
                active: false,
                title: {
                    text: 'Evento in Italia',
                    x: 1020,
                    y: 265
                },
                icon: {
                    url: 'assets/img/GoatRoadMap/2023/italia.svg',
                    x: 1092,
                    y: 302
                },
                circle: {
                    x: 1101,
                    y: 310
                }
            },
            {
                coord: [1180, -20],
                active: false
            },
            {
                coord: [1280, -70],
                active: false,
                title: {
                    text: 'Alien Goat',
                    x: 1225,
                    y: 315
                },
                circle: {
                    x: 1280,
                    y: 360
                }
            },
            {
                coord: [1340, 0],
                active: false
            },
            {
                coord: [1700, -200],
                active: false
            },
        ];
        return points;
    }

    getPointsDecoration() {
        const decorations = [
            {
                point_ref: '820,-180',
                effect: 'fadeIn',
                x: 740,
                y: 198,
                rotation: -9,
                image: 'assets/img/GoatRoadMap/2023/regalo.svg'
            },
            {
                point_ref: '820,-180',
                effect: 'fadeIn',
                x: 780,
                y: 153,
                rotation: -10,
                image: 'assets/img/GoatRoadMap/2023/bandiera.svg'
            },
            {
                point_ref: '1280,-70',
                x: 1280,
                y: 250,
                image: 'assets/img/GoatRoadMap/2023/navicella.svg'
            },
            /*
            {
                point_ref: 'no_ref_point',
                x: 968,
                y: 390,
                image: 'assets/img/GoatRoadMap/2023/surprise.svg'
            }
            */
        ];
        return decorations;
    }

    setPoints() {
        const points = this.getPoints();

        let last_point_active;
        const coords_point_active = points.filter(function (item) {
            if (item.active) last_point_active = item;
            return item.active;
        });
        let points_active = coords_point_active.reduce((array, point) => {
            array.push(point.coord[0], point.coord[1]);
            return array;
        }, []);
        // duplico il primo punto per prevenire il bug dell'animazione
        points_active = [points_active[0], points_active[1], ...points_active];
        this.active_points = points_active;

        // Nei punti inattivi includo anche l'ultimo punto attivo
        const coords_points_white = points.filter(function (item) {
            return !item.active || last_point_active == item;
        });
        let points_white = coords_points_white.reduce((array, point) => {
            array.push(point.coord[0], point.coord[1]);
            return array;
        }, []);
        // duplico il primo punto per prevenire il bug dell'animazione
        points_white = [points_white[0], points_white[1], ...points_white];
        this.inactive_points = points_white;
    }

    componentDidMount() {
        if (this.state.enabledScrollEffect) {
            window.addEventListener('resize', this.handleResize);
        } else {
            this.playEffectActive(true)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const newHeight = (window.innerWidth) * HEIGHT_CANVAS / WIDTH_CANVAS;
        this.setState({
            scale: Math.min(
                (window.innerWidth) / WIDTH_CANVAS,
                window.innerHeight / HEIGHT_CANVAS
            ),
            height: newHeight
        });
        this.removeDecorations();
        this.playEffectActive(true);
        if (window.innerWidth < 1080) {
            this.setState({enabledScrollEffect: false});
        }
    }

    addPointEffect(animation_id, node, next_index, points) {
        // Se viene interrotta l'animazione scrollando la pagina
        if (this.animation_id != animation_id) {
            return false;
        }
        const _this = this;
        const newPoints = points.slice(0, next_index);
        node.to({
            duration: next_index == 4 ? 0.1 : 0.5,
            // duration: 0,
            points: newPoints,
            easing: Konva.Easings.BackEaseInOut,
            onFinish: () => {

                // Coloro anche i punti nella mappa
                if (node === _this.line_active) {
                    if (_this.circles[newPoints.slice(-2)]) {
                        _this.circles[newPoints.slice(-2)].to({
                            duration: 0.5,
                            fill: COLOR_POINT_ACTIVE
                        });
                    }
                }

                // Se devo mostrare una decorazione per il punto appena creato
                if (_this.decorations[newPoints.slice(-2)]) {
                    for (let decoration of _this.decorations[newPoints.slice(-2)]) {
                        if (decoration) {
                            decoration.to({
                                visible: true,
                                y: decoration.attrs.y + 20,
                                duration: 1.5,
                                easing: Konva.Easings.EaseIn
                            });
                        }
                    }
                }

                // Passo al segmento successivo
                next_index += 2;
                if (points.length >= next_index) {
                    _this.addPointEffect(animation_id, node, next_index, points);
                } else {
                    // Se sono finiti gli attivi, passo agli inattivi
                    if (node === _this.line_active) {
                        this.addPointEffect(animation_id, _this.line_inactive, 4, _this.inactive_points);
                    }
                }
            }
        });
    }

    playEffectActive(active) {
        const node_active = this.line_active;
        const node_inactive = this.line_inactive;
        if (active) {
            this.animation_id = Math.floor((Math.random() * 10000) + 1);
            this.addPointEffect(this.animation_id, node_active, 4, this.active_points);
        } else {
            this.animation_id = 0;
            node_active.to({points: []});
            node_inactive.to({points: []});
            this.removeDecorations();
        }
    }

    removeDecorations() {
        // Nascondo le decorazioni
        for (let points_decoration in this.decorations) {
            if (points_decoration !== "no_ref_point") {
                for (let decoration of this.decorations[points_decoration]) {
                    if (decoration && decoration.attrs.visible) {
                        decoration.to({
                            y: decoration.attrs.originalY,
                            visible: false,
                        });
                    }
                }
            }
        }
    }

    LoadPointCircle(props) {
        const [image] = props.icon ? useImage(props.icon.url) : new Array();
        return (
            <>
                <Circle
                    x={props.circle.x}
                    y={props.circle.y} radius={15}
                    fill={COLOR_POINT_INACTIVE}
                    ref={(node) => {
                        this.circles[props.coord] = node;
                    }}
                />
                {props.icon ? <Image x={props.icon.x} y={props.icon.y} image={image}/> : null}
                {props.title ?
                    <Text
                        x={props.title.x}
                        y={props.title.y}
                        fontFamily={'Fahkwang'}
                        fontSize={'20'}
                        fontVariant={'600'}
                        text={props.title.text}
                        fill={props.active ? COLOR_POINT_ACTIVE : COLOR_POINT_INACTIVE}
                    />
                    : null
                }
            </>
        );
    }

    LoadIconDecoration(props) {
        const [image] = useImage(props.image);
        if (!this.decorations[props.point_ref]) {
            this.decorations[props.point_ref] = [];
        }
        return (
            <Image
                x={props.x}
                y={props.y - 20}
                originalY={props.y - 20}
                visible={props.show}
                rotation={props.rotation ? props.rotation : 0}
                ref={(node) => {
                    this.decorations[props.point_ref].push(node);
                }}
                image={image}/>
        );
    }

    ImagesBottom() {
        const [image_bottom1] = useImage("assets/img/GoatRoadMap/2023/GOAT1.svg");
        const [image_bottom2] = useImage("assets/img/GoatRoadMap/2023/GOAT3.svg");
        const [image_bottom3] = useImage("assets/img/GoatRoadMap/2023/GOAT2.svg");

        const [image_bottom4] = useImage("assets/img/TheGoatMap/GOAT6.png");
        const [image_bottom5] = useImage("assets/img/TheGoatMap/GOAT7.png");
        const [image_bottom6] = useImage("assets/img/TheGoatMap/GOAT2.png");
        const [image_bottom7] = useImage("assets/img/TheGoatMap/GOAT3.png");
        return (
            <>
                <Image x={250} y={430} image={image_bottom5}/>
                <Image x={305} y={390} image={image_bottom7}/>
                <Image x={940} y={430} image={image_bottom4}/>
                <Image x={845} y={390} image={image_bottom6}/>

                <Image x={390} y={320} image={image_bottom3}/>
                <Image x={690} y={306} image={image_bottom2}/>
                <Image x={520} y={270} image={image_bottom1}/>
            </>
        );
    }

    Background() {
        const [background] = useImage("assets/img/GoatRoadMap/2023/background2.png");
        return (
            <>
                {this.state.enabledScrollEffect ?
                    <Rect
                        y={0}
                        fillPatternImage={background}
                        width={window.innerWidth - 17}
                        height={this.state.height}
                    />
                    : null}
                <Rect
                    y={0}
                    fillLinearGradientStartPoint={{x: 0, y: 0}}
                    fillLinearGradientEndPoint={{x: 0, y: 500}}
                    fillLinearGradientColorStops={[0, 'transparent', 0.5, 'rgb(179 179 179 / 15%)', 1, 'transparent']}
                    width={4400}
                    height={4400}
                />
            </>
        )
    }

    render() {
        return (
            <>
                <div id="roadmap" onResize={() => this.handleResize()}>
                    {this.state.enabledScrollEffect ?
                        <Waypoint bottomOffset={this.state.height + 500}
                                  topOffset={500}
                                  onLeave={() => {
                                      this.playEffectActive(false)
                                  }}
                                  onEnter={() => this.playEffectActive(true)}>
                        </Waypoint>
                        : null}

                    <Stage width={window.innerWidth - 17} height={this.state.height}
                           style={{margin: '0px auto', background: 'black'}}
                           scaleY={this.state.scale}
                           scaleX={this.state.scale}
                    >
                        <Layer>
                            <this.Background/>
                            <Text
                                y={60}
                                x={550}
                                fontFamily={'Fahkwang'}
                                fontSize={'32'}
                                fontVariant={'600'}
                                fill={'white'}
                                text={'2023 GOATMAP'}
                            />
                            <Text
                                y={100}
                                x={648}
                                fontFamily={'Fahkwang'}
                                fontSize={'16'}
                                fontVariant={'400'}
                                fill={'white'}
                                text={'Da 0 a Goat'}
                            />
                            <Line
                                x={0}
                                y={430}
                                points={[this.active_points[0], this.active_points[1]]}
                                ref={(node) => {
                                    this.line_active = node;
                                }}
                                strokeWidth={2.5}
                                stroke={COLOR_POINT_ACTIVE}
                                lineJoin={'round'}
                            />
                            <Line
                                x={0}
                                y={430}
                                points={[this.inactive_points[0], this.inactive_points[1]]}
                                ref={(node) => {
                                    this.line_inactive = node;
                                }}
                                strokeWidth={2.5}
                                stroke={COLOR_POINT_INACTIVE}
                                lineJoin={'round'}
                            />
                            {this.getPoints().map((point, i) => {
                                if (point.circle) {
                                    return (<this.LoadPointCircle key={i} {...point} />)
                                }
                            })}
                            {this.getPointsDecoration().map((decoration, i) => {
                                return (
                                    <this.LoadIconDecoration show={decoration.point_ref == "no_ref_point"}
                                                             key={i} {...decoration} />
                                )
                            })}
                            <this.ImagesBottom/>
                        </Layer>
                    </Stage>
                </div>
            </>
        );
    }
}

export default GoatRoadMapInteractive;
