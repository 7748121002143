import React, { Component } from 'react';

export class AcademyBtnMobile extends Component {

    render() {
        return (<>
            <div className='goatech-mobile-btns'>
                <a href="/academy-contents?course">Courses</a>
                <a href="/academy-contents?group_live">Live Recorded</a>
            </div>
            <br />
        </>);
    }
}

export default AcademyBtnMobile;