
import React, { Component } from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { testi } from "../../../language/lang.js";

function People(props) {
    const key = props.people;
    return (<div className='carousel-goatmind-el'>
        <h5>{testi('goatmind_' + key + '_titolo')}</h5>
        <div dangerouslySetInnerHTML={{ __html: testi('goatmind_' + key + '_descr') }} className='carousel-goatmind-descr'></div>
        <br />
    </div>);
}

const settings_images = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    arrows: false,
    fade: true,
    speed: 200,
    cssEase: 'linear',
    swipe: false,
    accessibility: false,
    touchMove: false,
    swipeToSlide: false
};

let slider_images;

const settings_people = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    arrows: false,
    adaptiveHeight: true,
    beforeChange: function (current, next) {
        slider_images.slickGoTo(next);
    },
    onInit: function () {
        document.getElementsByClassName("carousel-goatmind-img")[0].style.display = "block";
        document.getElementsByClassName("carousel-goatmind")[0].style.display = "block";
    }
};

const settings_partners = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 5,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
};

export class HomeSection extends Component {
    render() {
        const people = [1, 2, 3, 4];
        const partners = ["BlockchainRoma.png", "Hybrid.png", "Mars.png", "Metaforum.png", "noexcuses.png", "blockchainarmy.png"];
        return (
            <div className='container-fluid'>
                <div className='section2-background homesection-goatmind'>
                    <h3 style={{textAlign: 'center'}} className="h3-section2" dangerouslySetInnerHTML={{__html: testi("partner_title")}}></h3>
                    <div className="container">
                        <div className='section2-left'>
                            <div className='carousel-goatmind-img'>
                                <Slider ref={slider => (slider_images = slider)} {...settings_images}>
                                    {people.map((key, i) => {
                                        return (
                                            <div key={i} className='carousel-goatmind-img-el' id={"peopleimg_" + i}>
                                                <img src={"assets/img/HomeGoatMind/people/" + testi('goatmind_' + key + '_img')} />
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                        <div className='section2-right'>
                            <div className='carousel-goatmind'>
                                <Slider {...settings_people}>
                                    {people.map((key, i) => {
                                        return (<People people={key} key={key} />)
                                    })}
                                </Slider>
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                        <div className='homesection-goatmind-partners'>
                            <h3>Goat Partners</h3>
                            <Slider {...settings_partners}>
                                {partners.map((key, i) => {
                                    return (<div>
                                        <img key={key} src={"assets/img/HomeGoatMind/partners/" + partners[i]} />
                                    </div>);
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;