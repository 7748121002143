import React, {Component} from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import GoatechBtnMobile from '../../../components/area_riservata/goatech/ui/BtnMobile';
import GoatechSingleLicense from '../../../components/area_riservata/goatech/ui/SingleLicense';
import {Reti} from '../../../components/wallet_generic/Wallets.js';
import {Licenses} from "../../../components/area_riservata/goatech/ui/Licenses";
import {getGoatechLevelCookie} from "../../../components/area_riservata/goatech/helpers/getGoatechLevelCookie";

export class GoatechInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',

            'goatechCurrentLevel': '',
            'goatechNextLevel': ''
        };
        this.setAddress = this.setAddress.bind(this);

        const goatechCurrentLevel = getGoatechLevelCookie();
        this.state.goatechCurrentLevel = goatechCurrentLevel.current_level;
        this.state.goatechCurrentLevelFormat = goatechCurrentLevel.current_level_format;
        this.state.goatechNextLevel = goatechCurrentLevel.current_next_level_format;
    }

    async setAddress(address) {
        this.setState({'menu_address': address});
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress}
                            checkLogged="true"/>

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech' subMenu='info' address={this.state.menu_address}/>
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>
                                    <GoatechBtnMobile/>

                                    <h3>Information</h3>

                                    <h4>My Goatech License</h4>

                                    <div className={'goatech-licenseinfo'}>
                                        <div style={{

                                        }} className={'box-goatech-single-license'}>
                                            {this.state.goatechCurrentLevel != "" ? <GoatechSingleLicense license={this.state.goatechCurrentLevel}/> : <div><br /><br /></div>}
                                        </div>
                                        <p className={'goatech-current-lvl'}>
                                            Your current Goatech license level is:<br/>
                                            <b>{this.state.goatechCurrentLevel != "" ? this.state.goatechCurrentLevelFormat : "None"}</b>
                                            <br/>
                                            {this.state.goatechNextLevel != this.state.goatechCurrentLevelFormat ? (
                                                <span>Next Month: <b>{this.state.goatechNextLevel != "" ? this.state.goatechNextLevel : "None"}</b></span>
                                            ) : null}
                                        </p>
                                    </div>
                                    <div style={{clear: 'both'}}></div>


                                    <div className={'box-dark'}>
                                        <div className={'grid-two-column'}>
                                            <div className={'column'}>
                                                <h4>Budget Allocation</h4>
                                                <p style={{marginRight: 15}} className={'parag-box-dark'}>
                                                    The entire budget of the Goatech service is allocated in a precise manner in different sectors to diversify and make Goatech and its results safer and more durable.<br /><br />
                                                    It is divided into percentages as can be seen on the graph below.
                                                </p>
                                            </div>
                                            <div className={'column'}>
                                                <img
                                                    style={{
                                                        width: '100%',
                                                        maxWidth: 400,
                                                        margin: '0px auto',
                                                        display: 'block'
                                                    }}
                                                    src={'assets/img/HomeGoatech/goatech-info2.png'}/>
                                            </div>
                                        </div>
                                    </div>

                                    <h4>Goatech level differences</h4>
                                    <div className={'grid-three-column nopadding column-card-licenses'}>
                                        <div className={'column'}>
                                            <GoatechSingleLicense license={Licenses.BRONZE}/>
                                        </div>
                                        <div className={'column'}>
                                            <GoatechSingleLicense license={Licenses.SILVER}/>
                                        </div>
                                        <div className={'column'}>
                                            <GoatechSingleLicense license={Licenses.GOLD}/>
                                        </div>
                                    </div>

                                    <div className={'box-dark'}>
                                        <div className={'grid-two-column'}>
                                            <div className={'column'}>
                                                <h4>How deposits and withdrawals work</h4>
                                                <p style={{marginRight: 15}} className={'parag-box-dark'}>
                                                    To <span className={'span-orange'}>Deposit</span> and access
                                                    Goatech's service, just go to the "<b>Buy Fieno</b>" section and purchase the hay bales.
                                                </p>
                                                <p style={{marginRight: 15}} className={'parag-box-dark'}>
                                                    <br/><span className={'span-orange'}>Withdrawals</span> are monthly
                                                    and are automatic directly into your wallet, so you won't have to
                                                    worry about a thing.<br /><br />
                                                </p>
                                            </div>
                                            <div className={'column'}>
                                                <img
                                                    style={{
                                                        width: '100%',
                                                        margin: '0px auto',
                                                        display: 'block'
                                                    }}
                                                    src={'assets/img/HomeGoatech/goatech-info1.png'}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'box-dark'}>
                                        <h4>How does it work</h4>
                                        <p className={'parag-box-dark'}>
                                            Deposits made by the end of the month will start producing rewards from the
                                            following month<br/><br/>
                                        </p>
                                        <img
                                            style={{
                                                width: '100%',
                                                maxWidth: 800
                                            }}
                                            src={'assets/img/HomeGoatech/goatech-info3.png'}/>
                                        <p className={'parag-box-dark'}>
                                            <i>Example:</i><br/>
                                            Deposits made between January 5th and February 3rd will be loaded on 5th February. With regard to deposits loaded by February 3rd, the rewards will be paid on March 05th.
                                        </p>
                                        <p className={'parag-box-dark span-orange'}>
                                            <br/>
                                            N.B. Deposits are not tied. However, if you withdraw (burn) your deposits
                                            before the 90 days, you will be charged a 10% penalty. Example You request
                                            1,000 usdc - 900 usdc will be sent to you. Burns made in the current month
                                            will be processed on the first of the following month
                                        </p>
                                        <br /><br />
                                        <h4>How burn works</h4>
                                        <img
                                            style={{
                                                width: '100%',
                                                maxWidth: 800
                                            }}
                                            src={'assets/img/HomeGoatech/goatech-info4.png'}/>
                                        <p className={'parag-box-dark'}>
                                            <i>Example:</i><br/>
                                            Burn requested between February 5th and March 3rd will be paid on 6th March.
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div
                            style={{background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain`}}
                            className='account-logo-goat'
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatechInfo;