
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { isAdminLogged } from '../backend/helpers/isAdminLogged';
import {getGoatechLevelCookie} from "../goatech/helpers/getGoatechLevelCookie";
import {BadgeLevel} from "../goatech/ui/BadgeLevel";

export class MenuArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'address': '',
            'showAddress': false,
            'currentPage': '',
            'subMenuActive': '',
            'currentSubNavActive': '',
            'subMenuHidden': true,

            'goatechCurrentLevel': '',
            'goatechNextLevel': ''
        };

        this.state.address = props.address;
        this.state.currentPage = props.currentPage;
        this.state.currentSubNavActive = props.currentPage;
        if (props.subMenu) {
            this.state.subMenuActive = props.subMenu;
            this.state.subMenuHidden = false;
        }
        this.toggleShowAddress = this.toggleShowAddress.bind(this);

        // Livello corrente di Goatech
        const goatechCurrentLevel = getGoatechLevelCookie();
        this.state.goatechCurrentLevel = goatechCurrentLevel.current_level;
        this.state.goatechNextLevel = goatechCurrentLevel.current_next_level_format;
        this.ShowGoatechBadge = this.ShowGoatechBadge.bind(this);
    }

    toggleShowAddress() {
        this.setState({
            'showAddress': !this.state.showAddress
        });
    }

    toggleAndActiveSubmenu(page) {
        if (!this.state.subMenuHidden && this.state.currentSubNavActive != page) {

        } else {
            this.setState({
                'subMenuHidden': !this.state.subMenuHidden,
            });
        }
        this.setState({
            'currentSubNavActive': page
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.address != this.props.address) {
            this.setState({ address: this.props.address });
        }
        if (prevProps.subMenu != this.props.subMenu) {
            if (this.props.subMenu) {
                this.setState({
                    subMenuActive: this.props.subMenu,
                    subMenuHidden: false
                });
            }
        }
    }

    ShowGoatechBadge() {
        return (
            <div className={'badge-menu'}>
                <span className={'badge-menu-title'}>Goatech License:</span>
                {this.state.goatechCurrentLevel != '' ? <BadgeLevel license={this.state.goatechCurrentLevel} /> : <div style={{height: 37}}/>}
                {this.state.goatechNextLevel != '' ? (<span className='badge-menu-nextlevel'>Next Month: <b>{this.state.goatechNextLevel}</b></span>) : null}
            </div>
        )
    }

    render() {
        return (
            <>
                <div className='account-padding'>
                    <img className='goat-logo-white' src="assets/img/goat_nation_WHITE.png" />

                    {this.state.goatechCurrentLevel || this.state.goatechNextLevel ? (
                        this.ShowGoatechBadge()
                    ) : null}

                    <div style={{clear: 'both'}}></div>
                    <h2>Welcome to your<br />Reserved GOAT Area</h2>

                    <ul className='account-nav scroller-stylized'>
                        <li className={this.state.currentPage == 'home' ? 'nav-active' : ''} ><a href="/account"><FontAwesomeIcon icon={faCircle} /> Home</a></li>
                        <li className={this.state.currentPage == 'goatech' ? 'nav-active' : ''}>
                            <a onClick={() => this.toggleAndActiveSubmenu('goatech')} ><FontAwesomeIcon icon={faCircle} /> GOATECH</a>

                            <ul className={(!this.state.subMenuHidden && (this.state.currentSubNavActive == 'goatech') ? 'subnav-active ' : '') + 'subnav-ul subnav-ul-3n'}>
                                <li><a className={this.state.subMenuActive == 'mint-fieno' ? 'subnav-active' : ''} href="/goatech-mint-fieno">Buy Fieno</a></li>
                                <li><a className={this.state.subMenuActive == 'stats' ? 'subnav-active' : ''} href="/goatech-stats">My Goatech Stats</a></li>
                                {/*<li><a className={this.state.subMenuActive == 'pamm-history' ? 'subnav-active' : ''} href="/goatech-history">Goatech History</a></li> */ }
                                {/*<li><a className={this.state.subMenuActive == 'info' ? 'subnav-active' : ''} href="/goatech-info">Information</a></li> */}
                            </ul>
                        </li>

                        <li className={this.state.currentPage == 'affiliate-area' ? 'nav-active' : ''}>
                            <a onClick={() => this.toggleAndActiveSubmenu('affiliate-area')} ><FontAwesomeIcon icon={faCircle} /> Affiliate Area</a>

                            <ul className={(!this.state.subMenuHidden && (this.state.currentSubNavActive == 'affiliate-area') ? 'subnav-active ' : '') + 'subnav-ul subnav-ul-4n'}>
                                <li><a className={this.state.subMenuActive == 'affiliate-area' ? 'subnav-active' : ''} href="/affiliate-area">Referrals</a></li>
                                <li><a className={this.state.subMenuActive == 'affiliate-commissions' ? 'subnav-active' : ''} href="/affiliate-commissions">Commissions</a></li>
                                <li><a className={this.state.subMenuActive == 'affiliate-bonus' ? 'subnav-active' : ''} href="/affiliate-bonus">Bonus</a></li>
                            </ul>
                        </li>

                        <li className={this.state.currentPage == 'academy-area' ? 'nav-active' : ''}>
                            <a onClick={() => this.toggleAndActiveSubmenu('academy-area')} ><FontAwesomeIcon icon={faCircle} /> Goat Academy</a>

                            <ul className={(!this.state.subMenuHidden && (this.state.currentSubNavActive == 'academy-area') ? 'subnav-active ' : '') + 'subnav-ul subnav-ul-5n'}>
                                <li><a className={this.state.subMenuActive == 'academy-courses' ? 'subnav-active' : ''} href="/academy-contents?course">Courses</a></li>
                                <li><a className={this.state.subMenuActive == 'academy-live-recorded' ? 'subnav-active' : ''} href="/academy-contents?group_live">Live Recorded</a></li>
                            </ul>
                        </li>

                        { /* <li className={'nav-tba'}><a href="#"><FontAwesomeIcon icon={faCircle} /> Staking (soon)</a></li>
                        <li className={'nav-tba'}><a href="#"><FontAwesomeIcon icon={faCircle} /> Breeding (soon)</a></li>
                        <li className={'nav-tba'}><a href="#"><FontAwesomeIcon icon={faCircle} /> GOATREX (soon)</a></li> */ }

                        {isAdminLogged() ?
                            <li className={'nav-admin' + (this.state.currentPage == 'goatech-admin' ? ' nav-active' : '')}>
                                <a onClick={() => this.toggleAndActiveSubmenu('goatech-admin')}><FontAwesomeIcon icon={faCircle} /> ADMIN AREA</a>

                                <ul className={(!this.state.subMenuHidden && (this.state.currentSubNavActive == 'goatech-admin') ? 'subnav-active ' : '') + 'subnav-ul subnav-ul-2n'}>
                                    <li><a className={this.state.subMenuActive == 'admin-burn-area' ? 'subnav-active' : ''} href="/admin-burn-area">Request Burn Area</a></li>
                                    <li><a className={this.state.subMenuActive == 'admin-update-history' ? 'subnav-active' : ''} href="/admin-update-history"><b>Update History</b></a></li>
                                    <li><a className={this.state.subMenuActive == 'admin-goatech-list-balle' ? 'subnav-active' : ''} href="/admin-goatech-list-balle"><b>Balle List</b></a></li>
                                    <li><a className={this.state.subMenuActive == 'admin-goatech-list-wallet' ? 'subnav-active' : ''} href="/admin-goatech-list-wallet"><b>Wallet List</b></a></li>
                                    
                                    <li><a className={this.state.subMenuActive == 'admin-commissions-affiliate' ? 'subnav-active' : ''} href="/admin-commissions-affiliate">Commissions Affiliate</a></li>
                                    <li><a className={this.state.subMenuActive == 'admin-affiliate-bonus' ? 'subnav-active' : ''} href="/admin-affiliate-bonus">Affiliate Bonus</a></li>

                                    <li><a className={this.state.subMenuActive == 'admin-academy-manage-courses' ? 'subnav-active' : ''} href="/admin-academy-manage-contents?course">Manage Courses</a></li>
                                    <li><a className={this.state.subMenuActive == 'admin-academy-manage-live-recorded' ? 'subnav-active' : ''} href="/admin-academy-manage-contents?group_live">Manage Live Rec.</a></li>
                                </ul>
                            </li>
                            : null}
                    </ul>

                    <div className='account-address-box'>
                        <a style={{ cursor: 'pointer' }} onClick={() => this.toggleShowAddress()}>
                            {!this.state.showAddress ? <FontAwesomeIcon icon={faEye} /> : ''}
                            {this.state.showAddress ? <FontAwesomeIcon icon={faEyeSlash} /> : ''}
                        </a>
                        Wallet Address:
                        <span>{this.state.showAddress ? this.state.address : '0x0************'}</span>
                    </div>
                </div>
                <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/FILIPPO_BGSCURO.jpg'})` }} className='account-col1-bg'></div>
            </>
        );
    }
}

export default MenuArea;