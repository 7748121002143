import React, {Component} from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';
import {Reti} from '../../../components/wallet_generic/Wallets.js';
import {TipiContenuto} from '../../../components/area_riservata/academy/TipiContenuto';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faPencil, faTrashAlt, faVideo} from "@fortawesome/free-solid-svg-icons";
import {EndPointMethod, RequestBackend} from "../../../components/area_riservata/backend/RequestBackend";

function BoxContent(classe, type, props) {
    const descr_breve = props.description.length > 200 ? props.description.slice(0, 200) + "..." : props.description;
    return (
        <div className={'academy-box-content'}>
            <a href={'/academy-view-content?c=' + props.alias_url}>
                <div className={'academy-box-cover'} style={{backgroundImage: 'url(' + props.cover + ')'}}>
                    &nbsp;
                </div>
                <div className={'academy-box-text'}>
                    <p className={'academy-box-title'}>{props.name}</p>
                    <p className={'academy-box-descr'}>{descr_breve}</p>
                    <p className={'academy-box-footer'}>
                        <span className={'academy-box-duration'}><FontAwesomeIcon icon={faClock}/>&nbsp;&nbsp;{props.total_duration ? parseInt(props.total_duration / 60) : 0}h</span>
                        <span className={'academy-box-nvideo'}><FontAwesomeIcon icon={faVideo}/>&nbsp;&nbsp;{props.total_video}</span>
                    </p>
                </div>
            </a>
        </div>
    )
}
export class AcademyContents extends Component {

    constructor(props) {
        super(props);

        let tipo_contenuto = "";
        let submenu = "";
        const param_url = window.location.search.substring(1);

        if (param_url == TipiContenuto.COURSE) {
            tipo_contenuto = TipiContenuto.COURSE;
            submenu = "academy-courses";
        } else if (param_url == TipiContenuto.GROUP_LIVE) {
            tipo_contenuto = TipiContenuto.GROUP_LIVE;
            submenu = "academy-live-recorded";
        } else {
            window.location.href = "/";
        }

        this.state = {
            'menu_address': '',
            type: tipo_contenuto,
            submenu: submenu,
            error_loading: '',

            list_contents: [],
        };
        this.setAddress = this.setAddress.bind(this);
    }

    async setAddress(address) {
        this.setState({'menu_address': address});

        const request = new RequestBackend(address, EndPointMethod.ACADEMY_LIST_CONTENTS, {type: this.state.type});
        const response = await request.run();
        if(request.isResponseError()) {
            this.setState({
                error_loading: response.error
            })
            return;
        }
        if (request.getStatusCode() == 200) {
            const response_data = response.data;
            this.setState({
                list_contents: response_data
            });
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress}
                            checkLogged="true"/>

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='academy-area' subMenu={this.state.submenu}
                                          address={this.state.menu_address}/>
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding scroller scroller-stylized'>

                                    {this.state.type == TipiContenuto.COURSE ?
                                        (<h3>Goat Academy</h3>)
                                        : null}
                                    {this.state.type == TipiContenuto.GROUP_LIVE ?
                                        (<h3>Goat Academy - Recordings</h3>)
                                        : null}

                                    {this.state.error_loading != "" ? <p>{this.state.error_loading}</p> : null}

                                    <div className={'academy-grid-contents'}>
                                        {this.state.list_contents && Array.from(this.state.list_contents).length > 0 ? Array.from(this.state.list_contents).map((content, i) => {
                                            return BoxContent(this, this.state.type, content);
                                        }, this) : (
                                            <p style={{margin: 10}}></p>
                                        )}
                                        <div style={{clear: 'both'}}></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div
                            style={{background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain`}}
                            className='account-logo-goat'
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AcademyContents;