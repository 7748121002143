
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";
import GoatMintBtn from "../ui/GoatMintBtn";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div className='section2-background section-goatchart-background' style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatChart/background.png'})` }}>
                    <div className="container">
                        <div style={{ paddingTop: 115, textAlign: 'left'}} className="section2-right">
                            <BadgeHome testo={testi('goatchart_label')} style={{ color: '#CD40FF',textTransform: 'uppercase' }} />
                            <h3 className="h3-section2">Goat chart</h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goatchart_parag') }} style={{ fontSize: 20, lineHeight: '28px' }} className="parag-section2"></p>
                            <GoatMintBtn/>
                        </div>
                        <div style={{ paddingTop: 0 }} className="section2-left">
                            <img src="assets/img/HomeGoatChart/goat-chart.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;