import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import GoatMintBtn from "../ui/GoatMintBtn";

import { testi, lingua_sito } from "../../../language/lang.js";

function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FontAwesomeIcon className='slick-next' icon={faAngleRight} onClick={onClick}/>
    );
}

function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FontAwesomeIcon className='slick-prev' icon={faAngleLeft} onClick={onClick}/>
    );
}

class GoatNFTLevels extends Component {

    settingsSlide() {
        return {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 7000,
            slidesToShow: 5,
            adaptiveHeight: false,
            arrows: true,
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
    }

    LevelBox(props) {
        return (
            <div className={'nftlevels-box'}>
                <div className={'nftlevels-thumb'} style={{backgroundImage: 'url(' + props.thumb + ')'}}>
                    <h4>{props.title}</h4>
                    {props.alphagoat ? <span className={'nftlevels-label-alphagoat'}>Alpha Goat</span> : null}
                    {props.multiply ? <span className={'nftlevels-label-multiply'}>x{props.multiply}</span> : null}
                </div>
                <div style={{background: props.background, color: props.textcolor ? props.textcolor : null}}
                     className={'nftlevels-descr'}>
                    {props.children}
                </div>
            </div>
        )
    }

    render() {
        const lingua = lingua_sito();
        return (
            <div className='container-fluid'>
                <div id="levels">
                    <div style={{paddingBottom: 0}} className="container-padding">
                        <h3 style={{textAlign: 'center'}} className="h3-section2" dangerouslySetInnerHTML={{__html: testi("goat_nft_level_title")}}></h3>
                        <p style={{textAlign: 'center', margin: '30px auto'}} className="paragw80 parag-section-opacity" dangerouslySetInnerHTML={{__html: testi("goat_nft_level_subtitle")}}></p>
                    </div>

                    <div className={'cont-slider'}>
                        <Slider {...this.settingsSlide()}>
                            <this.LevelBox background={'#292929'} thumb={'assets/img/HomeNFTLevels/1NFT.png'}
                                           title={'1 NFT'}>
                                {lingua == "it" ? (
                                    <ul>
                                        <li>Accesso iniziale alla Nazione</li>
                                        <li>Accedi al <b>Discord Holders</b></li>
                                        <li>Accedi <b>all’accademia completa</b> su Crypto e Blockchain (Mint)</li>
                                        <li>Accedi a <b>Goat-Chart</b></li>
                                        <li>Licenza <b>Goatech Bronze</b></li>
                                        <li>Accedi alle <b>Live di formazione Settimanali</b></li>
                                        <li>Produci token <b>$LATTE</b></li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>Initial access to the Nation</li>
                                        <li>Access to <b>Holders Discord</b></li>
                                        <li>Access to <b>Complete Academy</b> on Crypto and Blockchain (Minting)</li>
                                        <li>Access to <b>Goat-Chart</b></li>
                                        <li>License for <b>Goatech Bronze</b></li>
                                        <li>Access to <b>Weekly Training Live</b></li>
                                        <li>Produce <b>$LATTE</b> tokens</li>
                                    </ul>
                                )}
                            </this.LevelBox>
                            <this.LevelBox background={'#404040'} thumb={'assets/img/HomeNFTLevels/2NFT.png'}
                                           title={'2 NFT'}
                                           multiply={2}>
                                {lingua == "it" ? (
                                    <ul>
                                        <li>Accesso iniziale alla Nazione</li>
                                        <li>Accedi al <b>Discord Holders</b></li>
                                        <li>Accedi <b>all’accademia completa</b> su Crypto e Blockchain (Mint)</li>
                                        <li>Accedi a <b>Goat-Chart</b></li>
                                        <li>Licenza <b>Goatech Bronze</b></li>
                                        <li>Accedi alle <b>Live di formazione Settimanali</b></li>
                                        <li>Produci più token <b>$LATTE</b></li>
                                        <li>Possibilità di generare <b>Baby Goat in modo GRATUITO!</b></li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>Initial access to the Nation</li>
                                        <li>Access to <b>Holders Discord</b></li>
                                        <li>Access to <b>Complete Academy</b> on Crypto and Blockchain (Minting)</li>
                                        <li>Access to <b>Goat-Chart</b></li>
                                        <li>License for <b>Goatech Bronze</b></li>
                                        <li>Access to <b>Weekly Training Live</b></li>
                                        <li>Produce more <b>$LATTE</b> tokens</li>
                                        <li>Ability to generate <b>Baby Goat for FREE!</b></li>
                                    </ul>
                                )}
                            </this.LevelBox>
                            <this.LevelBox textcolor={'rgba(0, 0, 0, 0.8)'} background={'#FFE9B1'} alphagoat={true}
                                           thumb={'assets/img/HomeNFTLevels/5NFT.png'}
                                           title={'5 NFT'}
                                           multiply={5}>
                                {lingua == "it" ? (
                                    <ul>
                                        <li>Accesso iniziale alla Nazione</li>
                                        <li>Accedi al <b>Discord Holders</b></li>
                                        <li>Accedi <b>all’accademia completa</b> su Crypto e Blockchain (Mint)</li>
                                        <li>Accedi a <b>Goat-Chart</b></li>
                                        <li>Accedi a <b>Goatech</b></li>
                                        <li>Accedi alle <b>Live di formazione Settimanali</b></li>
                                        <li>Produci più token <b>$LATTE</b></li>
                                        <li>Possibilità di generare <b>Baby Goat in modo GRATUITO!</b></li>
                                        <li>Diventi un <b>ALPHA-GOAT</b></li>
                                        <li>Live di Formazione <b>Esclusive</b></li>
                                        <li>Accedere ai <b>nuovi servizi in anteprima</b></li>
                                        <li>Licenza <b>Goatech Silver</b></li>
                                        <li><b>Trattamenti riservati</b> ad eventi Goat.</li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>Initial access to the Nation</li>
                                        <li>Access to <b>Holders Discord</b></li>
                                        <li>Access to <b>Complete Academy</b> on Crypto and Blockchain (Minting)</li>
                                        <li>Access to <b>Goat-Chart</b></li>
                                        <li>Access to <b>Goatech</b></li>
                                        <li>Access to <b>Weekly Training Live</b></li>
                                        <li>Produce more <b>$LATTE</b> tokens</li>
                                        <li>Ability to generate <b>Baby Goat for FREE!</b></li>
                                        <li>Become an <b>ALPHA-GOAT</b></li>
                                        <li>Exclusive <b>Training Live</b></li>
                                        <li>Access to <b>new services in preview</b></li>
                                        <li>License for <b>Goatech Silver</b></li>
                                        <li><b>Reserved treatments</b> at Goat events.</li>
                                    </ul>
                                )}
                            </this.LevelBox>
                            <this.LevelBox
                                background={'linear-gradient(180deg, #FFDA7B 0%, #F3FFA8 100%), linear-gradient(180deg, #A7BB2A 0%, #F3FFA8 100%), url(.jpg), #FCD343'}
                                textcolor={'rgba(0, 0, 0, 0.8)'}
                                alphagoat={true}
                                title={'10 NFT'}
                                thumb={'assets/img/HomeNFTLevels/10NFT.png'}
                                multiply={10}
                            >
                                {lingua == "it" ? (
                                    <ul>
                                        <li>Accesso iniziale alla Nazione</li>
                                        <li>Accedi al <b>Discord Holders</b></li>
                                        <li>Accedi <b>all’accademia completa</b> su Crypto e Blockchain (Mint)</li>
                                        <li>Accedi a <b>Goat-Chart</b></li>
                                        <li>Accedi a <b>Goatech</b></li>
                                        <li>Accedi alle <b>Live di formazione Settimanali</b></li>
                                        <li>Produci più token <b>$LATTE</b></li>
                                        <li>Possibilità di generare <b>Baby Goat in modo GRATUITO!</b></li>
                                        <li>Diventi un <b>ALPHA-GOAT</b></li>
                                        <li>Live di Formazione <b>Esclusive</b></li>
                                        <li>Accedere ai <b>nuovi servizi in anteprima</b></li>
                                        <li>Licenza <b>Gold Goatech</b></li>
                                        <li><b>Trattamenti VIP</b> ad eventi Goat</li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>Initial access to the Nation</li>
                                        <li>Access to <b>Holders Discord</b></li>
                                        <li>Access to <b>Complete Academy</b> on Crypto and Blockchain (Minting)</li>
                                        <li>Access to <b>Goat-Chart</b></li>
                                        <li>Access to <b>Goatech</b></li>
                                        <li>Access to <b>Weekly Training Live</b></li>
                                        <li>Produce more <b>$LATTE</b> tokens</li>
                                        <li>Ability to generate <b>Baby Goat for FREE!</b></li>
                                        <li>Become an <b>ALPHA-GOAT</b></li>
                                        <li>Exclusive <b>Training Live</b></li>
                                        <li>Access to <b>new services in preview</b></li>
                                        <li>License for <b>Gold Goatech</b></li>
                                        <li><b>VIP treatments</b> at Goat events</li>
                                    </ul>
                                )}
                            </this.LevelBox>
                            <this.LevelBox
                                background={'linear-gradient(0deg, #FFD465, #FFD465), linear-gradient(180deg, #89963D 0%, #F3FFA8 100%), url(.jpg), #FF8038'}
                                textcolor={'rgba(0, 0, 0, 0.8)'}
                                alphagoat={true}
                                title={'20 NFT'}
                                multiply={20}
                                thumb={'assets/img/HomeNFTLevels/20NFT.png'}
                            >
                                {lingua == "it" ? (
                                    <ul>
                                        <li>Accesso iniziale alla Nazione</li>
                                        <li>Accedi al <b>Discord Holders</b></li>
                                        <li>Accedi <b>all’accademia completa</b> su Crypto e Blockchain (Mint)</li>
                                        <li>Accedi a <b>Goat-Chart</b></li>
                                        <li>Accedi a <b>Goatech</b></li>
                                        <li>Accedi alle <b>Live di formazione Settimanali</b></li>
                                        <li>Produci più token <b>$LATTE</b></li>
                                        <li>Possibilità di generare <b>Baby Goat in modo GRATUITO</b>!</li>
                                        <li>Diventi un ALPHA-GOAT</li>
                                        <li>Live di Formazione <b>Esclusive</b></li>
                                        <li>Accedere ai <b>nuovi servizi in anteprima</b></li>
                                        <li>Licenza <b>Gold Goatech</b></li>
                                        <li><b>Trattamenti VIP</b> ad eventi Goat.</li>
                                        <li>Accesso futuro alle <b>Shares Aziendali.</b></li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>Initial access to the Nation</li>
                                        <li>Access to <b>Holders Discord</b></li>
                                        <li>Access to <b>Complete Academy</b> on Crypto and Blockchain (Minting)</li>
                                        <li>Access to <b>Goat-Chart</b></li>
                                        <li>Access to <b>Goatech</b></li>
                                        <li>Access to <b>Weekly Training Live</b></li>
                                        <li>Produce more <b>$LATTE</b> tokens</li>
                                        <li>Ability to generate <b>Baby Goat for FREE</b>!</li>
                                        <li>Become an ALPHA-GOAT</li>
                                        <li>Exclusive <b>Training Live</b></li>
                                        <li>Access to <b>new services in preview</b></li>
                                        <li>License for <b>Gold Goatech</b></li>
                                        <li><b>VIP treatments</b> at Goat events.</li>
                                        <li>Future access to <b>Corporate Shares.</b></li>
                                    </ul>
                                )}
                            </this.LevelBox>
                        </Slider>
                        <GoatMintBtn/>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatNFTLevels;