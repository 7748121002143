import { getWeb3Provider } from './helpers/getWeb3Provider';

export class ContractData {

    static async loadInfo() {
        const provider = await getWeb3Provider();
        const contract = provider.contract;
        
        let mintPrice = await contract.getMintPrice();
        return {
            'mintPrice': mintPrice.toString(),
        };
    }
}

export default ContractData;