import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import { testi } from "../../../language/lang";

function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FontAwesomeIcon className='slick-next' icon={faAngleRight} onClick={onClick}/>
    );
}

function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FontAwesomeIcon className='slick-prev' icon={faAngleLeft} onClick={onClick}/>
    );
}

export class HomeSection extends Component {

    settingsSlide() {
        return {
            dots: false,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 7000,
            slidesToShow: 3,
            adaptiveHeight: false,
            arrows: true,
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
    }

    DrawAltroServizio(props) {
        return (
            <div className={'altriservizi-servbox ' + (props.blur ? 'servbox-blur' : null)}>
                <div className={'servbox-head'}
                     style={{
                         background: `url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatAltriServizi/369.png'})`,
                         color: props.color
                     }}
                >
                    {props.title}
                </div>
                <div class={'servbox-body'}>
                    <div className={'servbox-bg'} style={{backgroundColor: props.backgroundColor ? props.backgroundColor : null, backgroundImage: 'url(' + props.background + ')'}}></div>
                    <p>
                        {props.children}
                    </p>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='container altriservizi-container'>
                <h3 className="h3-section2 centered" dangerouslySetInnerHTML={{__html: testi("servizi_title")}} ></h3>

                <Slider {...this.settingsSlide()}>
                    <this.DrawAltroServizio color={'#FA7D47'} background={'assets/img/HomeGoatAltriServizi/325.png'}
                                            title={'Goat Academy'} >
                        <div dangerouslySetInnerHTML={{__html: testi("servizi_academy")}}></div>
                    </this.DrawAltroServizio>
                    <this.DrawAltroServizio color={'#8FFF00'} blur={true} background={'assets/img/HomeGoatAltriServizi/326.png'} title={/* 'Goatrex'*/ 'Goat Nation'}>
                        <div dangerouslySetInnerHTML={{__html: testi(/*"servizi_goatrex"*/ "servizi_academy")}}></div>
                    </this.DrawAltroServizio>
                    <this.DrawAltroServizio color={'grey'} background={''} backgroundColor={'#071d2e'} title={'Goat Nation'}
                                            blur={true}>
                        <div dangerouslySetInnerHTML={{__html: testi("servizi_academy")}}></div>
                    </this.DrawAltroServizio>
                </Slider>
            </div>
        );
    }
}

export default HomeSection;