import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import GoatMintBtn from '../ui/GoatMintBtn.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import testi from "../../../language/lang";
import {MINT_OPENED} from "../../../config/ConfigMint";
import DiscordBtn from "../ui/DiscordBtn";
import GoatechWhitepaper from "../ui/GoatechWhitePaper";

function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FontAwesomeIcon className='slick-next' icon={faAngleRight} onClick={onClick}/>
    );
}

function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FontAwesomeIcon className='slick-prev' icon={faAngleLeft} onClick={onClick}/>
    );
}

export class HomeSection extends Component {

    settingsSlide() {
        return {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 7000,
            slidesToShow: 3,
            adaptiveHeight: false,
            arrows: true,
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
    }

    DrawGoatechLicense(props) {
        return (
            <div className={'goatech-license-box'}
                 style={{backgroundImage: 'url(' + props.background + ')', color: props.color}}>
                <img class={'goatech-license-goatnation'} src={'assets/img/goat_nation_WHITE.png'}/>
                <p className={'goatech-license-title'}>{props.license}</p>
                <div className={'goatech-license-reward'}>
                    <p class={'license-reward-bold'}>Variable Rewards:</p>
                    <p>Up to {props.rewards}%</p>
                </div>
                <p className={'goatech-license-note'}>Number of NFTs: {props.note}</p>
            </div>
        )
    }

    render() {
        return (
            <div id={'goatech'} className='container-fluid goatech-container'>
                <div className='section2-background' style={{
                    background: `url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatech/1a-min.png'})`
                }}>
                    <div id="goatech" className="container" style={{position: 'relative', paddingTop: 50, paddingBottom: 50}}>
                        <div style={{paddingTop: 95}} className="section2-left">
                            <h3 className="h3-section2 mobile_center">Goatech</h3>
                            <p style={{marginBottom: '25px'}} className="parag-section1" dangerouslySetInnerHTML={{__html: testi("goatech_parag")}}></p>
                            {MINT_OPENED ? <GoatMintBtn/> : <DiscordBtn />}
                        </div>
                        <div className="section2-right">
                            <img style={{maxWidth: 600, position: 'absolute', top: 20, right: 0, imageOrientation: 'none'}}
                                 src="assets/img/account/goatech/gt212.png"/>
                        </div>
                    </div>
                </div>

                <div className={'container'}>
                    <h4 className="h4-section" style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: testi("goatech_title_comeopera")}}></h4>
                    <div className={'grid-three-column'}>
                        <div className={'column'}>
                            <div className={'goatech-icon'}>
                                <img src={'assets/img/HomeGoatech/IconBTC.png'} />
                            </div>
                            <p className={'parag-section-opacity'} dangerouslySetInnerHTML={{__html: testi("goatech_column_1")}}></p>
                        </div>
                        <div className={'column'}>
                            <div className={'goatech-icon'}>
                                <img src={'assets/img/HomeGoatech/Icon2.png'} />
                            </div>
                            <p className={'parag-section-opacity'} dangerouslySetInnerHTML={{__html: testi("goatech_column_2")}}></p>
                        </div>
                        <div className={'column'}>
                            <div className={'goatech-icon'}>
                                <img src={'assets/img/HomeGoatech/Icon3.png'} />
                            </div>
                            <p className={'parag-section-opacity'} dangerouslySetInnerHTML={{__html: testi("goatech_column_3")}}></p>
                        </div>
                    </div>
                </div>

                <h4 className="h4-section" style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: testi("goatech_platform_title")}}></h4>
                <p className={'paragw80 parag-section-opacity'} dangerouslySetInnerHTML={{__html: testi("goatech_platform_subtitle")}} ></p>
                <p style={{marginTop: '0px'}}>
                    <img className={'goatech-previewimg'} src={'assets/img/HomeGoatech/goatech1.png'}/>
                </p>

                <div style={{height: 'auto'}} className='section2-background'>
                    <div className="container" style={{position: 'relative'}}>
                        <div className="section2-left">
                            <h3 className="h3-section2 mobile_center" dangerouslySetInnerHTML={{__html: testi("goatech_platform_buget_title")}}></h3>
                            <p className=" parag-section-opacity" dangerouslySetInnerHTML={{__html: testi("goatech_platform_buget_parag")}}></p>

                            {MINT_OPENED ? <GoatMintBtn/> : null} <GoatechWhitepaper/>
                        </div>
                        <div className="section2-right">
                            <img style={{ maxWidth: 520, margin: '80px auto', display: 'block'}} src={'assets/img/HomeGoatech/goatech-grafico.png'} />
                        </div>
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>

                <h4 className="h4-section" style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: testi("goatech_levels_title")}}></h4>
                <p className={'paragw80 parag-section-opacity'} dangerouslySetInnerHTML={{__html: testi("goatech_levels_parag")}}></p>

                <div className={'container'}>
                    <Slider {...this.settingsSlide()}>
                        <this.DrawGoatechLicense color={'#6F4A40'} background={'assets/img/HomeGoatech/card1.png'}
                             rewards={4} note={'From 1 to 4'} license={'Bronze License'}/>
                        <this.DrawGoatechLicense color={'#60697E'} background={'assets/img/HomeGoatech/card2.png'}
                                                 rewards={5} note={'From 5 to 9'} license={'Silver License'}/>
                        <this.DrawGoatechLicense color={'#7E602A'} background={'assets/img/HomeGoatech/card3.png'}
                                                 rewards={6} note={'10+'} license={'Gold License'}/>
                    </Slider>
                </div>

                <p dangerouslySetInnerHTML={{__html: testi("disclaimer")}} className={'paragw80 footer-disclaimer'}></p>
            </div>
        );
    }
}

export default HomeSection;