import React, {Component} from 'react';
import {Licenses} from "./Licenses";

export class GoatechSingleLicense extends Component {

    constructor(props) {
        super(props);

        this.state = {
            license: props.license
        };
    }

    DrawCard(props) {
        return (
            <div className={'goatech-license-box'}
                 style={{backgroundImage: 'url(' + props.background + ')', color: props.color}}>
                <img className={'goatech-license-goatnation'} src={'assets/img/goat_nation_WHITE.png'}/>
                <p className={'goatech-license-title'}>{props.license}</p>
                <div className={'goatech-license-reward'}>
                    <p className={'license-reward-bold'}>Variable Rewards:</p>
                    <p>Up to {props.rewards}%</p>
                </div>
                <p className={'goatech-license-note'}>Number of NFTs: {props.note}</p>
            </div>
        )
    }

    render() {
        if (this.state.license == Licenses.BRONZE) {
            return (
                <this.DrawCard color={'#6F4A40'} background={'assets/img/HomeGoatech/card1.png'}
                                         rewards={4} note={'From 1 to 4'} license={'Bronze License'}/>
            )
        } else if (this.state.license == Licenses.SILVER) {
            return (
                <this.DrawCard color={'#60697E'} background={'assets/img/HomeGoatech/card2.png'}
                                         rewards={5} note={'From 5 to 9'} license={'Silver License'}/>
            )
        } else if (this.state.license == Licenses.GOLD) {
            return (
                <this.DrawCard color={'#7E602A'} background={'assets/img/HomeGoatech/card3.png'}
                                         rewards={6} note={'10+'} license={'Gold License'}/>
            )
        }
    }
}

export default GoatechSingleLicense;