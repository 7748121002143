import React, {useState, Component} from 'react';

import {testi, lingua_sito} from "../../../language/lang.js";

import {Waypoint} from 'react-waypoint';

function PointMap(param) {
    const [attivo, impostaAttivo] = useState(false);

    const classe = attivo ? 'box-roadmap-element roadmap-active' : 'box-roadmap-element';
    const classe1 = param.nascosto ? 'box-roadmap-element-hidden' : classe;
    const classe_mobile = param.nascosto ? 'roadmap-grid-element roadmap-grid-element-hiddenmob' : 'roadmap-grid-element';

    return (
        <Waypoint bottomOffset='400px' onEnter={() => impostaAttivo(true)} onLeave={() => impostaAttivo(false)}>
            <div style={param.style} className={classe_mobile}>
                <div className={classe1}>
                    <h4>{param.titolo}</h4>
                    {param.children}
                </div>
            </div>
        </Waypoint>
    );
}

export class HomeSection extends Component {
    render() {
        const lingua = lingua_sito();
        return (
            <div style={{
                background: `url(${process.env.PUBLIC_URL + '/assets/img/GoatRoadMap/tigre2.png'})`,
            }} className='roadmap-background'>
                <div className="container container-padding">

                    <div style={{
                        background: `url(${process.env.PUBLIC_URL + '/assets/img/GoatRoadMap/Rectangle282.png'})`,
                    }} className='riga_roadmap'/>

                    <div className="roadmap-grid">
                        <PointMap titolo={testi('goatroadmap_gennaio2023')}>
                            <img style={{top: -10, right: -15, width: 100}}
                                 src={'assets/img/GoatRoadMap/2023/goatech3.svg'}/>
                            {lingua == "it" ? (
                                <ul>
                                    <li>Lancio Goatech 3.0</li>
                                    <li>Lancio funnel Goat</li>
                                    <li>Lancio nuovo piano editoriale live + formazione</li>
                                    <li>Annuncio nuove partnership</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Launch of Goatech 3.0</li>
                                    <li>Launch of Goat funnel</li>
                                    <li>Launch of new live editorial plan + training</li>
                                    <li>Announcement of new partnerships</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true"/>

                        <PointMap nascosto="true"/>
                        <PointMap titolo={testi('goatroadmap_febbraio2023')}>
                            <img src={'assets/img/GoatRoadMap/2023/esim.svg'}/>
                            {lingua == "it" ? (
                                <ul>
                                    <li>Incontri in Italia Goat</li>
                                    <li>Lancio Academia Trading</li>
                                    <li>e-Sim Goat</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Meetings in Italy Goat</li>
                                    <li>Launch of Trading Academy</li>
                                    <li>Goat e-Sim</li>
                                </ul>
                            )}
                        </PointMap>


                        <PointMap titolo={testi('goatroadmap_marzo2023')}>
                            <img style={{transform: 'rotate(20deg)'}}
                                 src={'assets/img/GoatRoadMap/2023/graduation-cap-duotone.svg'}/>
                            {lingua == "it" ? (
                                <ul>
                                    <li>Lancio Goat-Academy</li>
                                    <li>Evento Partnership Internazionali</li>
                                    <li>Deadline Genesis Goat</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Launch of Goat-Academy</li>
                                    <li>International Partnership Event</li>
                                    <li>Genesis Goat Deadline</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true"/>

                        <PointMap nascosto="true"/>
                        <PointMap titolo={testi('goatroadmap_aprile2023')}>
                            <img style={{transform: 'rotate(-20deg)'}}
                                 src={'assets/img/GoatRoadMap/2023/gift-duotone.svg'}/>
                            {lingua == "it" ? (
                                <ul>
                                    <li>Apertura ***** Goat</li>
                                    <li>Evento NFT NYC</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Launch of ***** Goat</li>
                                    <li>NFT NYC event</li>
                                </ul>
                            )}
                        </PointMap>

                        <PointMap titolo={testi('goatroadmap_maggio2023')}>
                            <img style={{width: 80, transform: 'rotate(20deg)'}}
                                 src={'assets/img/GoatRoadMap/2023/navicella.svg'}/>
                            {lingua == "it" ? (
                                <ul>
                                    <li>Lancio Goat-S******</li>
                                    <li>Surprise coming soon</li>
                                    <li>Surprise coming soon</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Launch of Goat-S******</li>
                                    <li>Surprise coming soon</li>
                                    <li>Surprise Coming soon</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true"/>

                        <PointMap nascosto="true"/>
                        <PointMap titolo={testi('goatroadmap_giugno2023')}>
                            <img style={{transform: 'rotate(10deg)'}} src={'assets/img/GoatRoadMap/2023/italia.svg'}/>
                            {lingua == "it" ? (
                                <ul>
                                    <li>Evento Goat aperto a tutti in Italia (2,3,4 Giugno)</li>
                                    <li>Lancio Token $LATTE</li>
                                    <li>Surprise coming soon</li>
                                    <li>Surprise coming soon</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Open Goat event in Italy (June 2nd, 3rd, and 4th)</li>
                                    <li>Launch of $LATTE Token</li>
                                    <li>Surprise Coming soon</li>
                                    <li>Surprise Coming soon</li>
                                </ul>
                            )}
                        </PointMap>

                        <PointMap titolo={testi('goatroadmap_fine2023')}>
                            <img style={{transform: 'rotate(10deg)'}}
                                 src={'assets/img/GoatRoadMap/2023/babygoat.svg'}/>
                            {lingua == "it" ? (
                                <ul>
                                    <li>Lancio Baby Goat</li>
                                    <li>Lancio Alien Goat</li>
                                    <li>Lancio Goatrex</li>
                                    <li>Lancio Terzo Servizio</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Launch Baby Goat</li>
                                    <li>Launch Alien Goat</li>
                                    <li>Launch Goatrex</li>
                                    <li>Launch Third Service</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true"/>
                        <PointMap nascosto="true"/>
                    </div>

                    {/* lingua == "it" ? (
                        <h3 style={{textAlign: 'center'}} className="h3-section2 h3-endroadmap">2024: Conquista del
                            mondo!</h3>
                    ) : (
                        <h3 style={{textAlign: 'center'}} className="h3-section2 h3-endroadmap">2024: Conquest of the
                            world!</h3>
                    ) */}
                </div>
            </div>
        );
    }
}

export default HomeSection;