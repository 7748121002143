import React, {Component} from 'react';

import HeaderGoat from '../../../../components/ui/HeaderGoat';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';
import {Reti} from '../../../../components/wallet_generic/Wallets';
import BtnCreateElement from "../../../../components/area_riservata/academy/admin/ui/BtnCreateElement";
import ModalManageContents from "../../../../components/area_riservata/academy/admin/ModalManageContents";
import {TipiContenuto} from "../../../../components/area_riservata/academy/TipiContenuto";
import {EndPointMethod, RequestBackend} from "../../../../components/area_riservata/backend/RequestBackend";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faPencil, faTrashAlt, faVideo} from "@fortawesome/free-solid-svg-icons";
import ModalConfirm from '../../../../components/area_riservata/ui/ModalConfirm';
import {showError} from '../../../../components/ui/ToastErrore';

function BoxContentAdmin(classe, type, props) {
    const descr_breve = props.description.length > 200 ? props.description.slice(0, 200) + "..." : props.description;
    return (
        <div className={'academy-box-content'}>
            <a href={'/admin-academy-edit-content?type=' + type + '&_id=' + props._id}>
                <div className={'academy-box-cover'} style={{backgroundImage: 'url(' + props.cover + ')'}}>
                    <button onClick={(e) => {
                        e.preventDefault();
                        classe.toggle_modal_edit(props);
                    }} className={'academy-box-btn academy-box-btn-edit'}>
                        <FontAwesomeIcon icon={faPencil}/>&nbsp;&nbsp;Edit
                    </button>
                    <button onClick={(e) => {
                        e.preventDefault();
                        classe.delete_content(props);
                    }} className={'academy-box-btn academy-box-btn-delete'}>
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                </div>
                <div className={'academy-box-text'}>
                    <p className={'academy-box-title'}>{props.name}</p>
                    <p className={'academy-box-descr'}>{descr_breve}</p>
                    <p className={'academy-box-footer'}>
                        <span className={'academy-box-duration'}><FontAwesomeIcon icon={faClock}/>&nbsp;&nbsp;{props.total_duration ? parseInt(props.total_duration / 60) : 0}h</span>
                        <span className={'academy-box-nvideo'}><FontAwesomeIcon icon={faVideo}/>&nbsp;&nbsp;{props.total_video}</span>
                    </p>
                </div>
            </a>
        </div>
    )
}

export class AdminAcademyManageContents extends Component {
    constructor(props) {
        super(props);

        let tipo_contenuto = "";
        let submenu = "";
        const param_url = window.location.search.substring(1);

        if (param_url == TipiContenuto.COURSE) {
            tipo_contenuto = TipiContenuto.COURSE;
            submenu = "admin-academy-manage-courses";
        } else if (param_url == TipiContenuto.GROUP_LIVE) {
            tipo_contenuto = TipiContenuto.GROUP_LIVE;
            submenu = "admin-academy-manage-live-recorded";
        } else {
            window.location.href = "/";
        }

        this.state = {
            menu_address: '',
            modal_create: false,
            modal_edit: false,
            type: tipo_contenuto,
            submenu: submenu,
            list_contents: [],

            edit_data: []
        };
        this.setAddress = this.setAddress.bind(this);
        this.toggle_modal_create = this.toggle_modal_create.bind(this);
        this.toggle_modal_edit = this.toggle_modal_edit.bind(this);
        this.delete_content = this.delete_content.bind(this);
    }

    toggle_modal_create() {
        this.setState({modal_create: !this.state.modal_create});
    }

    toggle_modal_edit(edit_data = false) {
        if (edit_data) {
            this.setState({
                edit_data: edit_data
            });
        }
        this.setState({
            modal_edit: !this.state.modal_edit,
        });
    }

    async setAddress(address) {
        this.setState({'menu_address': address});

        const request = new RequestBackend(address, EndPointMethod.ACADEMY_ADMIN_LIST_CONTENTS, {type: this.state.type});
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const response_data = response.data;
            this.setState({
                list_contents: response_data
            });
        }
    }

    async delete_content(props) {
        const modalConfirm = new ModalConfirm("Elimina contenuto", "Sei sicuro di voler eliminare \"" + props.name + "\"?", "Elimina");
        const responseModal = await modalConfirm.waitModal();
        if (responseModal) {
            const request = new RequestBackend(this.state.menu_address, EndPointMethod.ACADEMY_ADMIN_DELETE_CONTENT, {id: props._id});
            const response = await request.run();
            if (request.isResponseError()) {
                showError({'titolo': 'Errore', 'testo': 'Impossibile eliminare il contenuto: ' + response.error});
                return;
            }
            window.location.reload();
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress}
                            checkLogged="true"/>

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu={this.state.submenu}
                                          address={this.state.menu_address}/>
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding scroller scroller-stylized'>

                                    {this.state.type == TipiContenuto.COURSE ?
                                        (<>
                                            <h3>Manage Courses</h3>
                                            <BtnCreateElement onClick={(e) => this.toggle_modal_create()}
                                                              testo={'Create new course'}/>
                                            <ModalManageContents
                                                toggleModal={this.toggle_modal_create}
                                                address={this.state.menu_address}
                                                impostaModal={this.state.modal_create}
                                                id_modal={'add'}
                                                titolo={'Create new course'}
                                                tipo={this.state.type}
                                                callback={'/admin-academy-edit-content?type=' + this.state.type + '&_id={id}'}/>
                                        </>)
                                        : null}

                                    {this.state.type == TipiContenuto.GROUP_LIVE ?
                                        (<>
                                            <h3>Manage Live Recordings</h3>
                                            <BtnCreateElement onClick={(e) => this.toggle_modal_create()}
                                                              testo={'Create new group'}/>
                                            <ModalManageContents
                                                toggleModal={this.toggle_modal_create}
                                                address={this.state.menu_address}
                                                impostaModal={this.state.modal_create}
                                                id_modal={'add'}
                                                titolo={'Create new group'}
                                                tipo={this.state.type}
                                                callback={'/admin-academy-edit-content?type=' + this.state.type + '&_id={id}'}/>
                                        </>)
                                        : null}


                                    <div className={'academy-grid-contents'}>
                                        {this.state.list_contents && Array.from(this.state.list_contents).length > 0 ? Array.from(this.state.list_contents).map((content, i) => {
                                            return BoxContentAdmin(this, this.state.type, content);
                                        }, this) : (
                                            <p style={{margin: 10}}></p>
                                        )}
                                        <div style={{clear: 'both'}}></div>
                                    </div>

                                    <ModalManageContents
                                        toggleModal={this.toggle_modal_edit}
                                        address={this.state.menu_address}
                                        impostaModal={this.state.modal_edit}
                                        id_modal={'edit'}
                                        titolo={'Edit'}
                                        tipo={this.state.type}
                                        edit_data={this.state.edit_data}
                                        callback={'/admin-academy-edit-content?type=' + this.state.type + '&_id={id}'}/>
                                </div>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div
                            style={{background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain`}}
                            className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminAcademyManageContents;