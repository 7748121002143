import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {EndPointMethod, RequestBackend} from "../../backend/RequestBackend";
import {showError} from "../../../ui/ToastErrore";
import toBase64 from "../../../../helpers/fileToBase64";

export class ModalManageContents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu_address: '',
            mostra_modal: false,
            id_modal: this.props.id_modal,
            titolo_modal: this.props.titolo,
            type_content: this.props.tipo,
            callback: this.props.callback,
            edit_data: this.props.edit_data ? this.props.edit_data : {}
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.impostaModal != this.props.impostaModal) {
            this.setState({
                menu_address: this.props.address,
                mostra_modal: this.props.impostaModal,
                edit_data: this.props.edit_data
            });

            let edit_name = "";
            let edit_descr = "";

            if (this.props.impostaModal && this.props.edit_data && this.props.edit_data._id) {
                const edit_data = this.props.edit_data;
                edit_name = edit_data.name;
                edit_descr = edit_data.description
            }

            // Svuoto i valori
            document.getElementById(this.state.id_modal + 'name').value = edit_name;
            document.getElementById(this.state.id_modal + 'description').value = edit_descr;
            document.getElementById(this.state.id_modal + 'file').value = "";
        }
    }

    async salva() {

        const name = document.getElementById(this.state.id_modal + 'name').value;
        const description = document.getElementById(this.state.id_modal + 'description').value;
        if (name == "") {
            showError({'titolo': 'Errore', 'testo': 'Inserire tutti i campi richiesti'});
            return false;
        }

        const fileInput = document.getElementById(this.state.id_modal + 'file').files;
        const fileSelected = fileInput.length == 1 ? fileInput[0] : ""

        const body = {
            name: name,
            description: description,
            type: this.state.type_content,
            file: fileSelected != "" ? await toBase64(fileSelected) : "",
        };

        let edit_content = false;
        if (this.props.edit_data && this.props.edit_data._id && this.props.edit_data._id != "") {
            edit_content = true;
        }

        if (edit_content) {
            const element_delfile = document.getElementById(this.state.id_modal + 'delfile');
            const delFile = element_delfile ? element_delfile.checked : false;
            body.delete_file = delFile;
            body.id = this.props.edit_data._id;
        }

        const request = new RequestBackend(this.state.menu_address, EndPointMethod.ACADEMY_ADMIN_MANAGE_CONTENTS, {});
        const response = await request.run(body);

        if (request.isResponseError()) {
            if (response["error"]) {
                showError({'titolo': 'Errore', 'testo': response["error"]});
                return false;
            }
            showError({'titolo': 'Errore', 'testo': 'Errore nell\'inserimento elemento'});
            return false;
        }

        if (!edit_content) {
            showError({'titolo': 'Success!', 'testo': 'Il contenuto è stato inserito correttamente'});
        } else {
            showError({'titolo': 'Success!', 'testo': 'Il contenuto è stato aggiornato correttamente'});
        }
        const _this = this;
        window.setTimeout(function () {
            // window.location.href = _this.state.callback.replace('{id}', response['id']);
            window.location.reload();
        }, 1200);
    }

    render() {
        return (
            <div className={'modal-overlay modal-overlay-inframe' + (this.state.mostra_modal ? ' modal-show' : '')}>
                <div className='modal'>
                    <a onClick={() => this.props.toggleModal()} className='modal-close'>
                        <FontAwesomeIcon icon={faClose}/>
                    </a>
                    <form className='form-insert-content'>
                        <h4>{this.state.titolo_modal}</h4>

                        <div className={'riga'}>
                            <label>Name*</label>
                            <input required={true} type={'text'} id={this.state.id_modal + 'name'}/>
                        </div>
                        <div className={'riga'}>
                            <label>Description</label>
                            <textarea style={{maxWidth: '100%', minWidth: '100%'}} rows={5} type={'text'}
                                      id={this.state.id_modal + 'description'}></textarea>
                        </div>

                        <div className={'riga'}>
                            <label>Cover. Upload here the new photo (16:9)</label>
                            {this.state.edit_data && this.state.edit_data.cover && this.state.edit_data.cover != "" ?
                                (
                                    <p style={{fontSize: 13}}>
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                        <input style={{verticalAlign: 'middle'}} id={this.state.id_modal + 'delfile'}
                                               type={'checkbox'}/> Current cover:&nbsp;&nbsp;
                                        <a style={{wordBreak: 'break-all', color: "white"}}
                                           href={this.state.edit_data.cover} target={'_blank'}>
                                            Link
                                        </a>
                                    </p>
                                )
                                : null}
                            <input type={'file'} id={this.state.id_modal + 'file'}/>
                        </div>
                        <div className={'riga'}>
                            <input type={'submit'} onClick={(e) => {
                                e.preventDefault();
                                this.salva();
                            }} value={'Save'}/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ModalManageContents;