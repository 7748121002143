import Cookies from 'js-cookie';
import moment from 'moment';
import { isDev } from './helpers/isDev';

export function setSessionCookies(data) {

    let scadAuthorizationToken = data["AuthorizationToken"]["expires"];
    scadAuthorizationToken = moment(scadAuthorizationToken).toDate();

    let scadRefreshToken = data["RefreshToken"]["expires"];
    scadRefreshToken = moment(scadRefreshToken).toDate();

    const scadAuth = moment().add(1, 'year').toDate();

    const AuthorizationToken = data["AuthorizationToken"]["valore"];
    const RefreshToken = data["RefreshToken"]["valore"];

    const isSecureToken = isDev() == false ? true : false;

    // Login riuscito con successo, setto i cookie
    Cookies.set('AuthorizationToken', AuthorizationToken, { expires: scadAuthorizationToken, secure: isSecureToken });
    Cookies.set('RefreshToken', RefreshToken, { expires: scadRefreshToken, secure: isSecureToken });
    Cookies.set('AuthorizationGoatech', true, { expires: scadAuth, secure: isSecureToken });
    
    // Se è un admin
    if (data["isAdmin"]) {
        Cookies.set('AuthorizationGoatechIsAdmin', true, { expires: scadAuth, secure: isSecureToken });
    }
}