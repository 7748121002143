import React, {Component} from 'react';
import {Licenses} from "./Licenses";

export class BadgeLevel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            license: props.license
        };
    }

    DrawBadge(props) {
        return (
            <div className={'goatech-license-badge'}
                 style={{backgroundImage: 'url(' + props.background + ')', color: props.color}}>
                {props.license}
            </div>
        )
    }

    render() {
        if (this.state.license == Licenses.BRONZE) {
            return (
                <this.DrawBadge color={'#6F4A40'} background={'assets/img/HomeGoatech/card1.png'}
                                license={'Bronze License'}/>
            )
        } else if (this.state.license == Licenses.SILVER) {
            return (
                <this.DrawBadge color={'#60697E'} background={'assets/img/HomeGoatech/card2.png'}
                                license={'Silver License'}/>
            )
        } else if (this.state.license == Licenses.GOLD) {
            return (
                <this.DrawBadge color={'#7E602A'} background={'assets/img/HomeGoatech/card3.png'}
                                license={'Gold License'}/>
            )
        }
    }
}

export default BadgeLevel;