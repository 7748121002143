import React, { Component } from 'react';

import { testi } from "../../../language/lang.js";
import { getBackendURI } from '../../area_riservata/backend/getBackendURI.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEthereum} from "@fortawesome/free-brands-svg-icons";
import {MINT_OPENED} from "../../../config/ConfigMint";

// import Countdown from 'react-countdown';

function goToMint() {
    if (localStorage.getItem("wallet_connected")) {
        window.location.href = "/mint";
    } else {
        localStorage.setItem("redirect_login", "/mint");
        document.getElementById("btnConnectwallet").click();
    }
}

function BtnMintHome(props) {
    /* const rendererCountdown = (props) => (
        <div className='countdown'>
            <p>{props.days}<span>days</span></p>
            <p>{props.hours}<span>hours</span></p>
            <p>{props.minutes}<span>minutes</span></p>
            <p>{props.seconds}<span>seconds</span></p>
        </div>
    );
    */
    if (MINT_OPENED) {
        return (
            <>
                {/* <h1 className='intest2-h1'>Mint is closed</h1>
                <h3>Reopening in</h3>
                <Countdown date={'2022-10-02T18:00:00'} renderer={props => rendererCountdown(props)} /> */}
                <h1 className='intest2-h1'>Mint is now live</h1>
                <p className={'pPriceHomeHead'}><FontAwesomeIcon icon={faEthereum}/> <span
                    dangerouslySetInnerHTML={{__html: testi("welcome_pricemint")}}></span></p>
                <button onClick={() => goToMint()} className={'mint-btn'}>Mint now and join the nation</button>
                <p className={'pAvailableHomeHead'}>Already Minted: {props.available_supply} / 4.444</p>
            </>
        );
    } else {
        return (
            <>
                <h1 className='intest2-h1 closed'>Mint is <span>closed</span></h1>
                {/* <h3 dangerouslySetInnerHTML={{__html: testi("welcome_mint_closed_parag")}}></h3> */}
                <a href='https://opensea.io/collection/goat-nation-officialnft' className={'mint-btn'}>
                    {testi("welcome_buyon_opensea")} <img width={'20'} src={'assets/img/Header/Logomar-opens.png'} alt={'OpenSea'}/>
                </a>
            </>
        );
    }
}

export class HomeFoto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            available_supply: 0
        };

        this.loadSupply();
    }

    async loadSupply() {
        let error = false;
        const param = {
            method: 'GET',
        };
        try {
            const request = await fetch(getBackendURI() + "/home/supply", param).catch((error) => {
                error = true;
            });
            const data = await request.json().catch((error) => {
                error = true;
            });
            if (!error) {
                if (data.status && data.status == "ok") {
                    this.setState({ available_supply: data.supply });
                }
            }
        } catch (error) {

        }
    }

    render() {
        return (
            <div id="index" style={{
                    background: `url(${process.env.PUBLIC_URL + '/assets/img/fotohome/backgroundhome-min.jpg'}) center no-repeat`,
                    backgroundSize: 'cover'
                }}
                className='homepage-intest-photo'>
                <div className='hp-intest-photodesktop'>
                    <div className='container-fluid'>

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% + 560px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% + 500px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% + 440px) 170px no-repeat,

                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% - 485px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% - 420px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% - 360px) 170px no-repeat
                            `
                        }} className='intest-bandiere' />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/shadow-3-2@1x.png'}) calc(50% + 470px) 100px no-repeat, 
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/shadow-3-3@1x.png'}) calc(50% - 470px) 100px no-repeat
                            `
                        }} className="intest-shadow" />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/832-8326155-fog-1@1x.png'}) left bottom no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/832-8326155-fog-2@1x.png'}) right bottom no-repeat 
                            `
                        }} className="intest-fog-image" />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/lorenzo-1@1x.png'}) calc(50% + 260px) 60px no-repeat, 
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/marco-png-1@1x.png'}) calc(50% - 260px) 60px no-repeat
                        `
                        }} className="intest-goats" />

                        <div style={{
                            background: `url(${process.env.PUBLIC_URL + '/assets/img/fotohome/omar-front-1@1x.png'}) center 30px no-repeat`,
                        }} className='intest-goat-omar' />

                        <div className='intest-fog' />
                    </div>
                    {/* <h1 className='intest-h1' dangerouslySetInnerHTML={{ __html: testi('welcome_h1') }} ></h1> */}
                    <div className={'intest-div ' + (!MINT_OPENED ? 'closed' : null)}>
                        <BtnMintHome available_supply={this.state.available_supply} />
                    </div>
                </div>
                <div className='hp-intest-photomobile'>
                    <img src="assets/img/fotohome/fotohome_mobile.png" />

                    <div className={'intest-div intestmobile-div ' + (!MINT_OPENED ? 'closed' : null)}>
                        <BtnMintHome available_supply={this.state.available_supply} />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeFoto;