import React, { Component } from 'react';

import HeaderGoat from '../../../../components/ui/HeaderGoat';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';

import { Reti } from '../../../../components/wallet_generic/Wallets';
import { EndPointMethod, RequestBackend } from '../../../../components/area_riservata/backend/RequestBackend';

import AffiliateBonusText from '../../../../components/area_riservata/affiliate/ui/AffiliateBonusText';
import AffiliateBonusBtnPay from '../../../../components/area_riservata/affiliate/ui/AffiliateBonusBtnPay';
import AffiliateProfitBadge, { AffiliateProfitBadgeEnum } from '../../../../components/area_riservata/affiliate/ui/AffiliateProfitBadge';

export class AdminAffiliateBonus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'bonus_list': null
        };
        this.setAddress = this.setAddress.bind(this);
    }

    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.AFFILIATE_ADMIN_BONUS);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const response_data = response.data;
            this.setState({
                'bonus_list': response_data.bonus_list
            });
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-affiliate-bonus' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Affiliate Bonus</h3>

                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th>Rewards</th>
                                                    <th>Insert Date</th>
                                                    <th>Payment Date</th>
                                                    <th>Status</th>
                                                    <th>Pay</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.bonus_list && Array.from(this.state.bonus_list).length > 0 ? Array.from(this.state.bonus_list).map((profit, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="Name" className='cell-left'><AffiliateBonusText tipo={profit.tipo} /></td>
                                                            <td data-label="Address" className='cell-left'>
                                                                <input value={profit.address} className='input-table-readonly' readOnly style={{ width: 230 }} />
                                                            </td>
                                                            <td data-label="Rewards" className='cell-left greenTdValue'>+ {profit.value} USD</td>
                                                            <td data-label="Insert Date" className='cell-left'>{profit.insertDate}</td>
                                                            <td data-label="Payment Date" className='cell-left'>{profit.payDate}</td>
                                                            <td data-label="Status"><AffiliateProfitBadge badge={profit.status} /></td>
                                                            <td data-label="Pay"><AffiliateBonusBtnPay admin={this.state.menu_address} amount={profit.value} disabled={profit.status != AffiliateProfitBadgeEnum.PENDING} address={profit.address} idBonus={profit.id} /></td>
                                                        </tr>
                                                    )
                                                }, this) : null}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminAffiliateBonus;