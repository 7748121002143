import forceLogout from "../../helpers/forceLogout";
import { showError } from "../ui/ToastErrore";
import { ChangeChainRedirect } from "./helpers/changeChainRedirect";
import getMetamaskProvider, { getCoinbaseProvider } from "./helpers/getProvider";
import { isChainCorrect } from "./helpers/isChainCorrect";
import { Reti, Wallets } from "./Wallets";
import { getCurrentChain } from './helpers/getCurrentChain';
import { isLoggedBackend } from '../area_riservata/backend/helpers/isLoggedBackend';
import { isDev } from "./helpers/isDev";
import { getChainParameters } from "./helpers/getChainParameters";
import { switchChain } from "./helpers/switchChain";

export class ConnectorWallet {
    constructor(provider, rete) {
        this.state = {
            'installed': false,
            'rete': rete,
            'provider': provider
        };
        this.state.installed = this.checkIsInstalled();
    }

    checkIsInstalled() {
        const provider = this.state.provider;
        if (provider == Wallets.METAMASK) {
            if (getMetamaskProvider() == null) {
                return false;
            }
        } else if (provider == Wallets.COINBASE) {
            if (getCoinbaseProvider() == null) {
                return false;
            }
        }
        return true;
    }

    async connect() {

        // La connessione primaria al wallet si fa con la rete Ethereum
        this.state.rete = Reti.ETHEREUM;
        const provider = this.state.provider;

        if (!this.state.installed) {
            showError({ 'titolo': 'Wallet connection', 'testo': 'Please install first the ' + provider + ' wallet extension or use the mobile app' });
            return false;
        }

        const _this = this;
        let ethereum;
        if (provider == Wallets.METAMASK) {
            ethereum = getMetamaskProvider();
        } else if (provider == Wallets.COINBASE) {
            ethereum = getCoinbaseProvider();
        }

        ethereum.request({ method: 'eth_requestAccounts' }).then(res => {
            if (res[0]) {
                ethereum.request({ method: 'eth_chainId' }).then(async (_chainId) => {
                    if (!isChainCorrect(_chainId, _this.state.rete)) {
                        let title, description;
                        if (isDev()) {
                            title = 'Ethereum Rinkeby';
                            description = 'In modalità sviluppo utilizzare la rete di test Rinkeby';
                        } else {
                            title = 'Ethereum Main net';
                            description = 'Goat Nation works only on the ETH main chain.';
                        }
                        showError({ 'titolo': title, 'testo': description });
                        
                        // Propongo lo switch
                        const params = getChainParameters(_this.state.rete);
                        const response = await switchChain(ethereum, params.chainId, params);
                        if(response) {
                            localStorage.setItem("wallet_connected", provider);
                            window.location.href = "/account";
                        }
                    } else {
                        localStorage.setItem("wallet_connected", provider);

                        // Redirect dopo il login
                        if(localStorage.getItem("redirect_login") && localStorage.getItem("redirect_login") == "/mint") {
                            localStorage.removeItem("redirect_login");
                            window.location.href = "/mint";
                        } else {
                            window.location.href = "/account";
                        }
                    }
                });
            }
        }, reason => {
            showError({ 'titolo': 'Wallet connection', 'testo': 'Please open and check the ' + provider + ' extension.' });
        });
    }

    async listenIfConnected(skip_redirect_login) {
        if (!this.state.installed) {
            return false;
        }
        if (!localStorage.getItem("wallet_connected")) {
            return false;
        }
        const _this = this;

        const provider = this.state.provider;
        let connector;
        if (provider == Wallets.METAMASK) {
            connector = getMetamaskProvider();
        } else if (provider == Wallets.COINBASE) {
            connector = getCoinbaseProvider();
        }

        // Devo fare una gara con un'altra Promise per dare un timeout alla richiesta
        // Questo mi risolve il bug che quando apri il browser la prima volta Metamask è in attesa del unlock con la password
        // Dopo 15 secondi di attesa faccio il logout
        const promiseTimeout = new Promise((resolve, reject) => {
            setTimeout(resolve, 15000, 'isTimeouted');
        });
        const accountsRequest = connector.request({ method: 'eth_requestAccounts' }).catch(() => { return null; });

        // Inizio la gara tra timeout e request
        const accounts = await Promise.race([promiseTimeout, accountsRequest]);
        const isTimeouted = accounts == "isTimeouted";
        if (isTimeouted) {
            // Tempo scaduto
            _this.walletDisconnect();
            return;
        }
        
        if (accounts && accounts[0]) {
            
            const _chainId = await getCurrentChain(connector);
            if (!isChainCorrect(_chainId, this.state.rete)) {
                ChangeChainRedirect(this.state.rete);
                return "redirect";
            }
            if (this.state.rete == Reti.BINANCE && !skip_redirect_login) {
                const isLoggedEndpoint = isLoggedBackend();
                if (!isLoggedEndpoint) {
                    _this.walletRedirectToLogin();
                    return "redirect";
                }
            }

            // Ascolto un cambio di account o una disconnessione
            connector.on('accountsChanged', function (accounts) {
                if (accounts[0]) {
                    window.location.reload();
                } else {
                    _this.walletDisconnect();
                }
            });
            
            // Ascolto il cambio di rete
            connector.on('chainChanged', function (_chainId) {
                if (!isChainCorrect(_chainId, _this.state.rete)) {
                    ChangeChainRedirect(_this.state.rete);
                }
            });

            return accounts[0];
        } else {
            _this.walletDisconnect();
        }
        return null;
    }

    walletDisconnect() {
        forceLogout();
    }

    walletRedirectToLogin() {
        window.location.href = "/verify-wallet?redirect=" + window.location.pathname;
    }
}