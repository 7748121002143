import React, { Component } from 'react';

export const AffiliateBonusBadgeEnum = {
    REFERRAL_GOAL_25: 'REFERRAL_GOAL_25'
};

export const AffiliateBonusBadgeText = {
    REFERRAL_GOAL_25: 'GOAL 25 Goat',
};


export class AffiliateBonusText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'tipo': props.tipo
        }
    }
    render() {
        return (<b>{AffiliateBonusBadgeText[this.state.tipo]}</b>);
    }
}

export default AffiliateBonusText;