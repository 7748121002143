import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight, faCheck, faClose, faSpinner, faWallet} from '@fortawesome/free-solid-svg-icons'
import {showError} from '../../../ui/ToastErrore';
import {EndPointMethod, RequestBackend} from '../../backend/RequestBackend';

export class ModalAdminInsertTrans extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menu_address: '',
            mostra_modal: false,
            valori_calcolati: false,
            loading_carica: false,
            loading_conferma: false,

            idProfittoMaster: '',
            totalValue: '',
            totalPAMM: '',
            totalPercentage: ''
        };

        this.calcola = this.calcola.bind(this);
        this.conferma = this.conferma.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.impostaModal != this.props.impostaModal) {
            this.setState({
                menu_address: this.props.address,
                mostra_modal: this.props.impostaModal,
                se_valori_calcolati: false,
                valori_calcolati: [],
                loading_carica: false,
                loading_conferma: false,

                idProfittoMaster: '',
                totalValue: '',
                totalPAMM: '',
                totalPercentage: ''
            });

            // Svuoto i valori
            document.getElementById('value-bronze').value = "";
            document.getElementById('value-silver').value = "";
            document.getElementById('value-gold').value = "";
        }
    }

    async calcola() {

        if (this.state.loading_carica) {
            return false;
        }

        if (document.getElementById('value-bronze').value == "" ||
            document.getElementById('value-silver').value == "" ||
            document.getElementById('value-gold').value == "") {
            showError({'titolo': 'Errore', 'testo': 'Inserire prima le 3 percentauli'});
            return false;
        }

        this.setState({loading_carica: true});

        const licenses_percent = {
            'BRONZE': document.getElementById('value-bronze').value,
            'SILVER': document.getElementById('value-silver').value,
            'GOLD': document.getElementById('value-gold').value,
        };

        const request = new RequestBackend(this.state.menu_address, EndPointMethod.GOATECH_ADMIN_PREPARE_DISTRIB, {
            licenses_percent: JSON.stringify(licenses_percent)
        });
        const response = await request.run();
        if (request.isResponseError()) {
            if (response["error"]) {
                showError({'titolo': 'Errore', 'testo': response["error"]});
                return false;
            }
            showError({'titolo': 'Errore', 'testo': 'Errore nell\'approvazione ricompensa'});
            return false;
        }

        const data_response = response["data"];
        const valori_calcolati = [];
        for (const profitLevel of data_response["licenses"]) {
            valori_calcolati[profitLevel.level] = profitLevel;
        }
        this.setState({
            loading_carica: false,
            se_valori_calcolati: true,
            valori_calcolati: valori_calcolati,
            idProfittoMaster: data_response["idProfittoMaster"],
            totalValue: data_response["totalValue"],
            totalPAMM: data_response["totalPAMM"],
            totalPercentage: data_response["totalPercentage"],
        });
    }

    async conferma() {
        if (this.state.idProfittoMaster == '') {
            return false;
        }
        if (!window.confirm("Sei sicuro di voler inserire questa ricompensa?")) {
            return false;
        }
        const request = new RequestBackend(this.state.menu_address, EndPointMethod.GOATECH_ADMIN_APPROVE_DISTRIB, {
            profittoMasterId: this.state.idProfittoMaster
        });
        const response = await request.run();

        if (request.isResponseError()) {
            if (response["error"]) {
                showError({'titolo': 'Errore', 'testo': response["error"]});
                return false;
            }
            showError({'titolo': 'Errore', 'testo': 'Errore nell\'approvazione ricompensa'});
            return false;
        }

        showError({'titolo': 'Success!', 'testo': 'La ricompensa è stata inserita correttamente'});
        window.setTimeout(function () {
            window.location.reload();
        }, 1200);
    }

    PercentualeCalcolataLicense(props) {
        return (
            <p>
                <span>Da inviare: $ {props.value}</span>
                <span>Totale: $ {props.totalPAMM_format}</span>
                <span><FontAwesomeIcon alt='Numero di Wallet Unici'
                                       icon={faWallet}/>&nbsp;&nbsp;&nbsp;{props.wallets}</span>
            </p>
        );
    }

    render() {
        return (
            <div className={'modal-overlay modal-overlay-inframe' + (this.state.mostra_modal ? ' modal-show' : '')}>
                <div className='modal'>
                    <a onClick={() => this.props.toggleModal()} className='modal-close'>
                        <FontAwesomeIcon icon={faClose}/>
                    </a>
                    <form className='form-profit-insert'>
                        <h4>Inserisci ricompensa</h4>
                        <p style={{textAlign: 'center'}}>
                            Inserire le percentuali per calcolare il valore in USD da inviare allo smart contract.
                        </p>
                        <div className={'grid-three-column nopadding'}>
                            <div className={'column'}>
                                <label>Bronze license</label>
                                <input className={'value-input'} id={'value-bronze'}
                                       type={'number'} step="any" min={0}
                                       placeholder="es. 4" required/> %

                                {this.state.se_valori_calcolati ?
                                    this.PercentualeCalcolataLicense(this.state.valori_calcolati['BRONZE'])
                                    : null}
                            </div>
                            <div className={'column'}>
                                <label>Silver license</label>
                                <input className={'value-input'} id={'value-silver'}
                                       type={'number'} step="any" min={0}
                                       placeholder="es. 5" required/> %

                                {this.state.se_valori_calcolati ?
                                    this.PercentualeCalcolataLicense(this.state.valori_calcolati['SILVER'])
                                    : null}
                            </div>
                            <div className={'column'}>
                                <label>Gold license</label>
                                <input className={'value-input'} id={'value-gold'}
                                       type={'number'} step="any" min={0}
                                       placeholder="es. 6" required/> %

                                {this.state.se_valori_calcolati ?
                                    this.PercentualeCalcolataLicense(this.state.valori_calcolati['GOLD'])
                                    : null}
                            </div>
                        </div>

                        {this.state.se_valori_calcolati ? (
                            <p style={{textAlign: 'center'}}>
                                <span style={{fontWeight: 'bold', marginBottom: 10, display: 'block', fontSize: 18}}>Totale: $ {this.state.totalValue}</span>
                                Goatech Totale: $ {this.state.totalPAMM}<br/>
                                Totale percentuale: {this.state.totalPercentage}%<br/>
                            </p>
                        ) : null}

                        <p style={{textAlign: 'center', marginTop: 30}}>
                            <button className="btn-block-orange white" onClick={(e) => {
                                e.preventDefault();
                                this.calcola();
                            }} type="submit">
                                Calcola {!this.state.loading_carica ?
                                <span><FontAwesomeIcon icon={faAngleRight}/></span> :
                                <span><FontAwesomeIcon spin icon={faSpinner}/></span>
                            }
                            </button>

                            {this.state.se_valori_calcolati ? (
                                <button style={{display: 'block', margin: '20px auto'}} className="btn-block-orange" onClick={(e) => {
                                    e.preventDefault();
                                    this.conferma();
                                }} type="submit">
                                    Conferma {!this.state.loading_conferma ?
                                    <span><FontAwesomeIcon icon={faCheck}/></span> :
                                    <span><FontAwesomeIcon spin icon={faSpinner}/></span>
                                }
                                </button>
                            ) : null}
                        </p>
                    </form>
                </div>
            </div>
        );
    }
}

export default ModalAdminInsertTrans;