import React, {Component} from 'react';
import {EndPointMethod, RequestBackend} from "../../../../components/area_riservata/backend/RequestBackend";
import HeaderGoat from "../../../../components/ui/HeaderGoat";
import {Reti} from "../../../../components/wallet_generic/Wallets";
import MenuArea from "../../../../components/area_riservata/ui/Menu";
import StatsValue from "../../../../components/area_riservata/goatech/ui/StatsValue";
import moment from "moment/moment";
import {StatsBadge, StatsBadgeEnum, StatsBadgeText} from "../../../../components/area_riservata/goatech/ui/StatsBadge";

export class AdminGoatechBalleList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'listBalle': []
        };
        this.setAddress = this.setAddress.bind(this);
    }

    setAddress(address) {
        this.setState({'menu_address': address});
        this.loadListBalle(address);
    }

    async loadListBalle(address) {
        const request = new RequestBackend(address, EndPointMethod.GOATECH_ADMIN_GETLIST_BALLE);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const listaBalle = response["data"]["listaBalle"];
            this.setState({'listBalle': listaBalle});
            document.getElementById("listaballe-totale").innerText = "Total Balle: " + listaBalle.length + " - Total missing: $ " + response["data"]["totalMissing"];
        }

        document.getElementById('filtra-cerca').addEventListener("click", function () {
            const filtro_address = document.getElementById("filtra-address").value;
            const filtro_stato = document.getElementById("filtra-stato").value;

            const table = document.getElementsByClassName('goatech-stats-table')[0];
            const listTr = Array.from(table.querySelectorAll('tbody > tr'));
            listTr.forEach(function (el) {
                el.style.display = 'table-row';
            });

            let totale_balle = 0;
            let totale_calcolato = 0;
            listTr.forEach(function (el) {
                if (filtro_address != "" && el.getAttribute("data-address").toLowerCase() != filtro_address.toLowerCase()) {
                    el.style.display = 'none';
                    return;
                }
                if (filtro_stato != "" && el.getAttribute("data-stato") != filtro_stato) {
                    el.style.display = 'none';
                    return;
                }
                totale_calcolato += parseFloat(el.getAttribute("data-valuemiss"));
                totale_balle++;
            });

            document.getElementById("listaballe-calcolato").innerText = 'In base alla ricerca effettuata: totale balle: ' + totale_balle + ' - totale missing: $' + totale_calcolato.toLocaleString();
        });
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress}
                            checkLogged="true"/>

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-goatech-list-balle'
                                          address={this.state.menu_address}/>
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Lista balle</h3>
                                    <p>Include tutte le balle tranne quelle in Burned.</p>

                                    <div className={'filtri-balle'}>
                                        <div className={'riga'}>
                                            <label>Address: <input type={'text'} id={'filtra-address'}/></label>
                                        </div>
                                        <div className={'riga'}>
                                            <label>
                                                Stato:&nbsp;
                                            </label>
                                            <select type={'text'} id={'filtra-stato'}>
                                                <option value={""}>--</option>
                                                {Object.keys(StatsBadgeEnum).map((stato, stato1) => {
                                                    return (<option key={stato1}
                                                                    value={stato}>{StatsBadgeText[stato]}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className={'riga'}>
                                            <a className={'btn-block-orange'} id={'filtra-cerca'}>Cerca</a>
                                        </div>
                                    </div>
                                    <p id={"listaballe-calcolato"}></p>
                                    <p id={"listaballe-totale"}></p>

                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                            <tr>
                                                <th className='cell-left'>Fieno #</th>
                                                <th className='cell-left'>Address</th>
                                                <th className='cell-left'>Total Rewards</th>
                                                <th className='cell-left'>Recovery Percent</th>
                                                <th className='cell-left'>Value Missing</th>
                                                <th className='cell-left'>Buy date</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.listBalle ? Array.from(this.state.listBalle).map((balla, i) => {
                                                return (
                                                    <tr data-address={balla.address} data-valuemiss={balla.valueMissing}
                                                        data-stato={balla.state} key={i}>
                                                        <td data-label="Fieno #" className='cell-left'>{balla.id}</td>
                                                        <td data-label="Address"
                                                            className='cell-left'>{balla.address}</td>
                                                        <td data-label="Total Rewards"
                                                            className='cell-left'>$ {balla.totalProfit}</td>
                                                        <td data-label="Recovery Percent"
                                                            className='cell-left'>{balla.percentTotalProfit} %
                                                        </td>
                                                        <td data-label="Value Missing"
                                                            className='cell-left'>$ {balla.valueMissing}</td>
                                                        <td data-label="Buy date"
                                                            className='cell-left'>{balla.buyDate}</td>
                                                        <td data-label="Status" className='cell-left'><StatsBadge
                                                            badge={StatsBadgeEnum[balla.state]}/></td>
                                                    </tr>
                                                )
                                            }, this) : null}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div
                            style={{background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain`}}
                            className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminGoatechBalleList;