import React, {Component} from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';
import {Reti} from '../../../components/wallet_generic/Wallets.js';
import {EndPointMethod, RequestBackend} from "../../../components/area_riservata/backend/RequestBackend";
import {TipiContenuto} from "../../../components/area_riservata/academy/TipiContenuto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import AcademyBtnMobile from "../../../components/area_riservata/academy/ui/AcademyBtnMobile";

function BoxThumbnail(alias_url, props) {
    return (
        <a href={'/academy-view-content?c=' + alias_url + '&lesson_id=' + props._id}>
            <div className={'academy-boxthumb' + ' ' + (props.active == true ? 'academy-boxthumb-active' : '')}>
                <div className={'academy-boxthumb-video'}>
                    <img src={props.thumbnail}/>
                </div>
                <div className={'academy-boxthumb-text'}>
                    <p className={'boxthumb-text-title'}>{props.lesson != "" ? props.lesson + " - " : ""}{props.title}</p>
                    <p className={'boxthumb-text-time'}>{props.time}</p>
                </div>
                <div style={{clear: 'both'}}></div>
            </div>
        </a>
    );
}

export class AcademyViewContent extends Component {

    constructor(props) {
        super(props);

        const url_params = new URLSearchParams(window.location.search);
        const alias_url = url_params.get("c") ? url_params.get("c") : "";
        if (alias_url == "") {
            window.location.href = "/";
            return false;
        }
        const lesson_id = url_params.get("lesson_id") ? url_params.get("lesson_id") : "";

        this.state = {
            'menu_address': '',
            alias_url: alias_url,
            lesson_id: lesson_id,
            submenu: '',
            type: '',

            video_name: '',
            video_description: '',
            video_attachment: '',
            list_video: [],

            error_loading: ''
        };
        this.setAddress = this.setAddress.bind(this);
    }

    async setAddress(address) {
        this.setState({'menu_address': address});

        const request = new RequestBackend(address, EndPointMethod.ACADEMY_VIEW_CONTENT, {
            alias_url: this.state.alias_url,
            lesson_id: this.state.lesson_id
        });
        const response = await request.run();
        if(request.isResponseError()) {
            this.setState({
                error_loading: response.error
            })
            return;
        }
        if (request.getStatusCode() == 200) {
            const response_data = response.data;

            let submenu = "";
            if (response_data.type == TipiContenuto.COURSE) {
                submenu = "academy-courses";
            } else if (response_data.type == TipiContenuto.GROUP_LIVE) {
                submenu = "academy-live-recorded";
            }

            this.setState({
                submenu: submenu,
                type: response_data.type,
                content_name: response_data.content_name,
                video_name: response_data.video_name,
                video_description: response_data.video_description,
                video_attachment: response_data.video_attachment,
                video_url: response_data.video_url,
                video_lesson: response_data.video_lesson,
                list_video: response_data.list_lessons,
            });
        }
    }

    render() {
        setTimeout(function() {
            var video_selezionato = document.getElementsByClassName('academy-boxthumb-active');
            if (video_selezionato.length == 1) {
                var posizione_sel = video_selezionato[0].offsetTop;
                posizione_sel = posizione_sel - 180;
                document.getElementById('academy-view-list-video').scrollTop = posizione_sel;
            }
        }, 500);
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress}
                            checkLogged="true"/>

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='academy-area' subMenu={this.state.submenu}
                                          address={this.state.menu_address}/>
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding scroller scroller-stylized'>

                                    <AcademyBtnMobile />

                                    {this.state.type == TipiContenuto.COURSE ? (
                                        <h3>Goat Academy</h3>
                                    ) : null}
                                    {this.state.type == TipiContenuto.GROUP_LIVE ? (
                                        <h3>Goat Academy - Recordings</h3>
                                    ) : null}

                                    {this.state.error_loading != "" ? <p>{this.state.error_loading}</p> : null}

                                    <div className={'academy-view-box'}>
                                        <div className={'academy-view-box-video'}>
                                            <h3>{this.state.content_name}</h3>
                                            <iframe width="100%" height="340" src={this.state.video_url}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen>
                                            </iframe>
                                            <h2>{this.state.video_lesson != "" && this.state.video_lesson !== undefined ? this.state.video_lesson + " - " : ""}{this.state.video_name != "" && this.state.video_name !== undefined ? this.state.video_name : ""}</h2>
                                        </div>
                                        <div style={{overflowX: 'hidden'}} className={'academy-view-list-video'}>
                                            <div id={'academy-view-list-video'}
                                                 className={'academy-view-container-list-video scroller-stylized'}>
                                                <h3>Playlist Video</h3>

                                                {this.state.list_video && Array.from(this.state.list_video).length > 0 ? Array.from(this.state.list_video).map((content, i) => {
                                                    return BoxThumbnail(this.state.alias_url, content);
                                                }, this) : (
                                                    <p style={{margin: 10}}></p>
                                                )}
                                            </div>
                                        </div>

                                        <div style={{clear: 'both'}}></div>
                                    </div>

                                    <div style={{clear: 'both'}}></div>

                                    <div className={'academy-view-descr'}>
                                        <div className={'academy-view-text'}>
                                            {this.state.video_description != "" ? (
                                                <>
                                                    {this.state.type == TipiContenuto.COURSE ? (
                                                        <h3>Dettagli lezione</h3>
                                                    ) : null}
                                                    {this.state.type == TipiContenuto.GROUP_LIVE ? (
                                                        <h3>Dettagli della Live</h3>
                                                    ) : null}
                                                    {this.state.video_description}
                                                </>
                                            ) : null}
                                            &nbsp;
                                        </div>
                                        <div className={'academy-view-attachment'}>
                                            {this.state.video_attachment !== undefined && this.state.video_attachment != "" ? (
                                                <a className={'academy-btn-attachment'}
                                                   href={this.state.video_attachment} target={'_blank'}>
                                                    <div className={'btn-icon-left'}>
                                                        <FontAwesomeIcon icon={faDownload}/>
                                                    </div>
                                                    <div className={'btn-text-right'}>
                                                        Scarica allegati della live
                                                    </div>
                                                    <div style={{clear: 'both'}}></div>
                                                </a>
                                            ) : null}
                                        </div>
                                        <div style={{clear: 'both'}}></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div
                            style={{background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain`}}
                            className='account-logo-goat'/>
                    </div>
                </div>
            </div>
        );
    }
}

export default AcademyViewContent;