import React, { Component } from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import StatsTab from '../../../components/area_riservata/goatech/ui/StatsTab.js';
import GoatechBtnMobile from '../../../components/area_riservata/goatech/ui/BtnMobile';

import StatsValue from '../../../components/area_riservata/goatech/ui/StatsValue.js';
import { Reti } from '../../../components/wallet_generic/Wallets.js';
import { EndPointMethod, RequestBackend } from '../../../components/area_riservata/backend/RequestBackend.js';

import { PAMMGrafico } from '../../../components/area_riservata/goatech/ui/PAMMGrafico.js';

export class GoatechPAMMHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'errorLoading': '',
            'totalPAMMBalance': '',
            'holders': '',
            'totalMonth': '',

            'lastMonthValue': '',
            'lastMonthPercent': '',
            'prev3MonthPercent': '',
            'totalGainPercent': '',
            'listClosedPositions': [],
            'graphData': []
        };
        this.setAddress = this.setAddress.bind(this);
    }
    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.GOATECH_PAMM_HISTORY);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const data_response = response.data;
            if (response.status == "ok") {

                for(const kProfit in data_response.listClosedPositions) {
                    const levels = [];
                    for(const profitLevel of data_response.listClosedPositions[kProfit].levels) {
                        levels[profitLevel.level] = profitLevel;
                    }
                    data_response.listClosedPositions[kProfit].levels = levels;
                }

                this.setState({
                    'totalPAMMBalance': data_response.totalPAMMBalance,
                    'holders': data_response.holders,
                    'totalMonth': data_response.totalMonth,
                    'lastMonthValue': data_response.lastMonthValue,
                    'lastMonthPercent': data_response.lastMonthPercent,
                    'prev3MonthPercent': data_response.prev3MonthPercent,
                    'totalGainPercent': data_response.totalGainPercent,
                    'listClosedPositions': data_response.listClosedPositions,
                    'graphData': data_response.graph,
                });
            } else if (response.status == "ko") {
                this.setState({ 'errorLoading': response.error });
            }
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech' subMenu='pamm-history' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller-stylized scroller'>
                                    <GoatechBtnMobile />

                                    <h3>GOATECH History</h3>

                                    {this.state.errorLoading != "" ? this.state.errorLoading : null}

                                    <div style={this.state.errorLoading != "" ? { display: 'none' } : null} >
                                        <div className='goatech-stats-cols'>
                                            <div className='goatech-stats-col60'>
                                                <div className='goatech-stats-grafico'>
                                                    <p>Last Month Results <span style={{ float: 'right' }}>GOATECH % by Month</span></p>
                                                    <PAMMGrafico datagraph={this.state.graphData} />
                                                </div>
                                            </div>
                                            <div className='goatech-stats-col40'>
                                                <div className='goatech-stats-tabs'>
                                                    <StatsTab titolo="Total Balance" valore={this.state.totalPAMMBalance != "" ? "$ " + this.state.totalPAMMBalance : ''} />
                                                    <StatsTab titolo="Last Month" valore={this.state.lastMonthValue ? "$ " + this.state.lastMonthValue : ''} percent={this.state.lastMonthPercent} />
                                                    <StatsTab titolo="Last 3 Months" percent={this.state.prev3MonthPercent} />
                                                    <StatsTab titolo="Total Rewards" percent={this.state.totalGainPercent} />
                                                    <StatsTab titolo="Total Goatech Months" valore={this.state.totalMonth != "" ? this.state.totalMonth : ''} />
                                                    <StatsTab titolo="Actual Holders" valore={this.state.holders != "" ? this.state.holders : ''} />
                                                </div>
                                            </div>
                                        </div>


                                        <h3>Last closed positions</h3>
                                        <div className='goatech-stats-table-wrapper'>
                                            <table className="goatech-stats-table stats-table-left">
                                                <thead>
                                                    <tr>
                                                        <th>Symbol</th>
                                                        {/* <th style={{ width: 120 }}>Total Rew.</th> */}
                                                        <th style={{ width: 100 }}>Bronze</th>
                                                        <th style={{ width: 100 }}>Silver</th>
                                                        <th style={{ width: 100 }}>Gold</th>
                                                        <th>Enter Date</th>
                                                        <th>Exit Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listClosedPositions ? Array.from(this.state.listClosedPositions).map((profitto, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td data-label="Symbol" className='cell-left'>EUR/USDC</td>
                                                                {/* <td data-label="Total Rew."><StatsValue valore={profitto.gain} /></td> */}
                                                                <td data-label="Bronze" className='cell-left'>{profitto.levels["BRONZE"] ? (<StatsValue valore={profitto.levels["BRONZE"].gain} />) : null}</td>
                                                                <td data-label="Silver" className='cell-left'>{profitto.levels["SILVER"] ? (<StatsValue valore={profitto.levels["SILVER"].gain} />) : null}</td>
                                                                <td data-label="Gold" className='cell-left'>{profitto.levels["GOLD"] ? (<StatsValue valore={profitto.levels["GOLD"].gain} />) : null}</td>
                                                                <td data-label="Enter date" className='cell-left'>{profitto.enterDate}</td>
                                                                <td data-label="Exit date" className='cell-left'>{profitto.exitDate}</td>
                                                            </tr>
                                                        )
                                                    }, this) : (
                                                        <tr>
                                                            <td colSpan="6" className='cell-left-important'>Nothing yet to show</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatechPAMMHistory;