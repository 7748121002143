import React, {Component} from 'react';
import {EndPointMethod, RequestBackend} from "../../../../components/area_riservata/backend/RequestBackend";
import HeaderGoat from "../../../../components/ui/HeaderGoat";
import {Reti} from "../../../../components/wallet_generic/Wallets";
import MenuArea from "../../../../components/area_riservata/ui/Menu";
import GoatechBtnSetAddressReached from "../../../../components/area_riservata/goatech/ui/GoatechBtnSetAddressReached";

export class AdminGoatechWalletList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'listWallet': []
        };
        this.setAddress = this.setAddress.bind(this);
    }

    setAddress(address) {
        this.setState({'menu_address': address});
        this.loadListWallet(address);
    }

    async loadListWallet(address) {
        const request = new RequestBackend(address, EndPointMethod.GOATECH_ADMIN_GETLIST_WALLET);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const listaWallet = response["data"]["listaWallet"];
            this.setState({'listWallet': listaWallet});
            document.getElementById("listawallet-totale").innerText = "Total Wallet: "+ listaWallet.length + " - Total missing: $ " + response["data"]["totalMissing"];
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress}
                            checkLogged="true"/>

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-goatech-list-wallet'
                                          address={this.state.menu_address}/>
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Lista wallet</h3>
                                    <p>Che hanno balle escludendo quelle in Burned.</p>
                                    <p id={"listawallet-totale"}></p>

                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Address</th>
                                                    <th className='cell-left'>Fieno Quantity</th>
                                                    <th className='cell-left'>Balance</th>
                                                    <th className='cell-left'>Profit</th>
                                                    <th className='cell-left'>Recovery Percent</th>
                                                    <th className='cell-left'>Value Missing</th>
                                                    <th className='cell-left'>Data Last Profit</th>
                                                    <th width={100}>Imposta balle come profitto raggiunto</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.listWallet ? Array.from(this.state.listWallet).map((wallet, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td data-label="Address" className='cell-left'>{wallet.address}</td>
                                                        <td data-label="Fieno Quantity" className='cell-left'>{wallet.fienoQuantity}</td>
                                                        <td data-label="Balance" className='cell-left'>$ {wallet.valueInvested}</td>
                                                        <td data-label="Profit" className='cell-left'>$ {wallet.valueTotalProfit}</td>
                                                        <td data-label="Recovery Percent" className='cell-left'>{wallet.percentageTotalProfit} %</td>
                                                        <td data-label="Value Missing" className='cell-left'>$ {wallet.valueMissing}</td>
                                                        <td data-label="Data last profit" className='cell-left'>{wallet.dataLastProfit}</td>
                                                        <td width={100} data-label="Imposta balle come profitto raggiunto" className='cell-left'><GoatechBtnSetAddressReached admin_address={this.state.menu_address} address={wallet.address} disabled={wallet.percentageTotalProfit >= 100} /></td>
                                                    </tr>
                                                )
                                            }, this) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div
                            style={{background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain`}}
                            className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminGoatechWalletList;