import React from 'react';

import HeaderGoat from '../components/ui/HeaderGoat.js';
import HomeFoto from '../components/home/section/FotoHead.js';
// import HomeVideo from '../components/home/Video.js';
import HomeGoatFromDay0 from '../components/home/section/GoatFromDay0.js';
import HomeGoatMind from '../components/home/section/GoatMind.js';
import HomeGoatArt from '../components/home/section/GoatArt.js';
import HomeGoatTeam from '../components/home/section/GoatTeam.js';
import HomeGoatRoadMap from '../components/home/section/GoatRoadMap.js';
import HomeGoatNFTLevels from '../components/home/section/GoatNFTLevels.js';
import HomeGoatNFTLevelsTable from '../components/home/section/GoatNFTLevelsTable.js';
import GoatRoadMapInteractive from "../components/home/section/GoatRoadMapInteractive";
import HomeGoatech from '../components/home/section/Goatech.js';
import HomeAltriServizi from '../components/home/section/GoatAltriServizi.js';
import HomeCommunity from '../components/home/section/GoatCommunity.js';
import HomeGoatChart from '../components/home/section/GoatChart.js';
import HomeGoateSim from '../components/home/section/GoatESim.js';

function Home() {
    return (
        <div className="home">
            <HeaderGoat className="header-black"></HeaderGoat>
            <HomeFoto/>
            {/* <HomeVideo /> */}
            <HomeGoatFromDay0/>
            {/* <HomeGoatNation/> */}
            <GoatRoadMapInteractive/>
            <HomeGoatRoadMap/>
            <HomeGoatNFTLevels/>
            <HomeGoatNFTLevelsTable/>
            {/* <HomeGoatech/> */}
            <HomeGoatChart/>
            <HomeGoateSim/>
            <HomeAltriServizi/>
            {/* <HomeCommunity/> */}
            {/* <HomeGoatToMars/>
            <HomeGoatAI/>
            <HomeGOATREX/>
            <HomeGoatEstate/>
            <HomeGoatAlphagoat/>*/}
            {/* <HomeGoatMind/> */}
            {/* <HomeGoatACADEMY/> */}
            <HomeGoatArt/>
            <HomeGoatTeam/>
        </div>
    );
}

export default Home;