import React, { Component } from 'react';
import { getWeb3Provider } from '../../../binance/helpers/getWeb3Provider';
import { getWeb3ProviderOLD } from '../../../binance/helpers/getWeb3ProviderOLDContract';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { showError } from '../../../ui/ToastErrore';

export class GoatechRequestBurn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'tokenId': this.props.tokenId ? this.props.tokenId : null,
            'disabled': this.props.disabled ? true : false,
            'loadingBurn': false,
            'percentagePenalBurn': this.props.percentagePenalBurn ? this.props.percentagePenalBurn : 0
        };
        this.requestBurnBtn = this.requestBurnBtn.bind(this);
    }

    requestBurnBtn() {
        if (!this.state.disabled && this.state.tokenId) {
            this.requestBurn();
        }
    }

    async requestBurn() {

        showError({ 'titolo': 'Burn non disponibile', 'testo': 'Funzione momentaneamente non disponibile' });
        return;

        let provider;
        if (parseInt(this.state.tokenId) <= 408) {
            provider = await getWeb3ProviderOLD(true);
        } else {
            provider = await getWeb3Provider(true);
        }
        const contract = provider.contract;

        // Richiesta di burn al contratto
        this.setState({ loadingBurn: true });

        try {
            const request = await contract.requestBurn(this.state.tokenId);

            showError({ 'titolo': 'Burn request in progress', 'testo': 'Awaiting confirmation of the transaction...' });

            await request.wait().then((res) => {
                showError({ 'titolo': 'Success!', 'testo': 'The burn request was sent! The bale will be updated within a few minutes' });
                this.setState({ disabled: true });

                // document.getElementById('statsbadge' + this.props.keyId).innerHTML = StatsBadgeText.REQ_BURN;
                // document.getElementById('statsbadge' + this.props.keyId).className = 'stats-badge ' + StatsBadgeClass.REQ_BURN;

                return true;
            }, error => {
                showError({ 'titolo': 'Error :(', 'testo': 'An error occurred in the request ' + (error.message ? ": " + error.message : '') });
                return false;
            }).catch((error) => {
                showError({ 'titolo': 'Error :(', 'testo': 'An error occurred in the request ' + (error.message ? ": " + error.message : '') });
                return false;
            });
        } catch (error) {
            showError({ 'titolo': 'Error :(', 'testo': 'An error occurred in the request ' + (error.message ? ": " + error.message : '') });
        }

        this.setState({ loadingBurn: false });
    }

    render() {
        const percentPenalBurn = this.state.percentagePenalBurn;
        return (<span onClick={() => this.requestBurnBtn()} className={'burn-btn ' + (this.state.disabled || this.state.loadingBurn ? 'disabled' : '')}>
            {percentPenalBurn === 0 ? "Free" : percentPenalBurn + "%"} Burn {this.state.loadingBurn ? <>&nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} spin /></> : ''}
        </span>);
    }
}

export default GoatechRequestBurn;