import React, { Component } from 'react';

export class StatsTab extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div className={'goatech-stats-tab ' + (this.props.className ? this.props.className : "")}>
            <div className='stats-tab-box'>
                <p className='stats-tab-title'>{this.props.titolo}</p>
                <p className='stats-tab-valore'>
                    {this.props.valore}
                    {this.props.valore != "" ? (<span>&nbsp;&nbsp;</span>) : ""}
                    {this.props.percentgreen && this.props.percentgreen != "" ? (<span className='stats-tab-percentgreen'>(+{this.props.percentgreen}%)</span>) : ''}
                    {this.props.percentred && this.props.percentred != "" ? (<span className='stats-tab-percentred'>(-{this.props.percentred}%)</span>) : ''}
                    {this.props.percent && this.props.percent != "" ? (
                        (this.props.percent < 0 ? (<span className='stats-tab-percentred'>(-{this.props.percent}%)</span>) : <span className='stats-tab-percentgreen'>(+{this.props.percent}%)</span>)
                    ) : null}
                </p>
            </div>
        </div>);
    }
}

export default StatsTab;