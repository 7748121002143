import React, { Component } from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import { Reti } from '../../../components/wallet_generic/Wallets.js';
import { EndPointMethod, RequestBackend } from '../../../components/area_riservata/backend/RequestBackend.js';
import StatsTab from '../../../components/area_riservata/goatech/ui/StatsTab.js';
import AffiliateProfitBadge from '../../../components/area_riservata/affiliate/ui/AffiliateProfitBadge.js';

export class AffiliateCommissions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'total_referrals': 0,
            'total_commissionsPayed': 0,
            'commissions_list': null
        };
        this.setAddress = this.setAddress.bind(this);
    }

    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.AFFILIATE_COMMISSIONS_PAGE);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const response_data = response.data;
            this.setState({
                'total_referrals': response_data.total_referrals,
                'total_commissionsPayed': response_data.total_commissionsPayed,
                'commissions_list': response_data.commissions_list
            });
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='affiliate-area' subMenu='affiliate-commissions' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Commissions</h3>

                                    <div className='goatech-stats-cols'>
                                        <div className='goatech-stats-col1'>
                                            <div className='goatech-stats-tabs affiliate-commissions-tab'>
                                                <StatsTab titolo="Total Referrals" valore={this.state.total_referrals} />
                                                <StatsTab titolo="Total paid commissions" className='greenValue' valore={`${this.state.total_commissionsPayed} ETH`} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 10 }} className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th width="100px" className='cell-left'>NFT ID</th>
                                                    <th>Commissions</th>
                                                    <th>Buy Date</th>
                                                    <th>Payment Date</th>
                                                    <th>Status</th>
                                                    <th>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.commissions_list && Array.from(this.state.commissions_list).length > 0 ? Array.from(this.state.commissions_list).map((commission, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="NFT ID" className='cell-left'>{commission.tokenId}</td>
                                                            <td data-label="Commissions" className='cell-left greenTdValue'>+ {commission.value} ETH</td>
                                                            <td data-label="Buy Date" className='cell-left'>{commission.buyDate}</td>
                                                            <td data-label="Payment Date" className='cell-left'>{commission.payDate}</td>
                                                            <td data-label="Status" className='cell-left'><AffiliateProfitBadge badge={commission.status} /></td>
                                                            <td data-label="Address" className='cell-left'>{commission.addressMintedToken}</td>
                                                        </tr>
                                                    )
                                                }, this) : (
                                                    <tr>
                                                        <td colSpan="6" className='cell-left-important'>Nothing yet to show</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AffiliateCommissions;