import { showError } from "../../ui/ToastErrore";
import { getSelectedProvider } from "./getProvider";

export async function switchChain(provider, chainId, params) {
    if (provider == null) {
        provider = getSelectedProvider();
    }
    const response = await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ 
            chainId: chainId 
        }]
    }).then(() => {
        return true;
    }, error => {
        if (error.code === 4902 || error.code === -32603) {
            const parameters = params;
            delete parameters.nome;

            provider.request({
                method: 'wallet_addEthereumChain',
                params: [parameters]
            }).then(() => {
                return switchChain(chainId, params);
            }, error => {
                showError({ 'titolo': 'Adding network', 'testo': 'A problem occurred with the addition of the network ' + params.nome + '. Please try manually' });
            });
            return false;
        }
        showError({ 'titolo': 'Network switch', 'testo': 'A problem occurred with the network switch. Please try manually' });
        return false;
    });

    return response;
}