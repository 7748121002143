import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBars,
    faChevronDown,
    faChevronRight,
    faGear, faGraduationCap, faHandshake,
    faRightFromBracket,
    faRocket,
    faShoppingCart,
    faUser,
    faWallet,
    faXmark
} from '@fortawesome/free-solid-svg-icons'
import {faTwitter, faInstagram, faYoutube, faDiscord} from '@fortawesome/free-brands-svg-icons'

import {testi, lingua_sito} from "../../language/lang.js";
import ModalConnectWallet from "./ModalConnectWallet.js";

import forceLogout from '../../helpers/forceLogout.js';
import {Reti, Wallets} from '../wallet_generic/Wallets.js';
import {ConnectorWallet} from '../wallet_generic/ConnectorWallet.js';
import {MINT_OPENED} from "../../config/ConfigMint";

export class HomeSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menu_mobile_opened: false,
            menu_mobile_level: false,
            menu_mobile_nav: '',

            dropdown_show: false,
            dropdown_id: '',

            wallet_connected: false,
            modal_connect_wallet: false,

            rete: props.rete ? props.rete : Reti.ETHEREUM
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.mobileMenuOpenChild = this.mobileMenuOpenChild.bind(this);
        this.mobileMenuCloseChild = this.mobileMenuCloseChild.bind(this);
        this.setOffMenu = this.setOffMenu.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);

        // Wallet
        this.toggleModalWallet = this.toggleModalWallet.bind(this);
        this.state.wallet_connected = this.isWalletConnected();

        // Se è necessaria l'autenticazione per vedere la pagina
        if (this.props.checkLogged) {
            this.isLogged();
        }
    }

    isLogged() {

        // Se connesso devo ascoltare i cambi web3
        if (localStorage.getItem("wallet_connected")) {

            const wallet = localStorage.getItem("wallet_connected");

            let wallet_selected = null;
            if (wallet === "metamask") {
                wallet_selected = Wallets.METAMASK;
            } else if (wallet === "coinbase") {
                wallet_selected = Wallets.COINBASE;
            } else {
                forceLogout();
                return;
            }

            const connector = new ConnectorWallet(wallet_selected, this.state.rete);

            const skipRedirectLogout = this.props.skipRedirectLogin ? true : false;
            connector.listenIfConnected(skipRedirectLogout).then(address => {
                if (address) {
                    if (address != "redirect") { // Attendo il redirect
                        if (this.props.setAddress) {
                            this.props.setAddress(address);
                        }
                    }
                } else {
                    forceLogout();
                    return;
                }
            }, error => {
            });

        } else {
            forceLogout();
            return;
        }
    }

    toggleMenu() {
        if (!this.state.menu_mobile_opened == false) {
            this.setOffMenu();
            return;
        }
        this.setState({menu_mobile_opened: true});
    }

    toggleDropdown(id, stato = null) {
        if (window.innerWidth < 1200) {
            return;
        }
        if (stato === null) {
            stato = !this.state.dropdown_show;
        } else {
            if (this.state.dropdown_id != id) {
                return;
            }
        }
        this.setState({
            dropdown_show: stato,
            dropdown_id: id
        });
    }

    setOffMenu() {
        this.setState({
            menu_mobile_opened: false,
            menu_mobile_level: false,
            menu_mobile_nav: ''
        });
    }

    setLang(lang) {
        localStorage.setItem("language", lang);
        window.location.reload();
    }

    /**
     * Mobile menù
     */
    mobileMenuOpenChild(index, event = null) {
        if (window.innerWidth > 1200) {
            return;
        }
        if (event !== null) {
            event.preventDefault();
        }
        if (this.state.menu_mobile_level && this.state.menu_mobile_nav == index) {
            this.mobileMenuCloseChild();
            return;
        }
        this.setState({menu_mobile_level: true, menu_mobile_nav: index});
    }

    mobileMenuCloseChild() {
        this.setState({menu_mobile_level: false, menu_mobile_nav: ''});
    }

    /**
     * Wallet
     */
    toggleModalWallet() {
        this.setState({modal_connect_wallet: !this.state.modal_connect_wallet});
        this.setOffMenu();
    }

    isWalletConnected() {
        if (localStorage.getItem("wallet_connected")) {
            return true;
        }
        return false;
    }

    render() {

        return (
            <>
                <ModalConnectWallet toggleModalWallet={this.toggleModalWallet} impostaModal={this.state.modal_connect_wallet} />
                <header className={this.props.className}>
                    <div className='container-fluid'>
                        <div className='header-flex'>
                            <div className='logo'>
                                <a href="/#index">
                                    <img src="assets/img/goat_nation_WHITE.png"/>
                                </a>
                            </div>
                            <nav className={this.state.menu_mobile_opened ? 'mostra-menu' : null}>
                                <ul>
                                    <li className='mobile-close-li'>
                                        <a className='mobile-close' onClick={this.toggleMenu}><span><FontAwesomeIcon
                                            icon={faXmark}/></span></a>
                                    </li>
                                    <li className={'open-mobile-child opener-mobile-menu mobile-child-opened'}>
                                        <a onClick={() => this.mobileMenuOpenChild('homepage')}>
                                            Homepage <span className='open-mobile-child-arrow'><FontAwesomeIcon
                                            icon={this.state.menu_mobile_nav == 'homepage' ? faChevronDown : faChevronRight}/></span>
                                        </a>
                                    </li>
                                    <li className={'mobile-child ' + (this.state.menu_mobile_level && this.state.menu_mobile_nav == 'homepage' ? 'mobile-child-opened' : '')}>
                                        <ul>
                                            <li><a onClick={this.setOffMenu} href='/#utilities'>Utilities</a></li>
                                            <li><a onClick={this.setOffMenu} href='/#roadmap'>Roadmap</a></li>
                                            <li><a onClick={this.setOffMenu} href='/#art'>Collection</a></li>
                                            {/* <li><a onClick={this.setOffMenu} href='/#team'>Team</a></li> */}
                                            <li><a href={testi('header_whitepaper_link')} target="_blank">White Paper</a></li>
                                            {this.state.wallet_connected && MINT_OPENED ? (<li><a style={{color: '#E2FC43', fontWeight: 500}} href='/mint'>Mint GOAT</a></li>) : null}
                                        </ul>
                                    </li>
                                    <li className='separator-menu'></li>

                                    <li className={'open-mobile-child opener-mobile-menu mobile-child-opened'}>
                                        <a onClick={() => this.mobileMenuOpenChild('social')}>
                                            Social <span className='open-mobile-child-arrow'><FontAwesomeIcon
                                            icon={this.state.menu_mobile_nav == 'social' ? faChevronDown : faChevronRight}/></span>
                                        </a>
                                    </li>
                                    <li className={'mobile-child ' + (this.state.menu_mobile_level && this.state.menu_mobile_nav == 'social' ? 'mobile-child-opened' : '')}>
                                        <ul>
                                            <li className='social'><a target="_blank"
                                                                      href='https://twitter.com/goatnation_nft'><FontAwesomeIcon
                                                icon={faTwitter}/> <span className='only-mobile'>twitter</span></a></li>
                                            <li className='social'><a target="_blank"
                                                                      href='https://www.instagram.com/goatnation.nft/'><FontAwesomeIcon
                                                icon={faInstagram}/> <span className='only-mobile'>instagram</span></a>
                                            </li>
                                            <li className='social'><a target="_blank"
                                                                      href='https://www.youtube.com/channel/UC2Z1b-7hPt-cmWeLCPRp9Wg'><FontAwesomeIcon
                                                icon={faYoutube}/> <span className='only-mobile'>youtube</span></a></li>
                                            <li className='social'><a target="_blank"
                                                                      href='https://discord.gg/goat-nation'><FontAwesomeIcon
                                                icon={faDiscord}/> <span className='only-mobile'>discord</span></a></li>
                                            <li className='social'><a target="_blank"
                                                                      href='https://opensea.io/collection/goat-nation-officialnft'><img
                                                className='icon-opensea-header' style={{maxWidth: 20}}
                                                src="assets/img/Header/Logomar-opens.png"/> <span
                                                className='only-mobile'>opensea</span></a></li>
                                        </ul>
                                    </li>
                                    <li className='separator-menu'></li>

                                    <li className={'wallet-li opener-mobile-menu '}
                                        onMouseLeave={() => this.toggleDropdown('wallet', false)}>
                                        {!this.state.wallet_connected ? (
                                            <a onClick={() => this.toggleModalWallet()} id={'btnConnectwallet'}
                                               className='connectWallet'>Connect Wallet <span
                                                className='open-mobile-child-arrow'><FontAwesomeIcon
                                                icon={faChevronRight}/></span></a>) : ""}
                                        {this.state.wallet_connected ? (
                                            <a onClick={(e) => this.mobileMenuOpenChild('wallet', e)}
                                               onMouseEnter={() => this.toggleDropdown('wallet')} href="/account"
                                               className='connectWallet walletConnected'>
                                                <FontAwesomeIcon icon={faWallet}/> My wallet
                                                <span className='open-mobile-child-arrow'>
                                                <FontAwesomeIcon
                                                    icon={this.state.menu_mobile_nav == 'wallet' ? faChevronDown : faChevronRight}/>
                                            </span>
                                            </a>
                                        ) : ""}
                                        <ul className={'mobile-child ' + (this.state.dropdown_show && this.state.dropdown_id == 'wallet' ? 'dropdown mostra-dropdown' : 'dropdown') + ' ' + (this.state.menu_mobile_level && this.state.menu_mobile_nav == 'wallet' ? 'mobile-child-opened' : '')}>
                                            <li><a href="/account"><FontAwesomeIcon icon={faUser}/> My account</a></li>
                                            <li><a href="/goatech-mint-fieno"><FontAwesomeIcon icon={faRocket}/> GOATECH</a>
                                            </li>
                                            <li><a href="/affiliate-area"><FontAwesomeIcon icon={faHandshake}/> Affiliate area</a></li>
                                            <li><a href="/academy-contents?course"><FontAwesomeIcon icon={faGraduationCap}/> Academy</a></li>


                                            { /* <li className='tba-menu'><a href="#">Staking (soon)</a></li>
                                        <li className='tba-menu'><a href="#">Breeding (soon)</a></li>
                                        <li className='tba-menu'><a href="#">GOATREX (soon)</a></li> 
                                        */}
                                            <li><a onClick={() => forceLogout()}><FontAwesomeIcon
                                                icon={faRightFromBracket}/> Logout</a></li>
                                        </ul>
                                    </li>
                                    <li className={'lang-li opener-mobile-menu'}
                                        onMouseLeave={() => this.toggleDropdown('lang', false)}>
                                        <a onClick={() => this.mobileMenuOpenChild('lang')}
                                           onMouseEnter={() => this.toggleDropdown('lang')}>
                                            {lingua_sito() == "it" ? <img className={'flag-lang-header icon-opensea-header'} src={'assets/img/lang/ita.png'} /> : <img className={'flag-lang-header icon-opensea-header'} src={'assets/img/lang/eng.png'} />} <span className='only-mobile'>Settings</span>
                                            <span className='open-mobile-child-arrow'>
                                            <FontAwesomeIcon
                                                icon={this.state.menu_mobile_nav == 'lang' ? faChevronDown : faChevronRight}/>
                                        </span>
                                        </a>
                                        <ul className={'mobile-child ' + (this.state.dropdown_show && this.state.dropdown_id == 'lang' ? 'dropdown mostra-dropdown' : 'dropdown') + ' ' + (this.state.menu_mobile_level && this.state.menu_mobile_nav == 'lang' ? 'mobile-child-opened' : '')}>
                                            {lingua_sito() == "en" ? (<li><a onClick={() => this.setLang('it')}><img
                                                src="assets/img/lang/ita.png"/> Passa a Italiano</a></li>) : ""}
                                            {lingua_sito() == "it" ? (<li><a onClick={() => this.setLang('en')}><img
                                                src="assets/img/lang/eng.png"/> Switch to English</a></li>) : ""}
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                            <div className='menu-mobile'>
                                <a onClick={this.toggleMenu}><FontAwesomeIcon icon={faBars}/></a>
                            </div>
                        </div>
                    </div>

                    <div id='modalError'/>
                </header>
            </>
        );
    }
}

export default HomeSection;