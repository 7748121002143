import React, {Component} from 'react';

import HeaderGoat from '../../../../components/ui/HeaderGoat';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';
import {Reti} from '../../../../components/wallet_generic/Wallets';
import {TipiContenuto} from "../../../../components/area_riservata/academy/TipiContenuto";
import {EndPointMethod, RequestBackend} from "../../../../components/area_riservata/backend/RequestBackend";
import BtnCreateElement from "../../../../components/area_riservata/academy/admin/ui/BtnCreateElement";
import ModalManageLessons from "../../../../components/area_riservata/academy/admin/ModalManageLessons";
import ModalConfirm from "../../../../components/area_riservata/ui/ModalConfirm";
import {showError} from "../../../../components/ui/ToastErrore";

export class AdminAcademyEditContent extends Component {
    constructor(props) {
        super(props);

        const url_params = new URLSearchParams(window.location.search);
        const url_tipo_contenuto = url_params.get("type");

        let tipo_contenuto = "";
        let submenu = "";
        if (url_tipo_contenuto == TipiContenuto.COURSE) {
            tipo_contenuto = TipiContenuto.COURSE;
            submenu = "admin-academy-manage-courses";
        } else if (url_tipo_contenuto == TipiContenuto.GROUP_LIVE) {
            tipo_contenuto = TipiContenuto.GROUP_LIVE;
            submenu = "admin-academy-manage-live-recorded";
        } else {
            window.location.href = "/";
            return;
        }

        const id_contenuto = url_params.get("_id");
        if (id_contenuto == "") {
            window.location.href = "/";
            return;
        }

        this.state = {
            'menu_address': '',
            type: tipo_contenuto,
            submenu: submenu,
            id_content: id_contenuto,
            name_content: '',
            modal_create: false,
            modal_edit: false,

            list_lessons: [],
            edit_data: []
        };
        this.setAddress = this.setAddress.bind(this);
        this.toggle_modal_create = this.toggle_modal_create.bind(this);
        this.toggle_modal_edit = this.toggle_modal_edit.bind(this);
        this.delete_content = this.delete_content.bind(this);
    }

    toggle_modal_create() {
        this.setState({modal_create: !this.state.modal_create});
    }

    toggle_modal_edit(edit_data = false) {
        if (edit_data) {
            this.setState({
                edit_data: edit_data
            });
        }
        this.setState({
            modal_edit: !this.state.modal_edit,
        });
    }

    async setAddress(address) {
        this.setState({'menu_address': address});

        const request = new RequestBackend(address, EndPointMethod.ACADEMY_ADMIN_LIST_LESSONS, {
            id_content: this.state.id_content
        });
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            this.setState({
                name_content: response.name_content,
                list_lessons: response.list_lessons
            });
        }
    }

    async delete_content(props) {
        const modalConfirm = new ModalConfirm("Elimina contenuto", "Sei sicuro di voler eliminare \"" + props.title + "\"?", "Elimina");
        const responseModal = await modalConfirm.waitModal();
        if (responseModal) {
            const request = new RequestBackend(this.state.menu_address, EndPointMethod.ACADEMY_ADMIN_DELETE_LESSON, {id: props._id});
            const response = await request.run();
            if (request.isResponseError()) {
                showError({'titolo': 'Errore', 'testo': 'Impossibile eliminare la lezione: ' + response.error});
                return;
            }
            window.location.reload();
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress}
                            checkLogged="true"/>

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu={this.state.submenu}
                                          address={this.state.menu_address}/>
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding scroller scroller-stylized'>

                                    <h3>Manage {this.state.name_content}</h3>

                                    {this.state.type == TipiContenuto.COURSE ?
                                        (
                                            <>
                                                <BtnCreateElement onClick={(e) => this.toggle_modal_create()}
                                                                  testo={'Add new lesson'}/>
                                                <ModalManageLessons
                                                    toggleModal={this.toggle_modal_create}
                                                    address={this.state.menu_address}
                                                    impostaModal={this.state.modal_create}
                                                    id_modal={'add'}
                                                    id_content={this.state.id_content}
                                                    titolo={'Add new lesson'}
                                                    callback={'/admin-academy-edit-content?type=' + this.state.type + '&_id={id}'}/>
                                            </>
                                        )
                                    : null}

                                    {this.state.type == TipiContenuto.GROUP_LIVE ?
                                        (
                                            <>
                                                <BtnCreateElement onClick={(e) => this.toggle_modal_create()}
                                                                  testo={'Add new Live'}/>
                                                <ModalManageLessons
                                                    toggleModal={this.toggle_modal_create}
                                                    address={this.state.menu_address}
                                                    impostaModal={this.state.modal_create}
                                                    id_modal={'add'}
                                                    id_content={this.state.id_content}
                                                    titolo={'Add new live'}
                                                    callback={'/admin-academy-edit-content?type=' + this.state.type + '&_id={id}'}/>
                                            </>
                                        )
                                    : null}

                                    <ModalManageLessons
                                        toggleModal={this.toggle_modal_edit}
                                        address={this.state.menu_address}
                                        impostaModal={this.state.modal_edit}
                                        id_modal={'edit'}
                                        id_content={this.state.id_content}
                                        titolo={'Edit'}
                                        edit_data={this.state.edit_data}
                                        callback={'/admin-academy-edit-content?type=' + this.state.type + '&_id={id}'}/>

                                    <div style={{marginTop: 10}} className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th>N°</th>
                                                    <th>Title</th>
                                                    <th>Time</th>
                                                    <th>Video</th>
                                                    <th>Attachments</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.list_lessons && Array.from(this.state.list_lessons).length > 0 ? Array.from(this.state.list_lessons).map((lesson, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="N°" className='cell-left'>{lesson.lesson}</td>
                                                            <td data-label="Title" className='cell-left'>{lesson.title}</td>
                                                            <td data-label="Duration" className='cell-left'>{lesson.video_duration}</td>
                                                            <td data-label="Video" className='cell-left'>
                                                                {lesson.video_url != "" ? (<a href={lesson.video_url} style={{color: 'white'}} target={'_blank'}>Link</a>) : ""}
                                                            </td>
                                                            <td data-label="Attachments" className='cell-left'>
                                                                {lesson.attachment !== undefined && lesson.attachment != "" ? (<a href={lesson.attachment} style={{color: 'white'}} target={'_blank'}>Link</a>) : ""}
                                                            </td>
                                                            <td data-label="Edit" className='cell-left'>
                                                                <a style={{color: 'white'}} href={''} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.toggle_modal_edit(lesson)
                                                                }}>Edit</a>
                                                            </td>
                                                            <td data-label="Delete" className='cell-left'>
                                                                <a style={{color: '#ef5350'}} href={''} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.delete_content(lesson)
                                                                }}>Delete</a>
                                                            </td>
                                                        </tr>
                                                    )
                                                }, this) : (
                                                    <tr>
                                                        <td colSpan="7" className='cell-left-important'>

                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div
                            style={{background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain`}}
                            className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminAcademyEditContent;