import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import { Reti } from '../../../components/wallet_generic/Wallets.js';
import { EndPointMethod, RequestBackend } from '../../../components/area_riservata/backend/RequestBackend.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { showError } from '../../../components/ui/ToastErrore.js';
import ModalConfirm from '../../../components/area_riservata/ui/ModalConfirm.js';

export class AffiliateReferrals extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'address_referral': '',
            'has_referral_address': false,
            'referrals_list': null,
            'sendingLoading': false,
            'infoLoaded': false
        };

        this.setAddress = this.setAddress.bind(this);
        this.applyReferralAddress = this.applyReferralAddress.bind(this);
    }

    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.AFFILIATE_REFERRALS_PAGE);
        const response = await request.run();

        if (!request.isResponseError()) {
            const response_data = response.data;

            this.setState({
                'infoLoaded': true,
                'address_referral': response_data.address_referral,
                'has_referral_address': response_data.address_referral != "" ? true : false,
                'referrals_list': response_data.referrals_list,
            });
        } else {
            if (response["error"]) {
                showError({ 'titolo': 'Error', 'testo': response["error"] });
            } else {
                showError({ 'titolo': 'Error', 'testo': 'A problem occurred with the loading of the page' });
            }
        }
    }

    async applyReferralAddress() {

        const referral_address = document.getElementById("affiliate-address-input").value;
        if (referral_address == "") {
            showError({ 'titolo': 'Errore', 'testo': 'Insert first your sponsor' });
            return;
        }

        // Modal conferma address referral
        const modalDescription = renderToString(
            <div>
                <p>Your sponsor: <b>{referral_address}</b></p>
                <p class='affiliate-warning'><b>Attention</b>: once you have entered the sponsor you will not be able to change it</p>
            </div>
        );

        const modalConfirm = new ModalConfirm("Confirm sponsor address", modalDescription);
        const responseModal = await modalConfirm.waitModal();
        if (!responseModal) {
            return false;
        }

        this.setState({ sendingLoading: true });

        const request = new RequestBackend(this.state.menu_address, EndPointMethod.AFFILIATE_SET_ADDRESS, {
            wallet_referral: referral_address
        });
        const response = await request.run();

        this.setState({ sendingLoading: false });

        if (!request.isResponseError()) {
            if (response["error"]) {
                showError({ 'titolo': 'Error', 'testo': response["error"] });
            } else {
                // Referral inserito correttamente
                window.location.reload();
            }
        } else {
            if (response["error"]) {
                showError({ 'titolo': 'Error', 'testo': response["error"] });
            } else {
                showError({ 'titolo': 'Error', 'testo': 'Error: unable to set referral' });
            }
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='affiliate-area' subMenu='affiliate-area' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Affiliate Area</h3>

                                    <form onSubmit={(e) => { this.applyReferralAddress(); e.preventDefault() }} className='form-insert-affiliate'>

                                        {!this.state.has_referral_address ?
                                            <>
                                                <label>Insert here your sponsor:</label>
                                                <input type={'text'} autoComplete={'off'} id={'affiliate-address-input'} disabled={!this.state.infoLoaded} placeholder="Insert here the wallet address of your sponsor" />
                                            </>
                                            :
                                            <p className='affiliate-address-saved' >Your sponsor: <span>{this.state.address_referral}</span></p>
                                        }

                                        {this.state.infoLoaded && !this.state.has_referral_address ?
                                            <button type="submit" >
                                                SUBMIT THE ADDRESS
                                                {!this.state.sendingLoading ? <span>&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleRight} /></span> : null}
                                                {this.state.sendingLoading ? <span>&nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} spin /></span> : null}
                                            </button>
                                            :
                                            null
                                        }

                                        {this.state.infoLoaded && !this.state.has_referral_address ?
                                            <p className={'affiliate-warning'}>
                                                <b>Attention</b>: once you have entered the sponsor you will not be able to change it
                                            </p>
                                            :
                                            null
                                        }
                                    </form>

                                    <h3>Your referrals:</h3>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th width="100px" className='cell-left'>#</th>
                                                    <th>Joined date</th>
                                                    <th>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.referrals_list && Array.from(this.state.referrals_list).length > 0 ? Array.from(this.state.referrals_list).map((referral, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="#" className='cell-left'>{referral.number}</td>
                                                            <td data-label="Joined date" className='cell-left'>{referral.dataJoin}</td>
                                                            <td data-label="Address" className='cell-left'>{referral.address}</td>
                                                        </tr>
                                                    )
                                                }, this) : (
                                                    <tr>
                                                        <td colSpan="3" className='cell-left-important'>Nothing yet to show</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AffiliateReferrals;