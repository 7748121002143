
import React, { Component } from 'react';

import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className="container container-padding">
                <h3 dangerouslySetInnerHTML={{ __html: testi('faq_h3') }} style={{ textAlign: 'center' }} className="h3-section2"></h3>
                <p dangerouslySetInnerHTML={{ __html: testi('faq_parag') }} style={{ textAlign: 'center' }} className="parag-section3"></p>

                <div className='faq-grid'>
                    <div className='faq-grid-element'>
                        <h4 dangerouslySetInnerHTML={{ __html: testi('faq_quest1') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: testi('faq_resp1') }}></p>
                    </div>
                    <div className='faq-grid-element'>
                        <h4 dangerouslySetInnerHTML={{ __html: testi('faq_quest2') }} ></h4>
                        <p dangerouslySetInnerHTML={{ __html: testi('faq_resp2') }}></p>
                    </div>
                    <div className='faq-grid-element'>
                        <h4 dangerouslySetInnerHTML={{ __html: testi('faq_quest3') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: testi('faq_resp3') }} ></p>
                    </div>
                    <div className='faq-grid-element'>
                        <h4 dangerouslySetInnerHTML={{ __html: testi('faq_quest4') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: testi('faq_resp4') }} ></p>
                    </div>
                    <div className='faq-grid-element'>
                        <h4 dangerouslySetInnerHTML={{ __html: testi('faq_quest5') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: testi('faq_resp5') }}></p>
                    </div>
                    <div className='faq-grid-element'>
                        <h4 dangerouslySetInnerHTML={{ __html: testi('faq_quest6') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: testi('faq_resp6') }}></p>
                    </div>
                </div>
                <div style={{ clear: 'both' }}></div>
                <div className='logo-footer'>
                    <img style={{ width: 150 }} src="assets/img/goat_nation_WHITE.png" />
                    <p className='footer_copyright'>
                        Tutti i diritti riservati<br />
                        <a href={'https://www.iubenda.com/privacy-policy/92721699'} target={"_blank"}>
                            Privacy Policy
                        </a> - <a href={'https://www.iubenda.com/privacy-policy/92721699/cookie-policy'} target={"_blank"}>
                            Cookie Policy
                        </a> - <a href={'https://www.iubenda.com/terms-and-conditions/92721699'} target={"_blank"}>
                            Terms & Conditions
                        </a>
                    </p>
                    <p className={'footer-disclaimer'} dangerouslySetInnerHTML={{__html: testi("disclaimer")}}></p>
                </div>
            </div>
        );
    }
}

export default HomeSection;