import React, {Component} from 'react';

import {testi} from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container community-container'>
                <h3 dangerouslySetInnerHTML={{__html: testi("eventi_community_title")}} className="h3-section2 centered"></h3>
                <p dangerouslySetInnerHTML={{__html: testi("eventi_community_parag")}} className={'parag-section-opacity centered'}></p>
                <p className={'community-header'}>
                    <img src={'assets/img/HomeGoatCommunity/364.png'} />
                    <img src={'assets/img/HomeGoatCommunity/367.png'} />
                    <img src={'assets/img/HomeGoatCommunity/369.png'} />
                </p>

                <div className={'grid-two-column'}>
                    <div className={'column'}>
                        <p className={'community-img'}>
                            <img src={'assets/img/HomeGoatCommunity/342.png'}/>
                            <span className={'community-img-badge-bg'} dangerouslySetInnerHTML={{__html: testi("eventi_community_1_title")}}></span>
                        </p>
                        <p className={'community-logo'}>
                            <img src={'assets/img/goat_nation_WHITE.png'}/>
                        </p>
                        <p className={'paragw80 parag-small-opacity'} dangerouslySetInnerHTML={{__html: testi("eventi_community_1")}}></p>
                    </div>

                    <div className={'column'}>
                        <p className={'community-img'}>
                            <img src={'assets/img/HomeGoatCommunity/371.png'}/>
                            <span className={'community-img-badge-bg'} dangerouslySetInnerHTML={{__html: testi("eventi_community_2_title")}}></span>
                        </p>
                        <p className={'community-logo'}>
                            <img src={'assets/img/HomeGoatMind/partners/BlockchainRoma.png'}/>
                        </p>
                        <p className={'paragw80 parag-small-opacity'} dangerouslySetInnerHTML={{__html: testi("eventi_community_2")}}></p>
                    </div>
                </div>

                <div className={'grid-two-column'}>
                    <div className={'column'}>
                        <p className={'community-img'}>
                            <img src={'assets/img/HomeGoatCommunity/372.png'}/>
                            <span className={'community-img-badge-bg'}>Lugano</span>
                        </p>
                        <p className={'community-logo'}>
                            <img src={'assets/img/HomeGoatMind/partners/Metaforum.png'}/>
                        </p>
                        <p className={'paragw80 parag-small-opacity'} dangerouslySetInnerHTML={{__html: testi("eventi_community_3")}}></p>
                    </div>

                    <div className={'column'}>
                        <p className={'community-img'}>
                            <img src={'assets/img/HomeGoatCommunity/382.png'}/>
                            <span className={'community-img-badge-bg'} dangerouslySetInnerHTML={{__html: testi("eventi_community_4_title")}}></span>
                        </p>
                        <p className={'community-logo'}>
                            <img src={'assets/img/HomeGoatMind/partners/noexcuses.png'}/>
                        </p>
                        <p className={'paragw80 parag-small-opacity'} dangerouslySetInnerHTML={{__html: testi("eventi_community_4")}}></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;