
import React from 'react';
import { renderToString } from 'react-dom/server'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faClose } from '@fortawesome/free-solid-svg-icons';

export class ModalConfirm {

    constructor(title, description, confirm_text = "Confirm") {
        this.state = {
            title: title,
            description: description,
            confirm_btn_text: confirm_text
        };
    }

    waitModal() {
        return new Promise((resolve) => {
            this.render();

            const _this = this;
            this.state.listenerClose = document.querySelector(".modal-confirm .modal-close").addEventListener("click", function () {
                _this.close();
                resolve(false);
            });
            this.state.listenerConfirm = document.querySelector(".modal-confirm .btn-modal-confirm").addEventListener("click", function () {
                _this.close();
                resolve(true);
            });
        });
    }

    close() {
        if (this.state.listenerClose) {
            document.querySelector(".modal-confirm .modal-close").removeEventListener("click", this.state.listenerClose);
        }
        if (this.state.listenerConfirm) {
            document.querySelector(".modal-confirm .btn-modal-confirm").removeEventListener("click", this.state.listenerConfirm);
        }

        const modalOverlay = document.querySelector(".modal-confirm-overlay");
        if(modalOverlay) {
            modalOverlay.remove();
        }
    }

    render() {

        const modal_overlay = document.createElement("div");
        modal_overlay.className = "modal-confirm-overlay modal-overlay modal-overlay-inframe modal-show";

        const modal = document.createElement("div");
        modal.className = "modal modal-confirm";

        const iconClose = document.createElement("a");
        iconClose.onclick = this.close();
        iconClose.className = "modal-close";

        const iconCloseFwe = renderToString(<FontAwesomeIcon icon={faClose} />);
        iconClose.innerHTML = iconCloseFwe;

        modal.appendChild(iconClose);

        const modalTitle = document.createElement("h4");
        modalTitle.innerText = this.state.title;

        modal.appendChild(modalTitle);

        const modalContent = document.createElement("p");
        modalContent.className = "modal-content";
        modalContent.innerHTML = this.state.description;

        modal.appendChild(modalContent);

        const modalButton = document.createElement("button");
        modalButton.className = "btn-block-orange btn-right btn-modal-confirm";
        modalButton.innerHTML = this.state.confirm_btn_text;

        modal.appendChild(modalButton);
        modal_overlay.appendChild(modal);

        document.body.appendChild(modal_overlay);
    }
}

export default ModalConfirm;