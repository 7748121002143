import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {Reti} from './components/wallet_generic/Wallets';
import './App.css';
import './App_mobile.css';

import Home from './pages/Home.js';
import Mint from './pages/MintGoat.js';
import AreaRiservata from './pages/area_riservata/AreaRiservata.js';
import SwitchNetwork from './pages/SwitchNetwork';

import GoatechMintFieno from './pages/area_riservata/goatech/GoatechMintFieno.js';
import GoatechStats from './pages/area_riservata/goatech/GoatechStats.js';
import GoatechPAMMHistory from './pages/area_riservata/goatech/GoatechPAMMHistory.js';
import GoatechVerifyAccount from './pages/area_riservata/goatech/GoatechVerifyAccount.js';
import GoatechInfo from './pages/area_riservata/goatech/GoatechInfo.js';

import AdminGoatechBurnArea from './pages/area_riservata/goatech/admin/AdminGoatechBurnArea';
import AdminGoatechUpdateHistory from './pages/area_riservata/goatech/admin/AdminGoatechUpdateHistory';

import AffiliateReferrals from './pages/area_riservata/affiliate/AffiliateReferrals';
import AffiliateCommissions from './pages/area_riservata/affiliate/AffiliateCommissions';
import AffiliateBonus from './pages/area_riservata/affiliate/AffiliateBonus';
import {AdminCommissionsAffiliate} from './pages/area_riservata/affiliate/admin/AdminCommissionsAffiliate';
import AdminAffiliateBonus from './pages/area_riservata/affiliate/admin/AdminAffiliateBonus';

import AdminAcademyManageContents from './pages/area_riservata/academy/admin/AdminAcademyManageContents';
import AdminAcademyEditContent from './pages/area_riservata/academy/admin/AdminAcademyEditContent';

import AcademyContents from "./pages/area_riservata/academy/AcademyContents";
import AcademyViewContent from "./pages/area_riservata/academy/AcademyViewContent";

import AdminGoatechBalleList from './pages/area_riservata/goatech/admin/AdminGoatechBalleList';
import AdminGoatechWalletList from './pages/area_riservata/goatech/admin/AdminGoatechWalletList';

function Main() {
    return (
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/mint' element={<Mint/>}></Route>
            <Route path='/account' element={<AreaRiservata/>}></Route>

            <Route path='/switch-network-ethereum' element={<SwitchNetwork goto={Reti.ETHEREUM}/>}></Route>
            <Route path='/switch-network-binance' element={<SwitchNetwork goto={Reti.BINANCE}/>}></Route>

            <Route path='/verify-wallet' element={<GoatechVerifyAccount/>}></Route>
            <Route path='/goatech-mint-fieno' element={<GoatechMintFieno/>}></Route>
            <Route path='/goatech-stats' element={<GoatechStats/>}></Route>
            <Route path='/goatech-history' element={<GoatechPAMMHistory/>}></Route>
                {/* <Route path='/goatech-info' element={<GoatechInfo/>}></Route> */}

            <Route path="/affiliate-area" element={<AffiliateReferrals/>}></Route>
            <Route path="/affiliate-commissions" element={<AffiliateCommissions/>}></Route>
            <Route path="/affiliate-bonus" element={<AffiliateBonus/>}></Route>

            <Route path='/admin-burn-area' element={<AdminGoatechBurnArea/>}></Route>
            <Route path='/admin-update-history' element={<AdminGoatechUpdateHistory/>}></Route>
            <Route path='/admin-commissions-affiliate' element={<AdminCommissionsAffiliate/>}></Route>
            <Route path='/admin-affiliate-bonus' element={<AdminAffiliateBonus/>}></Route>

            <Route path='/admin-academy-manage-contents' element={<AdminAcademyManageContents />} ></Route>
            <Route path='/admin-academy-edit-content' element={<AdminAcademyEditContent />} ></Route>

            <Route path='/academy-contents' element={<AcademyContents />} ></Route>
            <Route path='/academy-view-content' element={<AcademyViewContent />} ></Route>

                <Route path='/admin-goatech-list-balle' element={<AdminGoatechBalleList />}> </Route>
                <Route path='/admin-goatech-list-wallet' element={<AdminGoatechWalletList />} ></Route>
        </Routes>
    );
}

export default Main;