import developer from './abi/dev/contract.json';
import production from './abi/prod/contract.json';

import { isDev } from '../wallet_generic/helpers/isDev';

export function getAbiContract() {
    if (isDev()) {
        return developer;
    }
    return production;
}

export function getAddressContract() {
    if (isDev()) {
        return "0x842530B13d00Dee1C8c9dbDaE2B6A55B8568D5Ab";
    }
    return "0xFB35136FEDdc3C4531BE2E8E02eEfda95f742444"; // Nuovo contratto 22-08-2022
}

// Vecchio contratto con bug processBurn()
export function getOLDAddressContract() {
    if (isDev()) {
        return "0x842530B13d00Dee1C8c9dbDaE2B6A55B8568D5Ab";
    }
    return "0xFDE2cB5Ef78ebfc0c09946Ffd106a50930bC2b82";
}

export function getGenesisBlock() {
    if (isDev()) {
        // https://testnet.bscscan.com/tx/0x4547c779fda2b14fa50d92ce0b1e3fb76fe4b56af4c1d00fda5b941d49f96e9b
        return 20943034;
    }
    // https://bscscan.com/tx/0xf5e91a1b4b86e1b1216fc03893b67b69e506113927bb767c97357af0385bac9c
    return 20653883;
}

export default getAbiContract;
