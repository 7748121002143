import React, {Component} from 'react';

import {showError} from '../../ui/ToastErrore';
import {MINT_OPENED} from "../../../config/ConfigMint";
import { testi } from "../../../language/lang.js";

function mintPage() {
    if (localStorage.getItem("wallet_connected")) {
        window.location.href = "/mint";
    } else {
        showError({'titolo': 'Connect Wallet', 'testo': 'Connect your wallet first'});
    }
}

export class GoatMintBtn extends Component {
    render() {
        if (MINT_OPENED) {
            return (
                <p className='box-discordbtn'>
                    <a className='mintbtn-home' style={{cursor: 'pointer'}} onClick={() => mintPage()}>Mint a new
                        GOAT!</a>
                </p>
            );
        } else {
            return (
                <p className='box-discordbtn'>
                    <a className='mintbtn-home' style={{cursor: 'pointer'}} href={'https://opensea.io/collection/goat-nation-officialnft'}>
                        {testi("welcome_buyon_opensea")} <img width={'20'} style={{filter: 'invert(1)', verticalAlign: 'text-top'}} src={'assets/img/Header/Logomar-opens.png'} alt={'OpenSea'}/>
                    </a>
                </p>
            );
        }
    }
}

export default GoatMintBtn;