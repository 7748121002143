import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {EndPointMethod, RequestBackend} from "../../backend/RequestBackend";
import {showError} from "../../../ui/ToastErrore";
import toBase64 from "../../../../helpers/fileToBase64";
import ModalConfirm from "../../ui/ModalConfirm";

export class ModalManageLessons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu_address: '',
            mostra_modal: false,
            id_modal: this.props.id_modal,
            id_content: this.props.id_content,
            titolo_modal: this.props.titolo,
            callback: this.props.callback,
            edit_data: this.props.edit_data ? this.props.edit_data : {}
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.impostaModal != this.props.impostaModal) {
            this.setState({
                menu_address: this.props.address,
                mostra_modal: this.props.impostaModal,
                edit_data: this.props.edit_data
            });

            let edit_title = "";
            let edit_lesson = "";
            let edit_descr = "";
            let edit_video_url = "";
            let edit_video_duration = "";

            if (this.props.impostaModal && this.props.edit_data && this.props.edit_data._id) {
                const edit_data = this.props.edit_data;
                edit_title = edit_data.title;
                edit_lesson = edit_data.lesson;
                edit_descr = edit_data.description;
                edit_video_url = edit_data.video_url;
                edit_video_duration = edit_data.video_duration;
            }

            // Svuoto i valori
            document.getElementById(this.state.id_modal + 'title').value = edit_title;
            document.getElementById(this.state.id_modal + 'lesson').value = edit_lesson;
            document.getElementById(this.state.id_modal + 'description').value = edit_descr;
            document.getElementById(this.state.id_modal + 'video_url').value = edit_video_url;
            document.getElementById(this.state.id_modal + 'video_duration').value = edit_video_duration;
            document.getElementById(this.state.id_modal + 'video_thumbnail').value = "";
            document.getElementById(this.state.id_modal + 'attachment').value = "";
        }
    }

    async salva() {

        const title = document.getElementById(this.state.id_modal + 'title').value;
        const lesson = document.getElementById(this.state.id_modal + 'lesson').value;
        const description = document.getElementById(this.state.id_modal + 'description').value;
        const video_url = document.getElementById(this.state.id_modal + 'video_url').value;
        const video_duration = document.getElementById(this.state.id_modal + 'video_duration').value;

        if (title == "" || lesson == "" || video_url == "") {
            showError({'titolo': 'Errore', 'testo': 'Inserire tutti i campi richiesti'});
            return false;
        }

        const file_attachment = document.getElementById(this.state.id_modal + 'attachment').files;
        const file_attachment_selected = file_attachment.length == 1 ? file_attachment[0] : ""

        const file_thumbnail = document.getElementById(this.state.id_modal + 'video_thumbnail').files;
        const file_thumbnail_selected = file_thumbnail.length == 1 ? file_thumbnail[0] : ""

        const body = {
            idContent: this.state.id_content,
            title: title,
            lesson: lesson,
            description: description,
            video_url: video_url,
            video_duration: video_duration,
            video_thumbnail: file_thumbnail_selected != "" ? await toBase64(file_thumbnail_selected) : "",
            attachment: file_attachment_selected != "" ? await toBase64(file_attachment_selected) : "",
        };

        let edit_content = false;
        if (this.props.edit_data && this.props.edit_data._id && this.props.edit_data._id != "") {
            edit_content = true;
        }

        if (edit_content) {
            const element_del_attachment = document.getElementById(this.state.id_modal + 'del_attachment');
            const del_attachment = element_del_attachment ? element_del_attachment.checked : false;
            body.delete_file_attachment = del_attachment;

            const element_del_thumbnail = document.getElementById(this.state.id_modal + 'del_thumbnail');
            const del_video_thumbnail = element_del_thumbnail ? element_del_thumbnail.checked : false;
            body.delete_file_video_thumbnail = del_video_thumbnail;

            body.id = this.props.edit_data._id;
        }

        const request = new RequestBackend(this.state.menu_address, EndPointMethod.ACADEMY_ADMIN_MANAGE_LESSONS, {});
        const response = await request.run(body);

        if (request.isResponseError()) {
            if (response["error"]) {
                showError({'titolo': 'Errore', 'testo': response["error"]});
                return false;
            }
            showError({'titolo': 'Errore', 'testo': 'Errore nell\'inserimento elemento'});
            return false;
        }

        if (!edit_content) {
            showError({'titolo': 'Success!', 'testo': 'Il contenuto è stato inserito correttamente'});
        } else {
            showError({'titolo': 'Success!', 'testo': 'Il contenuto è stato aggiornato correttamente'});
        }
        const _this = this;
        window.setTimeout(function () {
            // window.location.href = _this.state.callback.replace('{id}', response['id']);
            window.location.reload();
        }, 1200);
    }


    async esempi_video_url() {
        const modalConfirm = new ModalConfirm("Esempi di URL validi", "<b>Per Youtube:</b> https://www.youtube.com/embed/<i>tVSxfN25G9U</i><br /><b>Per Vimeo:</b> https://player.vimeo.com/video/<i>29950141</i>", "Chiudi");
        const responseModal = await modalConfirm.waitModal();
    }

    render() {
        return (
            <div className={'modal-overlay modal-overlay-inframe' + (this.state.mostra_modal ? ' modal-show' : '')}>
                <div className='modal'>
                    <a onClick={() => this.props.toggleModal()} className='modal-close'>
                        <FontAwesomeIcon icon={faClose}/>
                    </a>
                    <form className='form-insert-content'>
                        <h4>{this.state.titolo_modal}</h4>

                        <div style={{width: '100px', float: 'left', marginRight: 10}} className={'riga'}>
                            <label>Lesson number*</label>
                            <input required={true} type={'number'} min={'0'} id={this.state.id_modal + 'lesson'}/>
                        </div>
                        <div style={{width: 'calc(100% - 110px)', float: 'left'}} className={'riga'}>
                            <label>Title*</label>
                            <input required={true} type={'text'} id={this.state.id_modal + 'title'}/>
                        </div>
                        <div style={{clear: 'both'}}></div>

                        <div className={'riga'}>
                            <label>Description</label>
                            <textarea style={{maxWidth: '100%', minWidth: '100%'}} rows={5} type={'text'}
                                      id={this.state.id_modal + 'description'}></textarea>
                        </div>

                        <div style={{width: '100px', float: 'left', marginRight: 10}} className={'riga'}>
                            <label>Time (in min.)</label>
                            <input placeholder={'Es: 10'} required={true} min={'0'} type={'number'} id={this.state.id_modal + 'video_duration'}/>
                        </div>
                        <div style={{width: 'calc(100% - 110px)', float: 'left'}} className={'riga'}>
                            <label>Video embed* <a href={''} style={{color: 'white'}} onClick={(e) => {
                                e.preventDefault();
                                this.esempi_video_url();
                            }}>GUARDA ESEMPI</a></label>
                            <input placeholder={'Direct link of your video (Vimeo, Youtube etc.)'} required={true}
                                   type={'text'} id={this.state.id_modal + 'video_url'}/>
                        </div>
                        <div style={{clear: 'both'}}></div>

                        <div style={{marginTop: 10}} className={'riga'}>
                            <label>Video thumbnail</label>
                            {this.state.edit_data && this.state.edit_data.video_thumbnail && this.state.edit_data.video_thumbnail != "" ?
                                (
                                    <p style={{fontSize: 13}}>
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                        <input style={{verticalAlign: 'middle'}}
                                               id={this.state.id_modal + 'del_thumbnail'}
                                               type={'checkbox'}/> Current thumbnail:&nbsp;&nbsp;
                                        <a style={{wordBreak: 'break-all', color: "white"}}
                                           href={this.state.edit_data.video_thumbnail} target={'_blank'}>
                                            Link
                                        </a>
                                    </p>
                                )
                                : null}
                            <input type={'file'} id={this.state.id_modal + 'video_thumbnail'}/>
                        </div>

                        <div className={'riga'}>
                            <label>Attachments</label>
                            {this.state.edit_data && this.state.edit_data.attachment && this.state.edit_data.attachment != "" ?
                                (
                                    <p style={{fontSize: 13}}>
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                        <input style={{verticalAlign: 'middle'}}
                                               id={this.state.id_modal + 'del_attachment'}
                                               type={'checkbox'}/> Current attachment:&nbsp;&nbsp;
                                        <a style={{wordBreak: 'break-all', color: "white"}}
                                           href={this.state.edit_data.attachment} target={'_blank'}>
                                            Link
                                        </a>
                                    </p>
                                )
                                : null}
                            <input type={'file'} id={this.state.id_modal + 'attachment'}/>
                        </div>

                        <div className={'riga'}>
                            <input type={'submit'} onClick={(e) => {
                                e.preventDefault();
                                this.salva();
                            }} value={'Save'}/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ModalManageLessons;