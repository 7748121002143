import React, { Component } from 'react';

import HeaderGoat from '../../../../components/ui/HeaderGoat';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';

import { Reti } from '../../../../components/wallet_generic/Wallets';
import { EndPointMethod, RequestBackend } from '../../../../components/area_riservata/backend/RequestBackend';
import AffiliateProfitBadge, { AffiliateProfitBadgeEnum } from '../../../../components/area_riservata/affiliate/ui/AffiliateProfitBadge';
import { AffiliateBtnPay } from '../../../../components/area_riservata/affiliate/ui/AffiliateBtnPay';

export class AdminCommissionsAffiliate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'profits_list': null
        };
        this.setAddress = this.setAddress.bind(this);
    }

    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.AFFILIATE_ADMIN_COMMISSIONS);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            const response_data = response.data;
            this.setState({
                'profits_list': response_data.profits_list
            });
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-commissions-affiliate' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Commissions Affiliate</h3>

                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th width="100px" className='cell-left'>NFT ID</th>
                                                    <th className='cell-left'>Commissions</th>
                                                    <th className='cell-left'>Percentage</th>
                                                    <th className='cell-left'>Buy Date</th>
                                                    <th className='cell-left'>Payment Date</th>
                                                    <th>Status</th>
                                                    <th className='cell-left'>Sponsor Address</th>
                                                    <th className='cell-left'>Buyer Address</th>
                                                    <th>Pay</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.profits_list ? Array.from(this.state.profits_list).map((profit, i) => {
                                                    return (
                                                        <tr key={i} className={profit.percentage == 20 ? 'affiliateProfit_gold' : ''}>
                                                            <td data-label="NFT ID" className='cell-left'>{profit.tokenId}</td>
                                                            <td data-label="Commissions" className='cell-left greenTdValue'>+ {profit.value} ETH</td>
                                                            <td data-label="Percentage" className='cell-left'>{profit.percentage} %</td>
                                                            <td data-label="Buy Date" className='cell-left'>{profit.buyDate}</td>
                                                            <td data-label="Payment Date" className='cell-left'>{profit.payDate}</td>
                                                            <td data-label="Status"><AffiliateProfitBadge badge={profit.status} /></td>
                                                            <td data-label="Sponsor Address" className='cell-left'>
                                                                <input value={profit.addressRef} className='input-table-readonly' readOnly width="150px" />
                                                            </td>
                                                            <td data-label="Buyer Address" className='cell-left'>
                                                                <input value={profit.addressMint} className='input-table-readonly' readOnly width="150px" />
                                                            </td>
                                                            <td data-label="Pay">
                                                                <AffiliateBtnPay admin={this.state.menu_address} address={profit.addressRef} disabled={profit.status != AffiliateProfitBadgeEnum.PENDING} />
                                                            </td>
                                                        </tr>
                                                    )
                                                }, this) : null}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminCommissionsAffiliate;