import React, {Component} from 'react';

import { testi } from "../../../language/lang.js";

function redirect() {
    window.location.href = "https://goat-nation.gitbook.io/goat-nation/v/italiano/goatech/cosa-e-goatech";
}

export class GoatechWhitepaper extends Component {
    render() {
        return (
            <p className='box-discordbtn'>
                <a className='mintbtn-home btn-goatech' style={{cursor: 'pointer'}} onClick={() => redirect()}>{testi("goatech_moreinfo")} </a>
            </p>
        );
    }
}

export default GoatechWhitepaper;