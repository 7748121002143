import Cookies from 'js-cookie';

export function getGoatechLevelCookie(data) {

    // Login riuscito con successo, setto i cookie
    const current_level = Cookies.get('GoatechLevelCurrent') ? Cookies.get('GoatechLevelCurrent') : "";
    const current_level_format = Cookies.get('GoatechLevelCurrentFormat') ? Cookies.get('GoatechLevelCurrentFormat') : "";
    const current_next_level_format = Cookies.get('GoatechLevelNextFormat') ? Cookies.get('GoatechLevelNextFormat') : "";

    return {
        current_level: current_level,
        current_level_format: current_level_format,
        current_next_level_format: current_next_level_format
    };
}