import React, {Component} from 'react';

import {showError} from '../../../ui/ToastErrore';
import ModalConfirm from "../../ui/ModalConfirm";
import {EndPointMethod, RequestBackend} from "../../backend/RequestBackend";


export class GoatechBtnSetAddressReached extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'admin_address': this.props.admin_address,
            'address': this.props.address ? this.props.address : null,
            'disabled': this.props.disabled ? true : false,
        };
        this.makeReachedBtn = this.makeReachedBtn.bind(this);
    }

    async makeReachedBtn() {
        if (!this.state.disabled && this.state.address) {
            const modalConfirm = new ModalConfirm("Imposta tutte le Balle di questo address come profitto raggiunto", "Address: " + this.state.address + "<br /><br />", "Conferma");
            const responseModal = await modalConfirm.waitModal();
            if (responseModal) {
                const addressReached = this.state.address;
                const request = new RequestBackend(this.state.admin_address, EndPointMethod.GOATECH_ADMIN_ADDRESS_AS_PROFIT_REACHED, {
                    addressReached: addressReached
                });
                const response = await request.run();
                if (request.getStatusCode() == 200) {
                    if (response.error) {
                        showError({'titolo': 'Errore', 'testo': response.error});
                    } else {
                        if (response["stato"] && response["stato"] == "ok") {
                            showError({
                                'titolo': 'Wallet salvato',
                                'testo': 'Wallet impostato come profitto raggiunto'
                            });
                            window.setTimeout(function () {
                                window.location.reload();
                            }, 1200);
                        } else {
                            showError({'titolo': 'Error', 'testo': 'A problem occurred'});
                        }
                    }
                }
                return false;
            }
        }
    }

    render() {
        return (<span onClick={() => this.makeReachedBtn()}
                      className={'burn-btn ' + (this.state.disabled ? 'disabled' : '')}>Profitto raggiunto</span>);
    }
}

export default GoatechBtnSetAddressReached;