import { renderToString } from 'react-dom/server'

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ModalConfirm from '../../ui/ModalConfirm';
import { EndPointMethod, RequestBackend } from '../../backend/RequestBackend';
import { showError } from '../../../ui/ToastErrore';

export class AffiliateBonusBtnPay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'admin_address': this.props.admin,
            'address': this.props.address,
            'idBonus': this.props.idBonus,
            'disabled': this.props.disabled ? true : false,
            'loading': false,
            'amount': this.props.amount
        }
        this.prepareModalPay = this.prepareModalPay.bind(this);
    }

    async prepareModalPay() {
        if (!this.state.disabled && this.state.address && !this.state.loading) {
            this.modalPay();
        }
    }

    async modalPay() {

        this.setState({ loading: true });

        // Modal per pagare
        const modalDescription = renderToString(
            <div>
                <div className='affiliate-paid-form'>
                    { /* <div className='affiliate-paid-icon'>
                        <FontAwesomeIcon icon={faCopy} />
                    </div> */ }
                    <div className='affiliate-paid-address'>
                        <p className='label-addressref'>Address sponsor:</p>
                        <p className='addressref'>
                            <input type={'text'} readOnly value={this.state.address} />
                        </p>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <p style={{ margin: 0 }}>Total to Pay: <span className='greenTdValue'>{this.state.amount} USD</span></p>
            </div>
        );

        const modalConfirm = new ModalConfirm("Commissions Payment", modalDescription, "Make as paid");
        const responseModal = await modalConfirm.waitModal();
        if (!responseModal) {
            this.setState({ loading: false });
            return false;
        }

        // Imposto i profitti come pagati
        await this.pay();

        this.setState({ loading: false });
    }

    async pay() {

        const request = new RequestBackend(this.state.admin_address, EndPointMethod.AFFILIATE_ADMIN_BONUS_PAY, {
            idBonus: this.state.idBonus
        });
        const response = await request.run();

        if (request.getStatusCode() == 200) {
            if (response.error) {
                showError({ 'titolo': 'Error', 'testo': response.error });
            } else {
                if (response.status && response.status == "ok") {
                    window.location.reload();
                } else {
                    showError({ 'titolo': 'Error', 'testo': 'A problem occurred' });
                }
            }
        }
    }

    render() {
        return (<span onClick={() => this.prepareModalPay()} className={'gestione-btn pay-btn ' + (this.state.disabled || this.state.loading ? 'disabled' : '')}>
            Pay {this.state.loading ? <>&nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} spin /></> : ''}
        </span>);
    }
}

export default AffiliateBonusBtnPay;