import React, { Component } from 'react';

import HeaderGoat from '../../../../components/ui/HeaderGoat';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';

import { StatsBadge, StatsBadgeEnum } from '../../../../components/area_riservata/goatech/ui/StatsBadge';
import StatsValue from '../../../../components/area_riservata/goatech/ui/StatsValue.js';

import GoatechProcessBurn from '../../../../components/area_riservata/goatech/ui/GoatechProcessBurn.js';
import { Reti } from '../../../../components/wallet_generic/Wallets';
import { getWeb3Provider } from '../../../../components/binance/helpers/getWeb3Provider';
import { showError } from '../../../../components/ui/ToastErrore';
import { EndPointMethod, RequestBackend } from '../../../../components/area_riservata/backend/RequestBackend';
import moment from 'moment';

export class AdminGoatechBurnArea extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'listRequests': []
        };
        this.setAddress = this.setAddress.bind(this);
    }

    setAddress(address) {
        this.setState({ 'menu_address': address });
        this.loadRequestsBurn();
    }

    async getBallaInfo(tokenId) {
        const request = new RequestBackend(this.state.menu_address, EndPointMethod.GOATECH_ADMIN_GET_BALLA, { tokenId: tokenId });
        const response = await request.run();

        if (request.getStatusCode() == 200 && !request.isResponseError()) {
            return response.data;
        } else {
            if (request.isResponseError()) {
                if (response["error"]) {
                    showError({ 'titolo': 'Errore info balla', 'testo': response["error"] });
                }
            }
            showError({ 'titolo': 'Errore info balla', 'testo': 'Errore generico' });
        }
        return null;
    }

    async loadRequestsBurn() {
        const provider = await getWeb3Provider(true);
        const contract = provider.contract;

        try {
            const request = await contract.getBurnQueue();

            let arrayRichieste = [];
            let tokenId;
            let ballaInfo;

            for (let tokenIdReq in request) {
                tokenId = parseInt(request[tokenIdReq].toNumber());

                ballaInfo = await this.getBallaInfo(tokenId);
                if (!ballaInfo) {
                    continue;
                }

                // Carico le informazioni di questa balla
                arrayRichieste.push({
                    tokenId: tokenId,
                    buyDate: ballaInfo.buyDate,
                    reqBurn: ballaInfo.reqBurn,
                    actualPercentage: ballaInfo.actualPercentage,
                    burnValue: parseInt(ballaInfo.burnValue),
                    percentagePenalBurn: ballaInfo.percentagePenalBurn
                });
            }

            arrayRichieste.sort(function (a, b) {
                return (b.buyDate ? moment(b.buyDate).unix() : 0) - (a.buyDate ? moment(a.buyDate).unix() : 0)
            });
            
            this.setState({ listRequests: arrayRichieste });

        } catch (error) {
            showError({ 'titolo': 'Error :(', 'testo': 'An error occurred in the request ' + (error.message ? ": " + error.message : '') });
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-burn-area' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Request Burn Area</h3>

                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Fieno #</th>
                                                    <th className='cell-left'>Fieno Total Rewards</th>
                                                    <th className='cell-left'>Buy date</th>
                                                    <th>Status</th>
                                                    <th className='cell-left'>Request burn date</th>
                                                    <th>Burn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.listRequests ? Array.from(this.state.listRequests).map((balla, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="Fieno #" className='cell-left'>{balla.tokenId}</td>
                                                            <td data-label="Fieno Total Rewards" className='cell-left'><StatsValue valore={balla.actualPercentage} /> RICEVE = {balla.burnValue}</td>
                                                            <td data-label="Buy date" className='cell-left'>{balla.buyDate ? moment(balla.buyDate).format("DD.MM.YYYY") : ''}</td>
                                                            <td data-label="Status" className='cell-left'><StatsBadge badge={StatsBadgeEnum.REQ_BURN} /></td>
                                                            <td data-label="Request burn date" className='cell-left'>{balla.reqBurn}</td>
                                                            <td data-label="Burn" className='cell-left'><GoatechProcessBurn tokenId={balla.tokenId} burnValue={balla.burnValue} percentagePenalBurn={balla.percentagePenalBurn} /></td>
                                                        </tr>
                                                    )
                                                }, this) : null}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminGoatechBurnArea;