import React, { Component } from 'react';

import moment from 'moment';

import HeaderGoat from '../../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';
import ModalAdminInsertTrans from '../../../../components/area_riservata/goatech/ui/ModalAdminInsertTrans.js';
import { showError } from '../../../../components/ui/ToastErrore.js';

import StatsValue from '../../../../components/area_riservata/goatech/ui/StatsValue.js';

import { Reti } from '../../../../components/wallet_generic/Wallets.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowUpRightFromSquare, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EndPointMethod, RequestBackend } from '../../../../components/area_riservata/backend/RequestBackend.js';
import { StatiProfittiSettimanali } from '../../../../components/area_riservata/goatech/ui/StatiProfittiSettimanali.js';

export class AdminGoatechUpdateHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'modal_insert_trans': false,

            'listProfits': []
        };

        this.setAddress = this.setAddress.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.eliminaProfitto = this.eliminaProfitto.bind(this);
    }

    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.GOATECH_ADMIN_GETLIST_PROFITS);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            for(const kProfit in response["data"]) {
                const levels = [];
                for(const profitLevel of response["data"][kProfit].levels) {
                    levels[profitLevel.level] = profitLevel;
                }
                response["data"][kProfit].levels = levels;
            }
            this.setState({ 'listProfits': response["data"] });
        }
    }

    toggleModal() {
        this.setState({ modal_insert_trans: !this.state.modal_insert_trans });
    }

    async eliminaProfitto(id) {
        if (window.confirm("Sei sicuro di voler eliminare questa ricompensa?")) {
            const request = new RequestBackend(this.state.menu_address, EndPointMethod.GOATECH_ADMIN_DELETE_DISTRIB, {
                profittoMasterId: id
            });
            const response = await request.run();
            if (request.getStatusCode() != 200) {
                showError({ 'titolo': 'Error', 'testo': 'Impossibile eliminare la ricompensa' });
            }
            if (response["stato"] && response["stato"] == "ok") {
                showError({ 'titolo': 'Success!', 'testo': 'La ricompensa è stata eliminata correttamente' });
                window.setTimeout(function () {
                    window.location.reload();
                }, 1200);
            } else {
                showError({ 'titolo': 'Error', 'testo': 'Impossibile eliminare la ricompensa' });
            }
        }
    }

    preparaModal() {
        this.toggleModal();
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-update-history' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Update History</h3>

                                    <br />

                                    <div className='form-trans-update'>
                                        <button onClick={(e) => {
                                                this.preparaModal();
                                                e.preventDefault();
                                            }} className='btn-block-orange' type="submit" >
                                            SELECT PERCENTAGE FOR LICENSES
                                            {!this.state.prepareModal ? <span>&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleRight} /></span> : null}
                                            {this.state.prepareModal ? <span>&nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} spin /></span> : null}
                                        </button>
                                    </div>

                                    <ModalAdminInsertTrans toggleModal={this.toggleModal} address={this.state.menu_address} impostaModal={this.state.modal_insert_trans} />

                                    <h3>Withdrawal Rewards History</h3>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Date</th>
                                                    <th style={{ width: 120 }}>Total Rew.</th>
                                                    <th style={{ width: 100 }}>Bronze</th>
                                                    <th style={{ width: 100 }}>Silver</th>
                                                    <th style={{ width: 100 }}>Gold</th>
                                                    <th>Month number</th>
                                                    <th className='cell-left'>Quantity</th>
                                                    <th className='cell-left'>Data distrib. prevista</th>
                                                    <th className='cell-left'>Stato</th>
                                                    <th style={{ width: 80 }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.listProfits ? Array.from(this.state.listProfits).map((profitto, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="Date" className='cell-left'>{profitto.data}</td>
                                                            <td data-label="Total Rew." className='cell-left'><StatsValue valore={profitto.gain} /></td>
                                                            <td data-label="Bronze" className='cell-left'>{profitto.levels["BRONZE"] ? (<StatsValue valore={profitto.levels["BRONZE"].gain} />) : null}</td>
                                                            <td data-label="Silver" className='cell-left'>{profitto.levels["SILVER"] ? (<StatsValue valore={profitto.levels["SILVER"].gain} />) : null}</td>
                                                            <td data-label="Gold" className='cell-left'>{profitto.levels["GOLD"] ? (<StatsValue valore={profitto.levels["GOLD"].gain} />) : null}</td>
                                                            <td data-label="Month number">{profitto.monthNumber}</td>
                                                            <td data-label="Quantity" className='cell-left'>{profitto.value} USDC</td>
                                                            <td data-label="Data distrib. prevista" className='cell-left'>{profitto.stato == "PENDING_DISTRIBUTION" ? /* profitto.distribPrevista */ "Al prossimo minuto 0 o 30" : '-'}</td>
                                                            <td data-label="Stato" className='cell-left'>{StatiProfittiSettimanali[profitto.stato]}</td>
                                                            <td data-label="Delete">{profitto.stato == "PENDING_DISTRIBUTION" ? <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => this.eliminaProfitto(profitto._id)} icon={faTrash} /> : null}</td>
                                                        </tr>
                                                    )
                                                }) : null}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminGoatechUpdateHistory;